import {DatePickerLocale} from "../TimePickersLocaleProxy";
import TranslatableTypography from "../TranslatableTypography";
import React from "react";
import moment from "moment";


export default ({source, label, momentFunc = moment}) => {

    function _toDateQuery(momentObject) {
        return !!momentObject ?
            {
                'between': [
                    momentFunc(momentObject).startOf('day').format(),
                    momentFunc(momentObject).endOf('day').format()
                ]
            }
            :
            momentObject;
    }

    function _fromDateQuery(query) {
        return !!query ?
            momentFunc(query.between[0])
            :
            query;
    }


    return (
        <DatePickerLocale source={source}
                          label={label}
                          format={_fromDateQuery}
                          parse={_toDateQuery}
                          momentFunc={(time) => momentFunc(time).utc()}
                          minDate={momentFunc(0)}
                          options={{
                              cancelLabel: <TranslatableTypography content={'ra.action.cancel'}/>,
                              format: 'DD-MM-YYYY (z)'
                          }}
                          isRequired={false}
        />
    )
}
