import React from 'react';
import {
    ArrayInput,
    Create,
    Datagrid,
    Filter,
    FormDataConsumer,
    LongTextInput,
    maxLength,
    minLength,
    number,
    RadioButtonGroupInput,
    required,
    Responsive,
    SelectArrayInput,
    SelectInput,
    Show,
    ShowButton,
    showNotification,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextField,
    TextInput,
    translate
} from 'react-admin';

import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";
import MyTransitionDecorator from "../MyTransitionDecorator";
import {MyRecordTitle, MyResourceListTitle} from "../MyTitle";
import Typography from "@material-ui/core/Typography";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import {NotamPublishToolbar} from "../MyBasicEditToolbar";
import connect from "react-redux/es/connect/connect";
import compose from "recompose/compose"
import {DateTimePickerLocale, TimePickerLocale} from "../TimePickersLocaleProxy";
import TranslatableTypography from "../TranslatableTypography";
import {
    ActivityPeriodMode,
    ActivityRepeatMode,
    ActivitySlotMode,
    NotamType,
    ZoneReferenceType
} from "../../../LogicV1Redux/constants/NotamsConstants";
import FlexDiv from "../FlexDiv";
import moment from "moment";
import {change, formValueSelector, getFormSyncErrors} from "redux-form";
import Labeled from "ra-ui-materialui/lib/input/Labeled";
import has from 'lodash.has'
import isEqual from "lodash.isequal"
import {getDiurnal, notamMoment} from "../../utils/notamDiurnal";
import {bindActionCreators} from "redux";
import * as wkx from "wkx";
import {withStyles} from "@material-ui/core";
import {ArrayField, ReferenceManyField, SelectField} from "ra-ui-materialui";
import MyTextField from "../MyTextField";
import withWidth from "@material-ui/core/withWidth";
import MyZoneField from "./ZoneCustomComponents/MyZoneField";
import {urls} from "../../../Configs";
import {isAtcMaster} from "../../logic/MyRestConfig";
import {isSuperAdmin} from "../../utils/tempCommon";
import {ZoneDataProvider} from "./NotamCustomComponents/ZoneDataProvider";
import {RecordConsumer} from "./NotamCustomComponents/RecordConsumer";
import {ZoneDescriptionInput} from "./ZoneCustomComponents/ZoneDescriptionInput";
import MyIlikeSearch from "./MyIlikeSearch";
import MyDateSearch from "./MyDateSearch";
import {validateNoSpaceTrim, validateNotamAltitudes} from "../../utils/validationHelpers";
import {WarningText} from "../WarningText";
import {isEmpty} from "lodash";
import {NOTAM_DEACTIVATION_SUPPORT} from "../../../Configs";


const defaultRequired = [required(), minLength(2), maxLength(64)];
const createNotamForm = 'record-form'; //WRRR! must be default

const styles = {
    qLineInputs: {
        marginRight: "0.5rem",
        '&:not(:first-child)': {
            marginLeft: "0.5rem",
        }
    },
    amslLine: {
        width: "unset",
        minWidth: "18ch",
        "&:before": {
            display: "block",
            position: "absolute",
            top: "22px",
            left: "auto",
            bottom: "auto",
            right: "0",
            content: "'ft AMSL'",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif"
        }
    }
};

const getActivityMonths = () => {
    let arr = [];
    for (let i = 0; i < 12; i++) {
        arr.push(
            {
                id: i,
                name: notamMoment().month(i).format('MMM')
            })
    }
    return arr;
};

const getActivityWeekdays = () => {
    let arr = [];
    for (let i = 1; i < 8; i++) {
        arr.push(
            {
                id: i,
                name: notamMoment().isoWeekday(i).format('ddd')
            })
    }
    return arr;
};

const getActivityDays = () => {
    let arr = [];
    for (let i = 0; i < 31; i++) {
        arr.push(
            {
                id: i + 1,
                name: i + 1
            })
    }
    return arr;
};


const notamTypes = [
    {id: NotamType.NEW, name: NotamType.NEW},
    {id: NotamType.REPLACE, name: NotamType.REPLACE},
    {id: NotamType.CANCEL, name: NotamType.CANCEL}
];

const zoneTypes = [
    {id: 'AREA', name: 'AREA'},
    {id: 'CTR', name: 'CTR'},
    {id: 'MCTR', name: 'MCTR'},
    {id: 'NW', name: 'NW'},
    {id: 'D', name: 'D'},
    {id: 'P', name: 'P'},
    {id: 'R', name: 'R'},
    //{id: 'EA', name: 'EA'}, -no notam modification, ea will be removed
    {id: 'TRA', name: 'TRA'},
    {id: 'TSA', name: 'TSA'}

];

const activityRepeatModes = [
    {id: ActivityRepeatMode.DAYS, name: 'resources.notams.fields.activityRepeatModes.days'},
    {id: ActivityRepeatMode.WEEKDAYS, name: 'resources.notams.fields.activityRepeatModes.weekdays'},
    {id: ActivityRepeatMode.MONTHS, name: 'resources.notams.fields.activityRepeatModes.months'},
    {id: ActivityRepeatMode.NONSTOP, name: 'resources.notams.fields.activityRepeatModes.nonStop'},
];

const activityTimeModes = [
    {id: ActivityPeriodMode.SINGLE, name: 'resources.notams.fields.activityTimeModes.single'},
    {id: ActivityPeriodMode.REPEAT, name: 'resources.notams.fields.activityTimeModes.repeat'},
];

const activitySlotModes = [
    {id: ActivitySlotMode.H24, name: 'resources.notams.fields.activitySlotModes.h24'},
    {id: ActivitySlotMode.SLOTS, name: 'resources.notams.fields.activitySlotModes.slots'},
];

const zoneReference = [
    {id: ZoneReferenceType.NEW, name: 'resources.notams.fields.zoneReference.new'},
    {id: ZoneReferenceType.EXIST, name: 'resources.notams.fields.zoneReference.exist'},
];

export const NotamsList = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureNotamsList {...props}/>
    </MyTransitionDecorator>
);

const NotamFilter = (props) => {
    if (!props.permissions) return null;

    return (
        <Filter {...props}>
            <MyDateSearch source="created"
                          label={'resources.notams.fields.created'}
                          momentFunc={notamMoment}
            />
            <MyIlikeSearch source="series"
                           label='resources.notams.fields.series'
            />
            <MyIlikeSearch source="zonename"
                           label='resources.notams.fields.zonename'
            />
        </Filter>
    )};


const PureNotamsList = withWidth()(
    ({permissions, width, ...props}) => (
        <List {...props}
              bulkActions={false}
              actions={<BasicListActions/>}
              title={<MyResourceListTitle resource={'notams'}/>}
              pagination={<MyPagination/>}
              sort={{field: 'series', order: 'DESC'}}
              filters={<NotamFilter {...{permissions}}/>}
        >
            <Responsive
                medium={
                    <Datagrid>
                        <TextField source="series"/>
                        <SelectField source="type"
                                     choices={notamTypes}/>
                        <TextField source="seriesref"/>
                        <TextField source="zonename"/>
                        <ShowButton/>
                    </Datagrid>
                }
                large={
                    <Datagrid>
                        <TextField source="series"/>
                        <SelectField source="type"
                                     choices={notamTypes}/>
                        <TextField source="seriesref"/>
                        <MyTextField format={(e) => e ? notamMoment(e).format('YYMMDDHHmm') : null}
                                     source="b"/>
                        <MyTextField format={(e) => e ? notamMoment(e).format('YYMMDDHHmm') : null}
                                     source="c"/>
                        <TextField source="e"/>
                        <TextField source="f"/>
                        <TextField source="g"/>
                        <TextField source="zonename"/>

                        {/*temp disabling, cloning issue*/}
                        {/*<CloneButton/>*/}
                        <ShowButton/>

                        {/*At this time no edit is allowed*/}
                        {/*{*/}
                        {/*    canUpdate(props.resource, permissions) ? <EditButton/> : <ShowButton/>*/}
                        {/*}*/}

                    </Datagrid>
                }
            />

        </List>
    ));

const getNotamText = ({
                          a,
                          b,
                          c,
                          d,
                          e,
                          f,
                          g,
                          q,
                          series = '',
                          seriesref,
                          type = ''
                      }) => {
    return `${series} ${type}${seriesref ? ' ' + seriesref : ''}${'\n'}${q ? 'Q) ' + q.code + "/" + q.traffic + "/" + q.purpose + "/" + q.scope + "/" + q.lower + "/" + q.upper + "/" + q.coords + "/" + '\n' : ''}${a ? 'A) ' + a + '\n' : ''}${b ? 'B) ' + moment(b).format('YYMMDDHHmm') + '\n' : ''}${c ? 'C) ' + moment(c).format('YYMMDDHHmm') + '\n' : ''}${d ? 'D) ' + d + '\n' : ''}${e ? 'E) ' + e + '\n' : ''}${f ? 'F) ' + f + 'FT AMSL\n' : ''}${g ? 'G) ' + g + 'FT AMSL\n' : ''}`.toUpperCase();
};


class NotamsCreateBase extends React.Component {

    static defaultProps = {
        actDef: []
    };

    state = {
        geometry: null,
        dLine: '',
        activities: [],
    };

    componentWillMount() {
        //With same-orgin policy
        window.document.addEventListener(
            'notamEditorLoaded',
            this._pushTokenToUtil,
            false
        );

        window.document.addEventListener(
            'newNotamGeometry',
            this._handleNewGeometry,
            false
        );

        //Without same-orgin policy
        // window.onmessage = function(e){
        //     if (e.data === 'hello') {
        //         alert('It works!');
        //     }
        // };

    }


    componentDidMount() {
        //TODO support for actDef cloning
        //
        // const {state} = this.props.location;
        //
        // if(has(state, 'record.b') ) {
        //     const {etc, ...cleanedRecord} = state.record;
        //     console.log("record from clone", state.record);
        //     this.props.history.replace({
        //         pathname: '/notams/create',
        //         state: {
        //             record: {
        //                 ...cleanedRecord,
        //                 actDef: etc.actDef,
        //
        //             }
        //         }
        //     })
        // }

    }

    componentWillUnmount() {
        window.document.removeEventListener('notamEditorLoaded', () => {
        });
        window.document.removeEventListener('newNotamGeometry', () => {
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        const {formSyncErrors} = nextProps;

        this._updateNotamUtil(formSyncErrors, nextProps);

        this._updateDiurnal(nextProps);

    }

    _updateDiurnal(nextProps) {
        if (
            !isEqual(nextProps.actDef, this.props.actDef) ||
            !isEqual(nextProps.b, this.props.b) ||
            !isEqual(nextProps.c, this.props.c)
        ) {
            const {b, c, actDef} = nextProps;
            if (!b || !c || !actDef) {
                return;
            }

            let dLine = '';
            const activities = [];
            for (const activityDef of actDef) {

                const {months, days, weekdays, hourSlots, periodMode, slotMode, end, begin, repeatMode} = activityDef;

                if (!periodMode) {
                    return;
                }

                switch (periodMode) {
                    case ActivityPeriodMode.SINGLE:
                        if (!begin || !end) {
                            return;
                        }
                        break;
                    case ActivityPeriodMode.REPEAT:
                        if (
                            !repeatMode ||
                            !(hourSlots || slotMode === ActivitySlotMode.H24)) {
                            return;
                        }
                        break;
                    default:
                        return;
                }

                try {
                    const diurnal = getDiurnal(b, c, activityDef);
                    dLine += (dLine !== '' ? ", " : '') + diurnal.text;
                    activities.push(...diurnal.activities);
                } catch (e) {
                    if (e.message === 'Notam diurnal composer - Activity period exceeds Notam validity period!') {
                        this.props.showNotification(
                            'Activity period exceeds Notam validity period, please correct!',
                            'warning',
                            {autoHideDuration: 9000}
                        )
                    } else {
                        this.props.showNotification(
                            e.message,
                            'warning',
                            {autoHideDuration: 9000}
                        )
                    }
                }
            }


            this.setState({
                dLine,
                activities,
            })
        }
    }

    _updateNotamUtil(formSyncErrors, nextProps) {
        if (
            !formSyncErrors.q &&
            !formSyncErrors.e &&
            !isEqual(nextProps.e, this.props.e)
        ) {
            this._pushNotamToUtil(getNotamText(nextProps));
        }
    }

    _pushNotamToUtil = (e) => {
        const iframe = document.getElementById('#notam_util');
        // console.log("notamEditorLoaded", iframe);
        iframe.contentDocument.dispatchEvent(
            new CustomEvent(
                'takeNotam',
                {
                    detail: e
                })
        )
    };

    /**
     * Used for passing token, required for its fetch actions
     * @private
     */
    _pushTokenToUtil = () => {
        const iframe = document.getElementById('#notam_util');
        // console.log("notamEditorLoaded", iframe);
        iframe.contentDocument.dispatchEvent(
            new CustomEvent(
                'takeToken',
                {
                    detail: this.props.token
                })
        )
    };

    _handleNewGeometry = (e) => {
        const details = JSON.parse(e.detail);
        const {type, coordinates} = details;
        const geom = wkx.Geometry.parseGeoJSON({type, coordinates});
        const geometryEwkb = geom.toEwkb().toString('HEX').toUpperCase();
        this.setState({
            geometry: geometryEwkb,
        })
    };


    render() {
        const {permissions, actDef, classes, type, zoneReferenceType, zoneType, translate, ...props} = this.props;


        return (
            <Create {...props}
                    title={'resources.notams.create'}
                // style={{height: "86vh"}}
            >
                <SimpleForm
                    submitOnEnter={false}
                    form={createNotamForm}
                    toolbar={
                        <NotamPublishToolbar activities={this.state.activities}
                                             geometry={this.state.geometry}
                                             dLine={this.state.dLine}
                        />
                    }
                >
                    <Typography variant={'body2'}>
                        {translate("resources.notams.fields.seriesLine")}
                    </Typography>
                    <FlexDiv>
                        <TextInput source="series"
                                   label="resources.notams.fields.series"
                                   validate={[required(), validateNoSpaceTrim]}
                        />
                        <SelectInput source="type"
                                     label="resources.notams.fields.type"
                                     choices={notamTypes}
                                     validate={[required()]}
                        />
                        {
                            !!type && type !== NotamType.NEW &&
                            <TextInput source="seriesref"
                                       label="resources.notams.fields.seriesref"
                                       validate={[required(), validateNoSpaceTrim]}
                            />
                        }

                    </FlexDiv>
                    <br/>
                    {
                        type !== NotamType.CANCEL &&
                        <>
                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.qLine")}
                            </Typography>
                            <FlexDiv>
                                <TextInput source={"q.fir"}
                                           label={'resources.notams.fields.q.fir'}
                                           inputProps={{maxLength: "4"}}
                                           style={{minWidth: "12ch", width: "16ch"}}
                                           placeholder={'XXXX'}
                                           className={classes.qLineInputs}
                                           validate={[required(), validateNoSpaceTrim]}
                                />
                                /
                                <TextInput source={"q.code"}
                                           label={'resources.notams.fields.q.code'}
                                           inputProps={{maxLength: "5"}}
                                           style={{minWidth: "14ch", width: "16ch"}}
                                           placeholder={'QXXXX'}
                                           className={classes.qLineInputs}
                                           validate={[required(), validateNoSpaceTrim]}
                                />
                                /
                                <TextInput source={"q.traffic"}
                                           label={'resources.notams.fields.q.traffic'}
                                           inputProps={{maxLength: "2"}}
                                           style={{minWidth: "6ch", width: "7ch"}}
                                           placeholder={'XX'}
                                           className={classes.qLineInputs}
                                           validate={[required(), validateNoSpaceTrim]}
                                />
                                /
                                <TextInput source={"q.purpose"}
                                           label={'resources.notams.fields.q.purpose'}
                                           inputProps={{maxLength: "3"}}
                                           style={{minWidth: "7ch", width: "8ch"}}
                                           placeholder={'XXX'}
                                           className={classes.qLineInputs}
                                           validate={[required(), validateNoSpaceTrim]}
                                />
                                /
                                <TextInput source={"q.scope"}
                                           label={'resources.notams.fields.q.scope'}
                                           inputProps={{maxLength: "2"}}
                                           style={{minWidth: "7ch", width: "7ch"}}
                                           placeholder={'XX'}
                                           className={classes.qLineInputs}
                                           validate={[required(), validateNoSpaceTrim]}
                                />
                                /
                                <TextInput source={"q.lower"}
                                           label={'resources.notams.fields.q.lower'}
                                           inputProps={{maxLength: "3"}}
                                           style={{minWidth: "7ch", width: "7ch"}}
                                           placeholder={'XXX'}
                                           className={classes.qLineInputs}
                                           validate={[required(),validateNoSpaceTrim]}
                                />
                                /
                                <TextInput source={"q.upper"}
                                           label={'resources.notams.fields.q.upper'}
                                           inputProps={{maxLength: "3"}}
                                           style={{minWidth: "7ch", width: "7ch"}}
                                           placeholder={'XXX'}
                                           className={classes.qLineInputs}
                                           validate={[required(), validateNoSpaceTrim]}
                                />
                                /
                                <TextInput source={"q.coords"}
                                           label={'resources.notams.fields.q.coords'}
                                           inputProps={{maxLength: "14"}}
                                           style={{minWidth: "16ch", width: "18ch"}}
                                           placeholder={'XXXXXXXXXXXXXX'}
                                           className={classes.qLineInputs}
                                           validate={[required(), validateNoSpaceTrim]}
                                />
                            </FlexDiv>
                            <br/>
                        </>
                    }

                    <Typography variant={'body2'}>
                        {translate("resources.notams.fields.aLine")}
                    </Typography>
                    <TextInput multiline={false} source="a" validate={[required(), validateNoSpaceTrim]}/>
                    <br/>
                    {
                        type !== NotamType.CANCEL &&
                        <>
                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.bLine")}
                            </Typography>
                            <DateTimePickerLocale source="b"
                                                  label='resources.notams.fields.b' //required for time picker
                                                  validate={defaultRequired}
                                                  options={{
                                                      ampm: false,
                                                      cancelLabel: <TranslatableTypography
                                                          content={'ra.action.cancel'}/>,
                                                      format: 'YYMMDDHHmm'
                                                  }}
                                                  momentFunc={(time) => notamMoment(time).utc()}
                            />
                            <br/>

                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.cLine")}
                            </Typography>
                            <DateTimePickerLocale source="c"
                                                  label='resources.notams.fields.c'
                                                  validate={defaultRequired}
                                                  options={{
                                                      ampm: false,
                                                      cancelLabel: <TranslatableTypography
                                                          content={'ra.action.cancel'}/>,
                                                      format: 'YYMMDDHHmm'
                                                  }}
                                                  momentFunc={(time) => notamMoment(time).utc()}
                            />
                            <br/>

                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.dLine")}
                            </Typography>
                            {
                                (isEmpty(actDef) || isEmpty(actDef[0])) &&
                                <WarningText content={<TranslatableTypography content={'resources.notams.activitiesReminder'}/>} colored/>
                            }

                            <ArrayInput source="actDef" label='resources.notams.fields.actDefName'>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({
                                              formData, // The whole form data
                                              scopedFormData, // The data for this item of the ArrayInput
                                              getSource, // A function to get the valid source inside an ArrayInput
                                              ...rest
                                          }) => {
                                            const idx = getSource().match(/[0-9]+(?!\[)(?=\])/g)[0];
                                            return (
                                                <>
                                                    <RadioButtonGroupInput source={getSource("periodMode")}
                                                                           label='resources.notams.fields.actDef.periodMode'
                                                                           choices={activityTimeModes}
                                                                           disabled={!!actDef[idx].periodMode}
                                                    />
                                                    {
                                                        has(actDef[idx], 'periodMode') && actDef[idx].periodMode === ActivityPeriodMode.REPEAT &&
                                                        <RadioButtonGroupInput source={getSource("repeatMode")}
                                                                               label='resources.notams.fields.actDef.repeatMode'
                                                                               choices={activityRepeatModes}
                                                                               disabled={!!actDef[idx].repeatMode}
                                                        />
                                                    }
                                                    {
                                                        //TODO add validation if begin and end is between start and stop
                                                        has(actDef[idx], 'periodMode') && actDef[idx].periodMode === ActivityPeriodMode.SINGLE &&
                                                        <>
                                                            <DateTimePickerLocale source={getSource('begin')}
                                                                                  label='resources.notams.fields.actDef.begin'
                                                                                  validate={defaultRequired}
                                                                                  options={{
                                                                                      ampm: false,
                                                                                      cancelLabel:
                                                                                          <TranslatableTypography
                                                                                              content={'ra.action.cancel'}/>,
                                                                                      format: 'MMM D HHmm'
                                                                                  }}
                                                                                  momentFunc={(time) => notamMoment(time).utc()}
                                                            />
                                                            <DateTimePickerLocale source={getSource('end')}
                                                                                  label='resources.notams.fields.actDef.end'
                                                                                  validate={defaultRequired}
                                                                                  options={{
                                                                                      ampm: false,
                                                                                      cancelLabel:
                                                                                          <TranslatableTypography
                                                                                              content={'ra.action.cancel'}/>,
                                                                                      format: 'MMM D HHmm'
                                                                                  }}
                                                                                  momentFunc={(time) => notamMoment(time).utc()}
                                                            />
                                                        </>
                                                    }
                                                    {
                                                        //TODO add validation if begin and end is between start and stop
                                                        has(actDef[idx], 'periodMode') && actDef[idx].periodMode === ActivityPeriodMode.REPEAT &&
                                                        <>
                                                            {
                                                                has(actDef[idx], 'repeatMode') &&
                                                                (
                                                                    actDef[idx].repeatMode === ActivityRepeatMode.DAYS ||
                                                                    actDef[idx].repeatMode === ActivityRepeatMode.WEEKDAYS ||
                                                                    actDef[idx].repeatMode === ActivityRepeatMode.MONTHS
                                                                ) &&
                                                                <SelectArrayInput source={getSource('months')}
                                                                                  label='resources.notams.fields.actDef.months'
                                                                                  choices={getActivityMonths()}
                                                                                  allowEmpty={true}
                                                                />
                                                            }

                                                            {
                                                                has(actDef[idx], 'repeatMode') && actDef[idx].repeatMode === ActivityRepeatMode.DAYS &&
                                                                <SelectArrayInput source={getSource('days')}
                                                                                  label='resources.notams.fields.actDef.days'
                                                                                  choices={getActivityDays()}
                                                                                  allowEmpty={true}
                                                                />
                                                            }
                                                            {
                                                                has(actDef[idx], 'repeatMode') && actDef[idx].repeatMode === ActivityRepeatMode.WEEKDAYS &&
                                                                <SelectArrayInput source={getSource('weekdays')}
                                                                                  label='resources.notams.fields.actDef.weekdays'
                                                                                  choices={getActivityWeekdays()}
                                                                                  allowEmpty={true}
                                                                />
                                                            }
                                                            {
                                                                (
                                                                    has(actDef[idx], 'months') ||
                                                                    has(actDef[idx], 'days') ||
                                                                    has(actDef[idx], 'weekdays') ||
                                                                    (has(actDef[idx], 'repeatMode') && actDef[idx].repeatMode === ActivityRepeatMode.NONSTOP)
                                                                ) &&
                                                                <RadioButtonGroupInput source={getSource("slotMode")}
                                                                                       label='resources.notams.fields.actDef.slotMode'
                                                                                       choices={activitySlotModes}
                                                                                       disabled={!!actDef[idx].slotMode}
                                                                />
                                                            }

                                                            {
                                                                has(actDef[idx], 'slotMode') && actDef[idx].slotMode === ActivitySlotMode.SLOTS &&
                                                                <ArrayInput source={getSource('hourSlots')}
                                                                            label="resources.notams.fields.hourSlots">
                                                                    <SimpleFormIterator>
                                                                        <TimePickerLocale source={'begin'}
                                                                                          label='resources.notams.fields.actDef.begin'
                                                                                          validate={defaultRequired}
                                                                                          options={{
                                                                                              ampm: false,
                                                                                              cancelLabel:
                                                                                                  <TranslatableTypography
                                                                                                      content={'ra.action.cancel'}/>,
                                                                                              format: 'HHmm'
                                                                                          }}
                                                                                          momentFunc={(time) => notamMoment(time).utc()}
                                                                        />
                                                                        <TimePickerLocale source={'end'}
                                                                                          label='resources.notams.fields.actDef.end'
                                                                                          validate={defaultRequired}
                                                                                          options={{
                                                                                              ampm: false,
                                                                                              cancelLabel:
                                                                                                  <TranslatableTypography
                                                                                                      content={'ra.action.cancel'}/>,
                                                                                              format: 'HHmm'
                                                                                          }}
                                                                                          momentFunc={(time) => notamMoment(time).utc()}
                                                                        />
                                                                    </SimpleFormIterator>
                                                                </ArrayInput>
                                                            }

                                                        </>
                                                    }
                                                </>
                                            )
                                        }
                                        }
                                    </FormDataConsumer>


                                </SimpleFormIterator>
                            </ArrayInput>
                            <br/>

                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.eLine")}
                            </Typography>
                            <FlexDiv>
                                <div style={{display: "flex"}}>
                                    <LongTextInput multiline source="e" label="resources.notams.fields.e"
                                                   validate={[required()]} style={{width: "unset"}}/>
                                    <div style={{minHeight: "600px", minWidth: "400px", width: "100%"}}>
                                        <iframe src={urls.notamUtil} height="100%" width="100%" id="#notam_util">
                                            <p>test</p>
                                        </iframe>
                                        <textarea rows="3" cols="29"
                                                  value={this.state.geometry ? this.state.geometry : ''}
                                                  style={
                                                      {
                                                          minWidth: "250px",
                                                          position: "relative",
                                                          top: "-60px",
                                                          left: "5px",
                                                          backgroundColor: 'rgba(F,F,F,0.5)',
                                                          resize: 'none'
                                                      }}
                                        />
                                    </div>
                                </div>
                            </FlexDiv>
                            <br/>

                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.fLine")}
                            </Typography>
                            <TextInput source="f"
                                       label={"resources.notams.fields.f"}
                                       validate={
                                           type === NotamType.NEW ?
                                               [required(), validateNotamAltitudes('f'), number(), validateNoSpaceTrim]
                                               :
                                               [validateNotamAltitudes('f'), number(), validateNoSpaceTrim]
                                       }
                                       className={classes.amslLine}
                                       inputProps={{maxLength: "7"}}
                            />
                            <br/>

                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.gLine")}
                            </Typography>
                            <TextInput source="g"
                                       label={"resources.notams.fields.g"}
                                       validate={
                                           type === NotamType.NEW ?
                                               [required(), validateNotamAltitudes('g'), number(), validateNoSpaceTrim]
                                               :
                                               [validateNotamAltitudes('g'), number(), validateNoSpaceTrim]
                                       }
                                       className={classes.amslLine}
                                       inputProps={{maxLength: "7"}}
                            />
                            <br/>

                            <Labeled label="resources.notams.fields.preview">
                                <textarea rows="9" cols="100"
                                          value={getNotamText({...this.props, d: this.state.dLine})}/>
                            </Labeled>
                            <Labeled label="resources.notams.fields.activationsCalc">
                                <textarea rows="10" cols="100"
                                          value={this.state.activities ? JSON.stringify(this.state.activities) : ''}
                                />
                            </Labeled>

                            <br/>
                            <br/>
                            <br/>
                            <Typography variant={'body2'}>
                                {translate("resources.notams.fields.zoneDataLine")}
                            </Typography>
                            <TextInput source="zonename"
                                       label="resources.notams.fields.zonename"
                                       validate={[required(), validateNoSpaceTrim]}
                            />


                            <SelectInput source="zonetype"
                                         label="resources.notams.fields.zonetype"
                                         choices={zoneTypes}
                                         validate={[required()]}
                            />
                        </>
                    }
                    {
                        type !== NotamType.CANCEL &&
                        <ZoneDescriptionInput formName={createNotamForm}
                                              zoneTypeName={zoneType}
                                              showTemplateSwitch
                        />
                    }


                    {/*<FlexDiv>*/}
                    {/*    <RadioButtonGroupInput label="resources.notams.fields.zoneReferenceType"*/}
                    {/*                           choices={zoneReference}*/}
                    {/*                           source={'zoneReferenceType'}*/}
                    {/*                           disabled={!type}*/}
                    {/*                           validate={[required()]}*/}
                    {/*    />*/}
                    {/*    {*/}
                    {/*        zoneReferenceType === ZoneReferenceType.NEW &&*/}
                    {/*        <TextInput source="zonename"*/}
                    {/*                   label="resources.notams.fields.zonename"*/}
                    {/*                   validate={type === NotamType.NEW ? [required()] : undefined}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*    {*/}
                    {/*        zoneReferenceType === ZoneReferenceType.EXIST &&*/}
                    {/*        <ReferenceInput label="resources.notams.fields.zonename"*/}
                    {/*                        source="zonename"*/}
                    {/*                        reference="zones"*/}
                    {/*                        perPage={Infinity}*/}
                    {/*                        sort={{field: 'name', order: 'ASC'}}*/}
                    {/*                        allowEmpty*/}
                    {/*                        resource={props.resource}*/}
                    {/*        >*/}
                    {/*            <SelectInput optionText="name"*/}
                    {/*                         validate={type !== NotamType.NEW ? [required()] : undefined}*/}
                    {/*            />*/}
                    {/*        </ReferenceInput>*/}
                    {/*    }*/}
                    {/*</FlexDiv>*/}
                </SimpleForm>
            </Create>
        )
    }
}

export const NotamsCreate = compose(
    withStyles(styles),
    translate,
    connect(
        state => ({
            token: state.myAuth.token,
            q: formValueSelector(createNotamForm)(state, 'q'),
            a: formValueSelector(createNotamForm)(state, 'a'),
            b: formValueSelector(createNotamForm)(state, 'b'),
            c: formValueSelector(createNotamForm)(state, 'c'),
            e: formValueSelector(createNotamForm)(state, 'e'),
            f: formValueSelector(createNotamForm)(state, 'f'),
            g: formValueSelector(createNotamForm)(state, 'g'),
            series: formValueSelector(createNotamForm)(state, 'series'),
            seriesref: formValueSelector(createNotamForm)(state, 'seriesref'),
            type: formValueSelector(createNotamForm)(state, 'type'),
            actDef: formValueSelector(createNotamForm)(state, 'actDef'),
            zoneReferenceType: formValueSelector(createNotamForm)(state, 'zoneReferenceType'),
            zoneType: formValueSelector(createNotamForm)(state, 'zonetype'),
            formSyncErrors: getFormSyncErrors(createNotamForm)(state),

        }),
        dispatch => ({
            change: bindActionCreators(change, dispatch),
            showNotification: bindActionCreators(showNotification, dispatch),
        })
    )
)(NotamsCreateBase);

const mapStateToProps = state => {
    return {
        locale: state.i18n.locale,
    }
};
const enhance = compose(
    connect(
        mapStateToProps,
        null
    ),
);


const DeactivatedNotam = ({ source, record = {} }) =>
{ return (NOTAM_DEACTIVATION_SUPPORT && !record.active)?
    <TranslatableTypography variant={'h5'} style={{color:'red', paddingBottom:10}} content={"resources.notams.fields.deactivated"}/>
    :null
};

class NotamShowBase extends React.Component {

    render() {

        const {permissions, months = getActivityMonths(), locale, dispatch, ...props} = this.props;

        if (!permissions) {
            return null
        }

        const hasHigherPermissions = isAtcMaster(permissions.roles) || isSuperAdmin(permissions.roles);

        return (
            <Show {...props}
                  actions={<BasicShowActions/>}
                  title={<MyRecordTitle resource={'notams'} recordName={'series'}/>}
            >
                <SimpleShowLayout>
                    {/* hiding on PK special request, logic still works as config const NOTAM_DEACTIVATION_SUPPORT remains true*/}
                    {/*<DeactivatedNotam />*/}
                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.seriesLine"}/>
                    <TextField source="active"
                               label="resources.notams.fields.active"
                    />
                    <TextField source="series"
                               label="resources.notams.fields.series"
                    />
                    <SelectField source="type"
                                 label="resources.notams.fields.type"
                                 choices={notamTypes}
                    />
                    <TextField source="seriesref"
                               label="resources.notams.fields.seriesref"
                    />
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.qLine"}/>
                    <TextField source="q.fir"
                               label={'resources.notams.fields.q.fir'}
                    />
                    <TextField source="q.code"
                               label={'resources.notams.fields.q.code'}
                    />
                    <TextField source={"q.traffic"}
                               label={'resources.notams.fields.q.traffic'}
                    />
                    <TextField source={"q.purpose"}
                               label={'resources.notams.fields.q.purpose'}
                    />
                    <TextField source={"q.scope"}
                               label={'resources.notams.fields.q.scope'}
                    />
                    <TextField source={"q.lower"}
                               label={'resources.notams.fields.q.lower'}
                    />
                    <TextField source={"q.upper"}
                               label={'resources.notams.fields.q.upper'}
                    />
                    <TextField source={"q.coords"}
                               label={'resources.notams.fields.q.coords'}
                    />
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.aLine"}/>
                    <TextField source="a"/>
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.bLine"}/>
                    <MyTextField format={(e) => e ? notamMoment(e).format('YYMMDDHHmm') : null}
                                 source="b"/>
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.cLine"}/>
                    <MyTextField format={(e) => e ? notamMoment(e).format('YYMMDDHHmm') : null}
                                 source="c"/>
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.dLine"}/>
                    <TextField source={"d"}
                    />
                    {
                        hasHigherPermissions &&
                        <ArrayField source="etc.actDef" label='resources.notams.fields.actDefName'>
                            <Datagrid>
                                <SelectField source="periodMode"
                                             label='resources.notams.fields.actDef.periodMode'
                                             choices={activityTimeModes}
                                />
                                <SelectField source="repeatMode"
                                             label='resources.notams.fields.actDef.repeatMode'
                                             choices={activityRepeatModes}
                                />
                                <MyTextField format={(e) => e ? notamMoment(e).format('YYMMDDHHmm') : undefined}
                                             source="begin"
                                             label='resources.notams.fields.actDef.begin'
                                />
                                <MyTextField format={(e) => e ? notamMoment(e).format('YYMMDDHHmm') : undefined}
                                             source="end" label='resources.notams.fields.actDef.end'
                                />
                                <MyTextField source="months"
                                             label='resources.notams.fields.actDef.months'
                                             format={
                                                 (e) => {
                                                     return Array.isArray(e) ?
                                                         e.map(
                                                             (val) => parseInt(val) + 1
                                                         ).toString()
                                                         :
                                                         e
                                                 }
                                             }
                                />
                                <MyTextField source="days"
                                             label='resources.notams.fields.actDef.days'
                                             format={
                                                 (e) => {
                                                     return Array.isArray(e) ?
                                                         e.toString()
                                                         :
                                                         e
                                                 }
                                             }
                                />
                                <TextField source="weekdays"
                                           label='resources.notams.fields.actDef.weekdays'
                                />
                                <SelectField source="slotMode" choices={activitySlotModes}
                                             label='resources.notams.fields.actDef.slotMode'
                                />
                                <ArrayField source="hourSlots"
                                            label="resources.notams.fields.hourSlots"
                                >
                                    <Datagrid>
                                        <MyTextField format={(e) => notamMoment(e).format('HHmm')}
                                                     source="begin"
                                                     label='resources.notams.fields.actDef.begin'
                                        />
                                        <MyTextField format={(e) => notamMoment(e).format('HHmm')}
                                                     source="end"
                                                     label='resources.notams.fields.actDef.end'
                                        />
                                    </Datagrid>
                                </ArrayField>
                            </Datagrid>
                        </ArrayField>
                    }

                    <ReferenceManyField target="notamid"
                                        reference="notamActivations"
                                        linkType={false}
                                        label="resources.notams.fields.activations"
                                        addLabel={true}
                    >
                        <Datagrid>
                            <MyTextField format={(e) => notamMoment(e).format()}
                                         source="start"
                                         label='resources.notams.fields.actDef.begin'
                            />
                            <MyTextField format={(e) => notamMoment(e).format()}
                                         source="stop"
                                         label='resources.notams.fields.actDef.end'
                            />
                        </Datagrid>
                    </ReferenceManyField>
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.eLine"}/>
                    <TextField source={"e"}
                    />


                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.fLine"}/>

                    <TextField source="f"/>
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.gLine"}/>
                    <TextField source="g"/>
                    <br/>

                    <TranslatableTypography variant={'body2'} content={"resources.notams.fields.zoneDataLine"}/>
                    <TextField source="zonename"/>
                    <TextField source={`description.${locale.toLowerCase()}`}/>

                    <RecordConsumer>
                        {
                            ({record}) =>
                                <ZoneDataProvider zonename={record.zonename}>
                                    {
                                        ({zonedata}) =>
                                            <MyZoneField record={zonedata}
                                                         showEditLink={false}
                                                         showGeometry={hasHigherPermissions}/>

                                    }
                                </ZoneDataProvider>
                        }
                    </RecordConsumer>

                </SimpleShowLayout>
            </Show>)
    }
}

export const NotamsShow = enhance(NotamShowBase);
