import {put, takeEvery} from 'redux-saga/effects';

import { REHYDRATE } from 'redux-persist';
import {changeLocale} from "react-admin";
import get from "lodash.get";


//side effects of rehydration here
function* rehydrate({ type, payload }) {

    //yield put({ type: PERSIST_LOCALE, payload:{locale:payload.locale} });
    console.log('rehydrating', type, payload);

    const locale = get(payload, 'persistedLocale.locale');

    if (locale) {
        yield put(changeLocale(locale));
    }

    yield null

}

export default function* () {

    yield takeEvery(REHYDRATE, rehydrate);
}
