import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import inflection from 'inflection';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {getResources, translate} from 'ra-core';
import DefaultIcon from '@material-ui/icons/ViewList';
import MapIcon from '@material-ui/icons/Map';
import StoreProvider from '../MyStoreProvider'
import {isSuperAdmin} from "../utils/tempCommon";

//import DashboardMenuItem from './DashboardMenuItem';
//import MenuItemLink from './MenuItemLink';
//import Responsive from '../layout/Responsive';
import {Responsive, MenuItemLink, DashboardMenuItem, UserMenu} from 'ra-ui-materialui';
import {myThemeChange} from "../logic/theme/MyThemeActions";
//import CustomizedSvgIcon from "../../rt_map_ra2/components/prepared-components/CustomizedSvgIcon";
import {CSSTransition} from 'react-transition-group';
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography/Typography";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import withWidth, {isWidthDown, isWidthUp} from '@material-ui/core/withWidth';
import {toggleSidebar as toggleSidebarAction} from 'ra-core';
import {withRouter} from 'react-router-dom';
import ExpandingMenuItem from "./ExpandingMenuItem";

//based on ra 2.0.3
//added custom links
//added hidden resource with list

//import { DRAWER_WIDTH } from 'ra-ui-materialui/src/layout/Sidebar'

export const MENU_GROUPS = {
    logs: {
        name: 'logs',
        label: 'myroot.admin.logs'
    }
};

const themeDependentStyles = (theme) => ({
    root: {
        color: theme.palette.colorCircle.menuText,
        transition: "margin-left 600ms",
    },
    active: {
        backgroundColor: theme.palette.colorCircle.menuActiveBackground,
        color: theme.palette.colorCircle.menuActiveText
    },
    rootExpanding: {
        color: theme.palette.primary.contrastText,
        transition: "margin-left 600ms",
    },
});

const themeIndependentStyles = {
    //TODO Gens console error decorator for MenuItemLink
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: "0.90",
        //overflowY: "hidden",
        marginTop: "1.5rem"
    },
    logoArea: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        willChange: "transform",
        maxHeight: "400px"
    },
    logoImg: {
        objectFit: "contain",
        height: "68%",
        width: "90%"
    },
    logoImg2: {
        objectFit: "contain",
        height: "14%",
        width: "60%"

    },
    logoImg3: {
        objectFit: "contain",
        height: "28%",
        width: "65%"

    },
    typoInLogo: {
        objectFit: "contain",
        height: "4%",
        width: "auto",
    },
    footer: {
        alignSelf: "center",
        //margin: "1rem"
    },
    hidden: {
        display: "none"
    },
    icon: {
        paddingRight: '1.2em'
    },
    /**
     //Gens console error
     main: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: "1",
            }
     //Gens console error
     transitionStyles: {
        entering: { opacity: 0 },
        entered:  { opacity: 1 },
    },
     initStyle: {
        transition: `opacity 500ms ease-in-out`,
        opacity: 0,
    }**/
};

const styles = (theme) => {
    if (theme.palette.colorCircle) {
        return {...themeDependentStyles(theme), ...themeIndependentStyles}
    } else {
        return themeIndependentStyles
    }
};

const translatedResourceMenuName = (resource, translate) =>
    translate(`resources.${resource.name}.menu`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });


class Menu extends React.Component {

    state = {
        shouldAppear: false,
    };

    _collapseIfBreakpoint = ({down, up, props}) => {
        if (isWidthDown(down, props.width) && props.sidebarOpen) {
            props.toggleSidebar();
        } else if (isWidthUp(up, props.width) && !props.sidebarOpen) {
            props.toggleSidebar();
        }
    };

    _arePropsChanging = (previousProps, nextProps) => {
        return previousProps !== nextProps;
    };

    componentWillUpdate(nextProps, nextState, nextContext) {

        const isWidthChanging = this._arePropsChanging(this.props.width, nextProps.width);
        const isRouteChanging = this._arePropsChanging(this.props.location.pathname, nextProps.location.pathname);


        if (isWidthChanging || isRouteChanging) {
            this._checkAndSetSidebarCollapse(nextProps)
        }

    }

    _checkAndSetSidebarCollapse = (props) => {
        switch (props.location.pathname) {
            case "/":
                this._collapseIfBreakpoint({down: 'sm', up: 'md', props});
                break;
            case "/notams":
                this._collapseIfBreakpoint({down: 'lg', up: 'xl', props});
                break;
            default:
                this._collapseIfBreakpoint({down: 'md', up: 'lg', props});
        }
    };

    componentDidMount() {
        this.setState(
            {
                shouldAppear: true
            }
        );
        this._checkAndSetSidebarCollapse(this.props);
    }

    render() {
        const {
            classes,
            className,
            dense,
            hasDashboard,
            onMenuClick,
            pathname,
            resources,
            translate,
            logout,
            sidebarOpen,
            permissions,
            width,
            location,
            ...rest
        } = this.props;

        //console.log('====my menu props', this.props.resources);
        const store = StoreProvider.getStore();
        const dispatchChange = () => {
            store.dispatch(myThemeChange());
        };

        const locale = this.props.locale;
        //fixing warning
        const menuLinkClasses = {root: classes.root, active: classes.active, icon: classes.icon};

        const filtered = resources
            .filter(r => {
                    //console.log('r', r);
                    return (r.hasList && !r.options.hideInMenu)
                }
            );
        const menuItems = [];

        for (const resource of filtered) {
            if (!resource.options.menuGroup) menuItems.push(
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={translatedResourceMenuName(resource, translate)}
                    leftIcon={
                        resource.icon ? <resource.icon/> : <DefaultIcon/>
                    }
                    onClick={onMenuClick}
                    dense={dense}
                    classes={menuLinkClasses}
                    style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}
                />
            );
            else {
                const expandingMenuItems = menuItems.find(e => e.props.group === resource.options.menuGroup);
                const menuItemLink =
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceMenuName(resource, translate)}
                        leftIcon={
                            resource.icon ? <resource.icon/> : <DefaultIcon/>
                        }
                        onClick={onMenuClick}
                        dense={dense}
                        classes={{
                            ...menuLinkClasses,
                            root: undefined
                        }}
                        style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}
                    />;

                if (!!expandingMenuItems) {
                    expandingMenuItems.props.items.push(menuItemLink);
                } else menuItems.push(
                    <ExpandingMenuItem
                        key={resource.name}
                        primaryText={translate(resource.options.menuGroup.label)}
                        leftIcon={
                            resource.icon ? <resource.icon/> : <DefaultIcon/>
                        }
                        dense={dense}
                        classes={menuLinkClasses}
                        style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}
                        group={resource.options.menuGroup}
                        items={[menuItemLink]}
                        pathname={location.pathname}
                    />
                )
            }
        }

        return (
            <CSSTransition timeout={700} classNames="sidemenu" in={this.state.shouldAppear} unmountOnExit
            >
                <div className={classes.main}>
                    <div>
                        {hasDashboard &&
                        <DashboardMenuItem onClick={onMenuClick}
                                           classes={menuLinkClasses}
                                           style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}/>}
                        {menuItems}
                        {/*25.06.18 custom links*/}
                        {/*<MenuItemLink*/}
                        {/*key='map'*/}
                        {/*to={'/map'}*/}
                        {/*primaryText='map'*/}
                        {/*leftIcon={<MapIcon/>}*/}
                        {/*onClick={onMenuClick}*/}
                        {/*dense={dense}*/}
                        {/*classes={menuLinkClasses}*/}
                        {/*//style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}*/}
                        {/*//style={{display: "none"}} //hiding for v1.1*/}
                        {/*/>*/}
                        {/*<MenuItemLink*/}
                        {/*key='faq'*/}
                        {/*to={`/docs/faq}`} */}
                        {/*primaryText='faq'*/}
                        {/*leftIcon={<DefaultIcon/>}*/}
                        {/*onClick={onMenuClick}*/}
                        {/*dense={dense}*/}
                        {/*classes={menuLinkClasses}*/}
                        {/*style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}*/}
                        {/*//style={{display: "none"}} //hiding for v1.1*/}
                        {/*/>*/}
                        {/*<MenuItemLink*/}
                        {/*key='help'*/}
                        {/*to={`/docs/help/`} */}
                        {/*primaryText='help'*/}
                        {/*leftIcon={<DefaultIcon/>}*/}
                        {/*onClick={onMenuClick}*/}
                        {/*dense={dense}*/}
                        {/*classes={menuLinkClasses}*/}
                        {/*style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}*/}
                        {/*//style={{display: "none"}} //hiding for v1.1*/}
                        {/*/>*/}
                        {/*<WithPermissions*/}
                        {/*render={({permissions}) => {*/}
                        {/*//console.log('WithPermissions', permissions);*/}
                        {/*return (*/}
                        {/*permissions && permissions.roles.indexOf('operator') !== -1*/}
                        {/*? <MenuItemLink*/}
                        {/*key='rodo'*/}
                        {/*to={`/docs/rodo`}*/}
                        {/*primaryText='rodo'*/}
                        {/*leftIcon={<DefaultIcon/>}*/}
                        {/*onClick={onMenuClick}*/}
                        {/*dense={dense}*/}
                        {/*classes={menuLinkClasses}*/}
                        {/*style={sidebarOpen ? undefined : {marginLeft: "0px", marginRight: "0px"}}*/}
                        {/*//style={{display: "none"}} //hiding for v1.1*/}
                        {/*/>*/}
                        {/*: null*/}
                        {/*)*/}
                        {/*}*/}
                        {/*}*/}
                        {/*/>*/}
                        {/*<ListItem*/}
                        {/*onClick={dispatchChange}*/}
                        {/*className={sidebarOpen ? classes.root : classnames(classes.root, classes.hidden)}*/}
                        {/*button*/}
                        {/*style={{visibility: "hidden"}}*/}
                        {/*>*/}
                        {/*/!*<CustomizedSvgIcon path="img/icons/circle2km-edit_v2.svg" iconSize="mini"/>*!/*/}
                        {/*Theme toggle (test)*/}
                        {/*</ListItem>*/}
                    </div>
                    <Responsive xsmall={logout} medium={null}/>
                    <div
                        className={sidebarOpen ? classnames(classes.footer, classes.logoArea) : classnames(classes.footer, classes.hidden, classes.logoArea)}>
                        <img src="./pansautm_logo.png" className={classes.logoImg}/>

                    </div>

                </div>
            </CSSTransition>
        );
    }
}


Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
    pathname: PropTypes.string,
    resources: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired,
};

Menu.defaultProps = {
    onMenuClick: () => null,
};

const mapStateToProps = state => ({
    sidebarOpen: state.admin.ui.sidebarOpen,
    resources: getResources(state),
    pathname: state.routing.location.pathname, // used to force redraw on navigation
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        //DN Had to remove due to lock on props update
        // {}, // Avoid connect passing dispatch in props,
        // null,
        {
            areStatePropsEqual: (prev, next) =>
                prev.resources.every(
                    (value, index) => value === next.resources[index]),// shallow compare resources
            toggleSidebar: toggleSidebarAction,
        }
    ),
    withStyles(styles),
    withWidth(),
    withRouter,
);

export default enhance(Menu);
