import { all, put, takeEvery, select, call } from "redux-saga/effects";

import { myUrls, useMocks } from "../../MyConfig";
import axios from "axios";

import {
    FETCH_ALL_ZONETYPES_V2_RQ,
    STORE_ALL_ZONETYPES_V2
} from "../constants/ZoneTypesV2Constants";
//import { showErrorSnackbar, SNACKBAR_DURATIONS } from "../transforms/SnackBarUtils";
import { showNotification } from 'react-admin';

import {getZoneRules as preprocessZoneTypes} from '../../modules2lib/examples/myMobileWrapper'

//umph, decoded placeholder
//import zoneTypesMock from "../mocks/zonetypesV2";
import ZoneTypesV2Decoded from "../../modules2lib/ZoneTypesV2Decoded";
import { groupBy, merge, keyBy } from "lodash";

const zoneTypesMock = []

export function* handleFetch({ type, payload }) {

    try {

        //FIXME!
        let url = myUrls.getZoneTypesV2Url;
        //url += "/?filter=" + JSON.stringify(filter);

        let response;

        const useMock = useMocks.zoneTypesV2;

        if (!useMock) {
            const promise = axios(encodeURI(url), {
                method: "get"
            });

            response = yield call(() => promise);
        } else {
            response = {
                data: zoneTypesMock
            };
        }
        ///console.log("=====fetch zoneType responses", response);

        const grouped = keyBy(response.data, function(o) {
            return o.name;
        });

        //console.log("=====fetch zoneType grouped", grouped);

        //temp!!!
        const merged = merge(ZoneTypesV2Decoded, grouped);

        //console.log(merged);

        const preprocessed = preprocessZoneTypes(merged);

        //console.log("=====fetch zonetypes response", preprocessed);

        yield put({
            type: STORE_ALL_ZONETYPES_V2,
            payload: {
                ...preprocessed,
            }
        });

    } catch (e) {
        //snackbar for now.
        console.log("zoneType.fetchErrorToast", e);

        console.error('handleFetch zoneType.fetchErrorToast error', e);
        yield put(showNotification('zoneType.fetchErrorToast fetch error', 'warning'));
    }
}

export default function*() {
    yield all([
        takeEvery(FETCH_ALL_ZONETYPES_V2_RQ, handleFetch),
    ]);
}
