
import {PERSIST_LOCALE} from "../constants/PersistLocaleContants";

const initialState = {};

//hmm should be combined to one persistable store? with myAuth etc?
const persistLocaleReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {


        case PERSIST_LOCALE:

            return {...previousState, ...payload};

        default:
            return previousState;
    }
};

export default persistLocaleReducer;
