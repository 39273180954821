import {ReferenceArrayField, Datagrid, TextField} from "ra-ui-materialui";
import {FormDataConsumer} from 'react-admin';
import React from "react";
import {RESOURCES} from "../../../logic/MyRestConfig";
import shouldUpdate from 'recompose/shouldUpdate';
import MyTextField from "../../MyTextField";
import {formatHourSlot} from "../../../utils/acmHelpers";
import {colorListStyles} from "./AcmListStyles";
import compose from 'recompose/compose';
import {withStyles} from "@material-ui/core";
import TranslatableTypography from "../../TranslatableTypography";


const updateCheck = (prevProps, nextProps) => prevProps.record[prevProps.source] !== nextProps.record[nextProps.source];

/**
 * Field that fetches parent acm rule data based on parent id passes in source-record pair
 */
const PureAcmParentField = ({source, record, resource, basePath, classes, ...props}) => {

    const refArrayRecord = {
        id: [record[source]]
    };

    return (
        <>
            <TranslatableTypography variant={'body2'} content={`resources.${RESOURCES.ACM_RULES}.defaultRule`}/>
            <ReferenceArrayField label='test' reference={RESOURCES.ACM_RULES} record={refArrayRecord} source='id'
                                 resource={resource} basePath={basePath}>
                <Datagrid classes={classes}>
                    <TextField source="designator"/>
                    <FormDataConsumer label={`resources.${RESOURCES.ACM_RULES}.fields.start`}>
                        {
                            ({formData, ...rest}) => (
                                <MyTextField source={'start'} {...rest} format={formatHourSlot(true)}/>
                            )
                        }
                    </FormDataConsumer>
                    <TextField source="blue"/>
                    <TextField source="green"/>
                    <TextField source="yellow"/>
                    <TextField source="red"/>
                </Datagrid>
            </ReferenceArrayField>
            <br/>
            <br/>
        </>
    )
};

export const AcmParentField = compose(
    shouldUpdate(updateCheck),
    withStyles(colorListStyles)
)(PureAcmParentField);