
import {currentCountry, myUrls} from "../../MyConfig";

import axios from "axios";

import {groupBy} from "lodash";
import moment from "moment";
import { isoString2UnixTime } from "../../modules2lib/common/src/TimeUtils";
import {NOTAM_DEACTIVATION_SUPPORT} from "../../Configs";

let cachedNotamActivations;

function decorateNotamActivation(data) {
    console.log('===Notams data',  data);

    const combined = [];

    //yikes! a LOT of things there
    for (let i = 0; i < data.length; i++ ) {

        const currNotam = data[i];
        currNotam.__startT = isoString2UnixTime(currNotam.b);
        currNotam.__stopT = isoString2UnixTime(currNotam.c);

        //huh, using q for now
        let failed = false;
        try {

            console.log(currNotam.etc);

            currNotam.__min = parseInt(currNotam.f);
            currNotam.__max = parseInt(currNotam.g);
        } catch (e) {
            console.error('failed conversion of notam data', currNotam);
            failed = true;
        }

        currNotam.__min = (failed || Number.isNaN(currNotam.__min))?Number.MIN_SAFE_INTEGER:currNotam.__min;
        currNotam.__max = (failed || Number.isNaN(currNotam.__max))?Number.MAX_SAFE_INTEGER:currNotam.__max;

        const activations = currNotam.notamActivations;
        for (let j = 0; j < activations.length; j++ ) {
            //console.log(JSON.stringify(activations[j].etc));
            //console.log(activations[j].note);

            activations[j].__startT = isoString2UnixTime(activations[j].start);
            activations[j].__stopT = isoString2UnixTime(activations[j].stop);
            activations[j].__min = currNotam.__min;
            activations[j].__max = currNotam.__max;

            //hmm
            activations[j].min = currNotam.__min;
            activations[j].max = currNotam.__max;
            //activations[j].zonename = currNotam.zonename;

            //combined.push(activations[j])
        }

        combined.push(currNotam)

    }

    let grouped = groupBy(combined, 'zonename');

    console.log('===Notam activations ret',  grouped);
    return grouped;
}

export function requestNotams() {

    //let url = urls.getNotamActivactionsUrl;
    let url = myUrls.getNotamsUrl;
    let aupZuluStartHour = currentCountry.aupZuluStartHour;

    let now = moment().clone(); //was timezoned
    let aupZuluStart = moment.utc(aupZuluStartHour, 'hh:mm').clone();

    ///beetween 0 and zulu we'll be in previus day aup. aargh....
    if (now.isBefore(aupZuluStart)) {
        //Logger.get(LOGGERS.MAIN).warn('=== checkme! nightly before 6 am rollback');
        //logger.debug('rollback 2 previous day!!..');
        now.subtract(1,'d');
        aupZuluStart.subtract(1, 'd');
        //aupZuluStart.subtract(1, 'd');
        //dt_from.subtract(1,'d') ;
        //dt_to.subtract(1,'d');
    }

    const notamActivationsStopFilterFrom = aupZuluStart.toISOString();
    const notamActivationsStartFilterUntil = aupZuluStart.clone().add('1','d').toISOString();

    console.log('=== aupZuluStart', notamActivationsStopFilterFrom);
    console.log('=== aupZuluEnd', notamActivationsStartFilterUntil);

    const filter = {
        //fields:{"id":true,"designator":true,"start":true,"stop":true,"min":true,"max":true,"etc":false},
        include: { "relation": "notamActivations",
            scope: {
                where: {
                    and: [
                        {"start": {"lt": notamActivationsStartFilterUntil}},
                        {"stop": {"gt": notamActivationsStopFilterFrom}}
                    ],
                },
                "order":"start ASC", // same time filtering /order as in parent.
            }
        },
        where: {
            and: [
                {"b": {"lt": notamActivationsStartFilterUntil}}, //b aka start
                {"c": {"gt": notamActivationsStopFilterFrom}}, //c aka stop
                //FIXME when mobile app is deployed
                //{"active": true})  //support of disabled notams.
            ],
        },
        order:"b ASC", // same time filtering /order as in parent.
        //limit : 2
    };

    //FIXME when mobile app is deployed
    if (NOTAM_DEACTIVATION_SUPPORT) {
        filter.where.and.push({"active": true});  //support of disabled notams.
    }

    url += '/?filter=' + JSON.stringify(filter);
    console.log('getNotamsUrl', url);

    const promise = axios(encodeURI(url), {
        method: 'get',
    });

    return promise
        .then(response => {
            console.log('requestNotamActivations axios response', response);

            cachedNotamActivations = decorateNotamActivation(response.data);

            return Promise.resolve(cachedNotamActivations);
        })
        .catch(function (error) {
            //console.dir(promise);
            console.warn('requestNotamActivations axios error', error);

            //fixme with notification here? or in zone saga?

            return Promise.reject('axios error')
        });
}
