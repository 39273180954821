import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import {urls} from "../../Configs";
import axios from "axios";
import { showNotification, hideNotification } from 'react-admin';

import {FETCH_ALL_RTDM_AEROBITS_TICKS_RQ, STORE_ALL_RTDM_AEROBITS_TICKS} from "../constants/RtdmAerobitsTicksConstants";
import {
    MAP_UI_RTDM_AEROBITS_TICKS_SHOW,
    MAP_RTDM_AEROBITS_TICKS_ENABLE_RQ,
    MAP_RTDM_AEROBITS_TICKS_ENABLED,
    MAP_UI_HEIGHT_FILTER,
    PANDORA_TRAFFIC_HEIGHT_FILTER,
} from "../constants/MapUxConfigConstants";
import {TIMER_TICK_RQ} from "../constants/TickConstants";
import {FEATURES_V1_2_ENUMS, enabledFeatures} from "../../Configs";
const AEROBITS_RTDM_STREAM = enabledFeatures.includes(FEATURES_V1_2_ENUMS.AEROBITS_RTDM_STREAM_WIP);

const getMyAppExtras = (state) => state.myAppExtras;
const getMapUxConfig = (state) => state.mapUxConfig;
const getInspectData = (state) => state.inspectData;
const getRtdmAerobitsTicks = (state) => state.rtdmAerobitsTicks;

//should be per app? pandora is default now
const isPandora = true;

function* handleFetch({ type, payload }) {
    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiRtdmSourcesEnabled) {
    //     console.error('lprHelipads fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);

    //console.log('payload', payload);
    //console.log('fetch rtdmAerobitsTicks start');

    yield put({ type: FETCH_START });

    try {

        const rtdmAerobitsUrl = urls.rtdmAerobitsUrl;

        const opts = {
            method:'get',
        };

        const promise = axios(rtdmAerobitsUrl, opts);

        const response = yield call(() => promise);

        const inspectData = yield select(getInspectData);

        const mapUxConfig = yield select(getMapUxConfig);

        if (isPandora) {
            yield put({type: MAP_UI_RTDM_AEROBITS_TICKS_SHOW, payload:mapUxConfig.pandoraLprTrafficEnabled});
            yield put({type: MAP_RTDM_AEROBITS_TICKS_ENABLED, payload:mapUxConfig.pandoraLprTrafficEnabled});
        } else {
            yield put({type: MAP_UI_RTDM_AEROBITS_TICKS_SHOW, payload:true});
            yield put({type: MAP_RTDM_AEROBITS_TICKS_ENABLED, payload:true});
        }

        const {bottomCutoff, topCutoff} = (isPandora)? mapUxConfig.trafficHeightFilter:mapUxConfig.heightFilter;

        const nextPayload = {
            rawData:response.data,
            currentInspectData:inspectData,
            meta:{
                bottomCutoff,
                topCutoff
            }
        };

        yield put({ type: STORE_ALL_RTDM_AEROBITS_TICKS, payload:nextPayload});

        yield put({ type: FETCH_END });

    } catch (error) {
        console.error('rtdmAerobitsTicks fetch error', error);
        yield put(hideNotification());
        yield put(showNotification('rtdmAerobitsTicks fetch error', 'warning'));
        yield put({ type: FETCH_END });
    }

}


//fixme show preferences?
function* handleEnableToggle({payload}) {
    //console.error('handle FETCH_ALL_RTDM_AEROBITS_TICKS_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_RTDM_AEROBITS_TICKS_RQ});
    } else {
        //just disabling

        yield put({type: MAP_RTDM_AEROBITS_TICKS_ENABLED, payload:false});
        yield put({type: MAP_UI_RTDM_AEROBITS_TICKS_SHOW, payload:false});
    }
}

function* handleTick({payload}) {
    //console.warn('handle TIMER_TICK_RQ for aerobits tick, payload:', payload);

    //every tick
    if (payload.ticks) {
        const mapUxConfig = yield select(getMapUxConfig);
        //console.warn('handle TIMER_TICK_RQ for lpr tick, mapUxConfig:', mapUxConfig);

        //let's not fetch in background.. only when visible
        if (mapUxConfig.uiRtdmSoapLprUnitsTicksShow) {
            yield put({type: FETCH_ALL_RTDM_AEROBITS_TICKS_RQ});
        }
    }
}

//force recalc deck filters
function* handleDisplayPrefsChange({ type, payload }) {

    console.log('on handle display prefs change', type, payload);

    const rtdmAerobitsTicks = yield select(getRtdmAerobitsTicks);

    const mapUxConfig = yield select(getMapUxConfig);
    const inspectData = yield select(getInspectData);


    const {bottomCutoff, topCutoff} = (isPandora)? mapUxConfig.trafficHeightFilter:mapUxConfig.heightFilter;

    const nextPayload = {
        rawData: rtdmAerobitsTicks.rawData,
        currentInspectData: inspectData,
        meta:{
            bottomCutoff,
            topCutoff
        }
    };

    yield put({ type: STORE_ALL_RTDM_AEROBITS_TICKS, payload:nextPayload});
}

export default function* () {

    const fx = [
        takeEvery(MAP_RTDM_AEROBITS_TICKS_ENABLE_RQ, handleEnableToggle),

        takeEvery(FETCH_ALL_RTDM_AEROBITS_TICKS_RQ, handleFetch),

        takeEvery(TIMER_TICK_RQ, handleTick),

    ];

    if (isPandora) {
        fx.push(takeEvery(PANDORA_TRAFFIC_HEIGHT_FILTER, handleDisplayPrefsChange))
    } else {
        fx.push(takeEvery(MAP_UI_HEIGHT_FILTER, handleDisplayPrefsChange))
    }

    if (AEROBITS_RTDM_STREAM) {
        yield all(fx);
    } else {
        yield null;
    }
}
