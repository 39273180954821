//hmm. tree shake should clean configs, but not tested at all
//possibly import should be rewritten for modularization

//import AsyncStorage from '@react-native-community/async-storage';

//import {KeychainStorage}  from "../../logic/modules2lib/dr2-keychain-storage";

import {
    urls,
    Apps,
    builtOn,
    devBuild,
    defaultCountry,
} from './Configs'


import { WeightClassesEnums } from "./LogicV1Redux/constants/CheckinsV2Enums";
import {storages as _storages} from './Configs'

export * from './DebugConfig'

export const storages = _storages;

export const currentCountry = defaultCountry;

export const myUrls = {
    apiBase: urls.apiBaseV2,
    ...urls
    //noAccessPage,
    //defaultREST:operatorUrls,
};

//fixme, configs per app got messy
export const AppConfig = {
    appMode:Apps.MOBILE.type,
    app: Apps.MOBILE,
    builtOn:builtOn,
    devBuild:devBuild,
    apiKey: 'AikxzLwfUMbsetHE'
};


export const supportedLangs = ['en','pl'];
export const fallbackLang = ['en'];


//TODO if not works wit persist replace with init phase and myconfig.
export const initialState = {
    mapUxConfig:{
        heightFilter: {
            bottomCutoff: 0,
            topCutoff: 9500,
        },
        //looks like should be indepedent for pandora
        trafficHeightFilter: {
            bottomCutoff: 0,
            topCutoff: 9500,
        },
        visibilityPrefs:{

        },
        viewportData: currentCountry.viewportData,
    },
    checkinsV2:{mapboxData:{active:null}},
    myAppExtras:AppConfig,
    threeLights: {
        calcParams: {
            weightClass: WeightClassesEnums.MEDIUM,
            aglMinMax: [0, 150] //currently only max used... but let's be prepared for ranged calcs
        },
        locatorFeature: {
            "type": "Feature",
            "properties": {
                __srtmV1Gnd:113
            },
            "geometry": {
                "type": "Point",
                "coordinates": currentCountry.viewportData.center
            }
        }
    }
};
