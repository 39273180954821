
import {FETCH_ALL_CHECKINS_V2_RQ, POST_OWNED_CHECKIN_V2, STORE_ALL_CHECKINS_V2, POST_ATC_REVIEWED_CHECKIN_V2} from "../constants/CheckinsV2Constants";

export const fetchAllCheckinsV2Rq = (payload) => {
    console.log('fetchAllCheckinsV2Rq', payload);
    return ({
        type: FETCH_ALL_CHECKINS_V2_RQ,
        payload,
    });
};

export const storeAllCheckinsV2 = (payload) => {
    console.log('storeAllCheckinsV2', payload);
    return ({
        type: STORE_ALL_CHECKINS_V2,
        payload,
    });
};

export const postOwnedCheckinV2 = payload => {
    console.log('postOwnedCheckinV2', payload);
    return ({
        type: POST_OWNED_CHECKIN_V2,
        payload,
    });
};

export const postAtcReviewedCheckinV2 = payload => {
    console.log('postCheckedCheckinV2', payload);
    return ({
        type: POST_ATC_REVIEWED_CHECKIN_V2,
        payload,
    });
};
