import { all, put, takeEvery,  select, call, take } from 'redux-saga/effects';
import {
    SAVE_MISSION_DATA_V2_RQ,
    SAVE_MISSION_STATUS_V2_RQ,
    CLONE_MISSION_V2,
    CHECK_HOLIDAYS,
    VLOS_OFFICIAL_CONSIDER_TIME,
    BVLOS_OFFICIAL_CONSIDER_TIME,
    MISSION_TERM_DIALOG_CALLBACK, MISSION_NO_CONFLICTS_DIALOG_CALLBACK,
} from '../constants/MissionsV2Constants';

import {currentCountry} from "../../MyConfig";

import DataProvider from "../../dr_ra2/MyDataProvider";
//import delay_p from '@redux-saga/delay-p'; //hmm
import {delay as delay_p} from 'redux-saga'; //reverting to old version
import { showNotification } from 'react-admin';

import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import {serializeLegData} from "../oldLogic/MyMissionPlanner";

import { refreshView } from 'react-admin';
import { push } from 'react-router-redux';

import {m2ftCeiling} from "../../LogicV1Redux/oldLogic/GeoConverters";
import {unixTimeNow} from "../../LogicV1Redux/oldLogic/TimeConverters";
import {MissionType, MissionV2Statuses} from "../constants/MissionsV2Enums";
import {MAX_LEG_AREA_KM_SQ} from "../constants/LegsV2Constants";
import {polygon as turfPoly} from "@turf/helpers";

import {
    LOADING_START,
    LOADING_END,
    TOGGLE_MISSION_TERM_DIALOG,
    TOGGLE_MISSION_NO_CONFLICTS_DIALOG
} from "../../dr_ra2/logic/ui/MyUiActions";
import omit from "lodash.omit";
import {srtmHeightAsPromise} from '../oldLogic/MySrtm'
import {Holidays} from "../../dr_ra2/utils/momentHelpers";
import moment from "moment";
import {MY_STORE_SETSELF_USERSTATE} from "../../LogicV2/auth/MyAuthConstants";
import {PRIMITIVES} from "../../gl_map/glSetups/deckDraw/DrawEditorEnums";
import {has, isEmpty, cloneDeep} from "lodash";
import {deserializeMissionData} from "../oldLogic/MissionsListV2";
import {turfWithin} from "../../gl_map/glSetups/oldCommon/commonTurf";
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";
import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";

const MISSION_112_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.MISSION_112);

//hmm, move shorthands to related? read more about memoization

const getAppExtras = (state) => state.myAppExtras;
const getMySelf = (state) => state.mySelf;
const getZoneTypesV2 = (state) => state.zoneTypesV2;
const getZonesV2 = (state) => state.zonesV2;

export const getEditablePrimitives = (state) => state.drawEditorData.editablePrimitives;


function processSrtmResponse(response, leg) {
    //fixme! should be in serialize, but we call serialize earlier.. to rethink

    leg.etc.srtmV1Gnd = Math.round(response.data.hgtElevation);
    leg.min = (leg.etc.aglMinMax[0] === 0)
        ? 0 //hacking ground for Jasiek
        :  m2ftCeiling(leg.etc.aglMinMax[0]+leg.etc.srtmV1Gnd);
    leg.max = m2ftCeiling(leg.etc.aglMinMax[1]+leg.etc.srtmV1Gnd);

    return leg;
}

//const promise = axios(url, opts);

//todo check if FETCH START/END should be added - yup, saving not launched by form
//btw add passing dataProvider in init? like authSaga? not sure...
//fixme add description, as it is messy approach (should be backend?)
//payload is mission record from lb (combined doc with legs etc..)
function* handleFullSave({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'myroot.message.saving',
    });

    //yield put(showNotification('missionsV2 save here', 'warning'));
    try {

        const appExtras = yield select(getAppExtras);

        const editablePrimitives = yield select(getEditablePrimitives);

        console.warn('(mss) handleFullSave SAVE_MISSION_DATA_V2_RQ');
        console.log('(mss) missionsV2 save here', type, payload);
        console.log('(mss) missionsV2 save here editable prim', editablePrimitives);

        console.log('(mss) currc,', currentCountry);

        //mission data splitting/cleaning for save ...

        const transformed = transformMissionPayload(payload, editablePrimitives, currentCountry.borderData);
        console.log('(mss) missionsV2 save here transformed', transformed);


        if (transformed.legsSizesTooLarge) {
            yield earlyFailSafe('resources.missions.notifications.legsTooLargeErr');
            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));
            return;
        }

        if (transformed.legsWithInvalidGeometry) {
            yield earlyFailSafe('resources.missions.notifications.legsNotValidErr');
            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));
            return;
        }

        if (transformed.legsOutsideBorder) {
            yield earlyFailSafe('resources.missions.notifications.legsOutsideBorder');
            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));
            return;
        }

        //not working.. (force refresh?)
        //yield put({ type: CLEAR_ALL_PRIMITIVES }); //should be clear editable, but maybe we should readd instead..


        // due to issues with arrays containing empty objects and infite loads on drone arrays issue #276
        let cleanedMissionRecord = _cleanMissionRecordForSave(transformed.missionRecord);


        let missionId = cleanedMissionRecord.uid;
        const dataProvider = DataProvider.getDataProvider();

        console.warn('===============missionId', missionId);
        let missionSaved, isCreate;
        isCreate = (!missionId);
        if (isCreate) {
            console.warn('add new mission support');
            missionSaved = yield dataProvider(API_VERBS.CREATE, 'missions', {data: {
                    ...cleanedMissionRecord,
                    status: MissionV2Statuses.CREATED,
                }});
            console.warn('=================new mission saved', missionSaved);
            //throw "new mission saved"
            missionId = missionSaved.data.uid;

        } else {
            missionSaved = yield dataProvider(API_VERBS.UPDATE, 'missions', {id: missionId, data: cleanedMissionRecord});
            console.warn('=================old mission updated', missionSaved);

        }
        //fixme -> is one by one, should be parallel?
        //and foreach is not supported by saga, funny
        for (let i = 0; i < transformed.legsToUpdate.length; i++) {
            let currLeg = transformed.legsToUpdate[i];

            console.warn('(mss) ======leg update here',currLeg );
            const srtmResponse = yield srtmHeightAsPromise(appExtras.app.name, currLeg.etc.centroid);
            console.warn("(mss) ======srtm response", srtmResponse);
            //should be server side! (api security)
            currLeg = processSrtmResponse(srtmResponse, currLeg);
            const legUpdated = yield dataProvider(API_VERBS.UPDATE, 'legs', {id:currLeg.uid, data:currLeg});
            console.log('legUpdated', legUpdated);
        }

        for (let j = 0; j < transformed.legsToCreate.length; j++) {
            let currLeg = transformed.legsToCreate[j];
            console.warn('(mss) ======leg create here', currLeg );
            //should be server side! (api security)
            const srtmResponse = yield srtmHeightAsPromise(appExtras.app.name, currLeg.etc.centroid);
            console.warn("(mss) ======srtm response", srtmResponse);
            currLeg = processSrtmResponse(srtmResponse, currLeg);

            const legCreated = yield dataProvider(API_VERBS.CREATE, 'legs', {id:missionId, data:currLeg});
            console.log('legCreated', legCreated);
        }

        for (let k = 0; k < transformed.legsUidsToDelete.length; k++) {

            console.warn('(mss) ======leg delete here',transformed.legsUidsToDelete[k]);

            //hack/monkey patch
            // -> for some misty reasons sometimes during fast edits mission has still already removed legs..
            //(either react admin get one cache, or something else.. )
            //so..
            try {
                const legDeleted = yield dataProvider(API_VERBS.DELETE, 'legs', {id: transformed.legsUidsToDelete[k]})
                console.log('legDeleted', legDeleted);

            } catch (e) {
                console.warn('mss error in deleting legs', e);
                if (e.message && e.message.indexOf('LEGID_INVALID') !== -1) {
                    console.warn('mss silently skippping LEGID_INVALID');
                    //to finish and hide

                    yield put(showNotification('leg already deleted?', 'warning'));

                } else {
                    throw e;
                }
            }
        }
        yield put(showNotification('resources.missions.notifications.awaitingForResolveMsg', 'info'));

        //currently (after backend update !419), intersects are calculated DURING create/update leg
        // so delay is probably unnecessary. but... let's keep it for now (1.1 UX), just reducing to 1000ms (was 2000)
        yield call(delay_p, 1000);
        //yield put({ type: CLEAR_ALL_PRIMITIVES });

        yield put({ type: FETCH_END });

        if (isCreate) {
            yield put(push(`/missions/${missionId}/edit`));
            yield put({type: LOADING_END});
        }
        else {
            yield put(refreshView()); //force refresh
            yield put({type: LOADING_END});
        }

    } catch (error) {
        console.error('(mss) handleSave SAVE_MISSION_DATA_V2_RQ error', error);
        yield put(showNotification('resources.missions.notifications.saveFailedErr', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});

    }
}

//probably we should add double check for dirty mission data here
//for now only on mission form (needed anyway to disable send button)
//anyway quick and diryt
//payload is cleaned record + custom __msg
function* handleStatusSave({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'myroot.message.saving'
    });

    try {
        const appExtras = yield select(getAppExtras);
        const mySelf = yield select(getMySelf);

        console.warn('handleFetch SAVE_MISSION_STATUS_V2_RQ', appExtras, mySelf);
        console.log('===========missionsV2 save status here', type, payload);

        const missionStartDay = payload.start;
        const missionStartYear = moment(missionStartDay).year();
        const nowYear = moment().year();

        const missionType = payload.type;

        if(!Holidays('pl').hasYears(nowYear, missionStartYear)) {
            yield call(fetchHolidays, {country: 'pl', year: missionStartYear});
        }

        const userState = yield select(
            (state) => state.mySelf.userState
        );

        let missionId = payload.uid; //cannot save status before creating, so we have uid always
        //const data = {status: payload.status};
        const data = {};
        const status = payload.status;


        //fixme temporary hack for passing multiple reject/accept msg
        // aka v1 for madrid with history
        const dataProvider = DataProvider.getDataProvider();

        const zoneTypesV2 = yield select(getZoneTypesV2);
        const zonesV2 = yield select(getZonesV2);

        if(_isVlosNoConflictsMission(payload, missionType, zoneTypesV2, zonesV2)) {
            yield put({
                type: TOGGLE_MISSION_NO_CONFLICTS_DIALOG,
                show: true
            });
            const {response} = yield take(MISSION_NO_CONFLICTS_DIALOG_CALLBACK);
            yield put({
                type: TOGGLE_MISSION_NO_CONFLICTS_DIALOG,
                show: false
            });
            data.etc = {
                ...payload.__etc,
                noConflictsDialogUnderstood: response //hmm security?
            };
            const missionSaved = yield dataProvider(API_VERBS.UPDATE, 'missions', {id: missionId, data});

            yield put(push('/missions'));
            yield put({type: FETCH_END});
            yield put({type: LOADING_END});
            return;
        }

        if(_isTermNotRetained(missionStartDay, missionType) && userState.canCalcHolidays) {
            yield put({
                type: TOGGLE_MISSION_TERM_DIALOG,
                show: true
            });
            console.log("WAIT FOR MISSION_TERM_DIALOG_CALLBACK");
            const {response} = yield take(MISSION_TERM_DIALOG_CALLBACK);
            console.log("MISSION_TERM_DIALOG_CALLBACK", response);
            //If user canceled, break the saga
            if(!response) {
                console.log("MISSION_TERM_DIALOG_CALLBACK response FALSE");
                yield put({ type: FETCH_END });
                yield put({type: LOADING_END});
                yield put({
                    type: TOGGLE_MISSION_TERM_DIALOG,
                    show: false
                });
                return;
            }
            //Else close window and continue
            else {
                console.log("MISSION_TERM_DIALOG_CALLBACK response true");
                yield put({
                    type: TOGGLE_MISSION_TERM_DIALOG,
                    show: false
                });
            }
        }
        console.log("CONTINUE SAVE");


        if (appExtras.app.name === 'DR2_GOV') {
            //etc no longer used by GOV!
            //data.etc = {...payload.__etc};

            const myDefaultUnit = mySelf.units[0]; //hmm. fix or remove multi unit user

            //gov, so for now assuming correct data
            const newMsgBlock = {
                status,
                issuerEmail:mySelf.email,
                timestamp:unixTimeNow(),
                issuerUnitId:myDefaultUnit.uid,
                issuerUnitName:myDefaultUnit.name //yup, overhead, but no unit access for operator today..
            };

            if (payload.__msg) {
                newMsgBlock.msg = payload.__msg;
            }

            data.govMsgBlock = newMsgBlock;

            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));

            //we are to force status of owned zonelegs with one click.. so...
            //btw - some optimization here? (batch update?)
            //and one thing more -> what about leg status change/update..
            console.log('======zonelegs status update here', payload);

            //fixme -> this one should be on the backend
            let haveAllAccepted = true;

            const legs = (payload.legs) ? payload.legs : [];
            for (let i = 0; i < legs.length; i++) {
                //zoneLeg IS array!
                for (let j = 0; j < legs[i].zoneLeg.length; j++) {
                    const singleZoneLeg = legs[i].zoneLeg[j];
                    console.log('======singleZoneLeg status update here', singleZoneLeg);
                    if (singleZoneLeg.__hasZoneOwnership) {
                        console.warn('updating zoneleg here ',  {id: singleZoneLeg.uid, status});
                        yield dataProvider(API_VERBS.UPDATE, 'zoneLegs', {id: singleZoneLeg.uid, data:{status}});

                        if (status !== MissionV2Statuses.ACCEPTED) {
                            haveAllAccepted = false;
                        }
                    } else {
                        console.warn('====no ownership', singleZoneLeg);
                        if (!singleZoneLeg.zonePrimitive) {
                            console.warn('====skipping check for removed');
                            continue;
                        }

                        if (!singleZoneLeg.__intersectionValid) {
                            console.warn('====skipping check for invalid intersection');
                            continue;
                        }

                        if (singleZoneLeg.status !== MissionV2Statuses.ACCEPTED) {
                            haveAllAccepted = false;
                        }
                    }
                }
            }


            //if rejected by anyone, status is forced to rejected. (28-02-19)
            if (status === MissionV2Statuses.REJECTED) {
                data.status = status;
            } else if (payload.forceAccept) {
                data.status = MissionV2Statuses.ACCEPTED;
            } else if (haveAllAccepted && status === MissionV2Statuses.ACCEPTED) {
                data.status = MissionV2Statuses.ACCEPTED;
            } else {
                data.status = MissionV2Statuses.PLANNED;
            }
        } else { //operator mode,
            data.etc = {...payload.__etc};
            //removing hack for madrid
            //todo check if is passing etc still required? probably yes
            //data.etc.modified = unixTimeNow();

            data.status = status;
        }

        // if (true) {
        //     console.warn('finish me', data);
        //     yield earlyFailSafe('finish me');
        //     //wtf. not working..
        //     //yield put(setAllEditablePrimitives(editablePrimitives));
        //     return;
        // }

        //console.warn('===============missionId', missionId);
        let missionSaved;

        missionSaved = yield dataProvider(API_VERBS.UPDATE, 'missions', {id: missionId, data});

        console.warn('=================old mission updated', missionSaved);

        //overridable message? in meta? or payload?
        yield put(showNotification('resources.missions.notifications.statusChangedMsg', 'info'));
        yield call(delay_p, 700);
        //
        yield put({ type: FETCH_END });
        yield put(push(`/`)); //for now going to dashboard, redirect should be overridable, in meta??
        yield put({type: LOADING_END});

    } catch (error) {
        console.error('handleSave SAVE_MISSION_STATUS_V2_RQ error', error);
        yield put(showNotification('resources.missions.notifications.saveFailedErr', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});
    }
}

const _cleanCloneRecordForForm = (record) => {

    //atc msgs related no longer used in etc, but since clone works on old records, keeping logic
    const clean_etc = MISSION_112_ENABLED ?
        omit(
            record.etc,
            'atcStatusMsg',
            'atcStatusIssuerEmail',
            'atcMsgs',
            'noConflictsDialogUnderstood',
            'is112'
        )
        :
        omit(
            record.etc,
            'atcStatusMsg',
            'atcStatusIssuerEmail',
            'atcMsgs',
            'noConflictsDialogUnderstood',
        );

    return {
        etc: clean_etc,
        name: record.name + ' (CLONED)',
        typ: record.typ,
        mcmt: record.mcmt,
        attn: record.attn
    };
};

const _cleanCloneRecordForMap = (record) => {
    const legs = record.legs;
    const cleanedLegs = [];

    legs.forEach((leg)=> {
        cleanedLegs.push(omit(leg, 'missionid', 'id', 'uid'));
    });

    return {
        legs: cleanedLegs
    }
};


function* handleClone({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'resources.missions.notifications.cloning'
    });

    try {
        console.log("Clone saga - input", type, payload);
        let missionId = payload.uid;

        const dataProvider = DataProvider.getDataProvider();
        const fetchedMission = yield dataProvider(API_VERBS.GET_ONE, 'missions', { id: missionId});
        console.log("Clone saga - fetched mission for attachments", fetchedMission);

        const cleanedFormRecord = _cleanCloneRecordForForm(fetchedMission.data);

        const formRecord = {
            ...cleanedFormRecord,
        };
        const mapRecord = _cleanCloneRecordForMap(fetchedMission.data);

        console.log("Clone saga - output", formRecord, mapRecord);

        yield put(push({
            pathname: `/missions/create`,
            state: {
                record: formRecord,
                legs: mapRecord
            }
        }));
        yield put({type: FETCH_END });
        yield put({type: LOADING_END});

    } catch (error) {
        console.error('handleClone error', error);
        yield put(showNotification('resources.missions.notifications.cloneFailedErr', 'warning'));
        yield put({ type: FETCH_END });
        yield put({type: LOADING_END});
    }
}


function* fetchHolidays({ type, country, year }) {

    try {
        const startYear = year;
        const nowYear = moment().year();

        const yearsToStart = startYear - nowYear;

        const userState = yield select(
            (state) => state.mySelf.userState
        );

        for(let i = 0; i < yearsToStart + 1; i++) {
            if(!Holidays(country).hasYear(nowYear+i)) {
                yield Holidays(country).fetchAndSupplyHolidays(nowYear+i);
            }
        }

        if(!userState.canCalcHolidays) {
            yield put({
                type: MY_STORE_SETSELF_USERSTATE,
                newUserState: {
                    canCalcHolidays:true,
                }
            });
        }

    } catch (error) {
        console.error('Holidays fetch error', error);
        yield put(showNotification('resources.missions.notifications.holidaysCheckError', 'warning'));

        yield put({
            type: MY_STORE_SETSELF_USERSTATE,
            newUserState: {
                canCalcHolidays: false,
            }
        });
    }
}

const _isTermNotRetained = (missionStartDay, missionType) => {

    const now = moment();
    console.log("Holidays days", Holidays('pl').calcBusinessDays(now, missionStartDay));
    switch (missionType) {
        case MissionType.VLOS:
            return Holidays('pl').calcBusinessDays(now, missionStartDay) <= VLOS_OFFICIAL_CONSIDER_TIME;
        case MissionType.BVLOS:
            return moment(missionStartDay).isSameOrBefore(moment().endOf('day').add(BVLOS_OFFICIAL_CONSIDER_TIME-1, 'd'));
        default:
            return false;
    }
};

const _isVlosNoConflictsMission = (record, missionType, zoneTypesV2, zonesV2) => {
    const missionData = deserializeMissionData({
        missionRecord: {
            ...record,
            legs: record.__legs
        },
        zoneTypesV2,
        zonesV2
    });
    const conflicts = (missionData && missionData.conflicts) ? missionData.conflicts : [];

    return conflicts.length === 0 && missionType === MissionType.VLOS;
};



export default function* () {

    yield all([
        takeEvery(SAVE_MISSION_DATA_V2_RQ, handleFullSave),
        takeEvery(SAVE_MISSION_STATUS_V2_RQ, handleStatusSave),
        takeEvery(CLONE_MISSION_V2, handleClone),
        takeEvery(CHECK_HOLIDAYS, fetchHolidays),
    ]);
}

//----------helpers

function* earlyFailSafe(msg) {
    yield put(showNotification(msg, 'warning'));
    yield put({ type: FETCH_END });
    yield put({type: LOADING_END});

    //yield put(refreshView()); //no refresh on failsafe!
}


function transformMissionPayload(payload, editablePrimitives, borderData) {
    console.warn('mission payload', payload);
    console.warn('editablePrimitives', editablePrimitives);
    const legsToCreate = [];
    const legsToUpdate = [];

    let legsSizesTooLarge = false;
    let legsWithInvalidGeometry = false;
    let legsOutsideBorder = false;

    //split record
    const {legs, ...missionRecord} = payload;

    const oldUids = [];
    const newUids = [];
    if (legs && legs.length > 0) {
        legs.forEach((leg) => {
            oldUids.push(leg.uid);
        });
    }

    for (let i = 0; i <editablePrimitives.length; i++) {
        const primitive = editablePrimitives[i];

        if (primitive.primitiveType === PRIMITIVES.MEASURE) continue;

        if (primitive.__area && primitive.__area.kmSq >= MAX_LEG_AREA_KM_SQ) {
            legsSizesTooLarge = true;
        }

        if (primitive.__kinks) {
            legsWithInvalidGeometry = true;
        }

        console.log ('primitive', primitive);

        if (borderData) {
            for (let i = 0; i <borderData.features.length; i++) {
                console.log('calling border check for', borderData.features[i]);
                legsOutsideBorder = (legsOutsideBorder)
                    ?legsOutsideBorder
                    :!turfWithin(turfPoly(primitive.polygon), borderData.features[i]);
                console.log(String(legsOutsideBorder))
            }
        }

        const serializedLeg = serializeLegData(primitive);

        //forcing start stop from mission to support capacity check algo
        // but one day should be editable per leg,
        // (with check on mission start/stop edit update!)

        serializedLeg.start = missionRecord.start;
        serializedLeg.stop = missionRecord.stop;


        if (!serializedLeg.uid) {
            legsToCreate.push(serializedLeg);
        }
        else {
            newUids.push(serializedLeg.uid);
            if (serializedLeg.__dirty) {
                legsToUpdate.push(serializedLeg);
            }
        }
    }

    const legsUidsToDelete = oldUids.filter(e => !newUids.includes(e));
    console.warn('compare here', oldUids, newUids, legsUidsToDelete);

    return {missionRecord, legs, legsToCreate, legsToUpdate, legsUidsToDelete, legsSizesTooLarge, legsWithInvalidGeometry, legsOutsideBorder}

}

function _removeEmpties(arr) {
    const complete = [];
    arr.forEach(
        el => {
            if (!isEmpty(el)) {
                complete.push(el);
            }
        });
    return complete;
}

function _cleanMissionRecordForSave(record) {

    const mutatedRecord = cloneDeep(record);

    let drones;
    let observers;

    if(has(mutatedRecord, 'etc.drones')) {
        drones = _removeEmpties(mutatedRecord.etc.drones);
    }

    if(has(mutatedRecord, 'etc.observers')) {
        observers = _removeEmpties(mutatedRecord.etc.observers);
    }

    if(has(mutatedRecord, 'etc.noConflictsDialogUnderstood')) {
        delete mutatedRecord.etc.noConflictsDialogUnderstood;
    }

    return ({
        ...mutatedRecord,
        etc: {
            ...mutatedRecord.etc,
            drones,
            observers
        }
    });
}

