
//import MruArray from 'mru'; <- export error, not used anyway now.
import {isPosEqual} from '../../gl_map/glSetups/oldCommon/commonTurf'
import axios from 'axios';
import {urls} from '../../Configs';


// caching srtm requests
// "required for rt-map old checkins refresh (height is passed only in AML m)"
// -> not true, there is still browser cache, and params will be the same (to test if works)
//but WILL be needed for gps enabled operator app !
let cache = [] //new MruArray(500); fixme!

export function cachePosition({latLng, gnd}) {
    cache.push({latLng, gnd})
}

export function getCachedGnd({latLng}) {
    console.error('not finished! todo');
    //TODO find in cache with reasonable error
    const f = isPosEqual;
}

//one day should be own saga...
export function srtmHeightAsPromise(appName, lngLat) {

    console.warn('======srtm ', appName, lngLat, urls.getSrtmUrl);

    const url = `${urls.getSrtmUrl[appName]}&lat=${lngLat[1]}&lon=${lngLat[0]}`;
    return axios(url);
}


