import {myUrls} from "../../MyConfig";

import axios from "axios";
//import StoreProvider from "../dr_ra2/MyStoreProvider";
//import { showNotification } from 'react-admin';
import {groupBy} from "lodash";
import { isoString2UnixTime } from "../../modules2lib/common/src/TimeUtils";

let cachedWebcatActivations;

function decorateWebcatActivations(data) {
    //console.log('===webcat act data',  data);

    //yikes! a LOT of things there
    for (let i = 0; i < data.length; i++ ) {
        //console.log(data[i].designator);
        //console.log(JSON.stringify(data[i].etc));
        //console.log(data[i]);

        data[i].__startT = isoString2UnixTime(data[i].start);
        data[i].__stopT = isoString2UnixTime(data[i].stop);
    }


    let ret = groupBy(data, 'designator');
    //console.log('===webcat act ret',  ret);
    return ret;
}

export function requestWebcatActivation() {
    // min_time = '00:00';
    // max_time = '23:59'; //?or '24:00';  (+1 day)

    // reenable caching later if needed
    // if (cachedWebcatActivations) {
    //     //logger.error('----------------cached call for cachedWebcatActivations-------------');
    //     deffered.resolve(cachedWebcatActivations);
    // }

    const filter = {
        order: "start ASC"
    };

    const url = myUrls.getWebcatActivactionsUrl + "/?filter=" + JSON.stringify(filter);
    //console.log("getWebcatActivactionsUrl", url);

    const promise = axios(encodeURI(url), {
        method: "get"
    });


    return promise
        .then(response => {
            console.log('requestWebcatActivation axios response');

            cachedWebcatActivations = decorateWebcatActivations(response.data);

            //cachedWebcatActivations = response.data;

            return Promise.resolve(cachedWebcatActivations);
        })
        .catch(function (error) {
            //const store = StoreProvider.getStore();
            //store.dispatch(showNotification('requestWebcatActivation error', 'warning'));

            //console.dir(promise);
            console.warn('requestWebcatActivation axios error', error);

            //fixme with notification here? or in zone saga?

            return Promise.reject('axios error')
        });
}
