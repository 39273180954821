import {INFO_SEEN_ADD, INFO_SEEN_REMOVE, INFO_SEEN_REMOVE_ALL} from "./InfoSeenConstants";

const initialState = {
    seen: [],
};

export default (state = initialState, {hash, type}) => {

    const seen = [...state.seen];

    switch (type) {
        case INFO_SEEN_ADD:
            seen.push(hash);
            return {
                ...state,
                seen,
            };

        case INFO_SEEN_REMOVE:
            const idx = seen.indexOf(hash);
            if(idx > -1) {
                seen.splice(idx,1)
            }
            return {
                ...state,
                seen
            };

        case INFO_SEEN_REMOVE_ALL:
            return {
                ...state,
                seen: []
            };

        default:
            return state;
    }
}

