//->explicit, but seems a way to go.. at least for now
// method for get_list is get_many (uses same lb endpoint after all)

//new note -> nested include fields in scope WORKED in mobile, but in array format (not object!)

import {RESOURCES} from "./MyRestConfig";
//import moment from "moment";
import {MissionV2Statuses} from "../../LogicV1Redux/constants/MissionsV2Enums";
import {CaaApplicationStatuses} from "../../LogicV1Redux/constants/CaaApplicationEnums";
import Configs, {FEATURES_V1_2_ENUMS} from "../../Configs";
import {API_VERBS} from "../MyApiVerbs";

const ZONE_DELETE_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ZONE_DELETE);
const UNIT_CREATE_NEW_ENDPOINT = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.UNIT_CREATE_NEW_ENDPOINT);

export const superAdminTweaks = {

    //special
    // '!AUTH!': {
    //     [API_VERBS.LOGIN]:{url:'/Govs/login', method:'POST', anonAccess:true},
    //
    //     [API_VERBS.LOADSELF]:{
    //         url:'/Govs/{userId}',
    //         method:'GET',
    //         //30.05.2019 disabling self units, as there is added geometry now.. HEAVY for FISes
    //         //customFilter:{"include": { "relation": "units"}},
    //     },
    //     // [API_VERBS.SAVESELF]:{
    //     //     url:'/Govs/{userId}',
    //     //     method:'PATCH',
    //     // }
    // },

    [RESOURCES.UNITS]: {
        [API_VERBS.COUNT]:{url:'/Govs/units/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/units', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/units/{modelId}', method:'GET'},

        [API_VERBS.LB_CREATE]: UNIT_CREATE_NEW_ENDPOINT ?
            {url:'/Govs/units', method:'POST'}
            :
            {url:'/Govs/{userId}/units', method:'POST'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/units/{modelId}', method:'PUT'},
    },

    // [RESOURCES.ZONES]: {
    //     [API_VERBS.GET_LB_COUNT]:{url:'/Govs/zones/count', method:'GET'},
    //
    //     [API_VERBS.GET_LB_MANY]:{url:'/Govs/zones', method:'GET'},
    //     [API_VERBS.GET_LB_ONE]:{url:'/Govs/zones/{modelId}', method:'GET'},
    //
    //     [API_VERBS.LB_UPDATE]:{url:'/Govs/zones/{modelId}', method:'PUT'},
    //
    //     [API_VERBS.LB_CREATE]:{url:'/Govs/zones', method:'POST'},
    //
    // },

    //Super admin should see all missions
    [RESOURCES.MISSIONS]: {
        [API_VERBS.COUNT]:{url:'/Govs/missions/count', method:'GET'},

        //[API_VERBS.GET_LB_MANY':{url:'/Govs/missions', method:'GET'},

        [API_VERBS.LB_GET_MANY]: {
            url: '/Govs/missions',
            method: 'GET',
            customFilter: {
                "include": {"relation": "legs", "scope": {"include": ["zones", "zoneLeg"]}},
                "fields":{"attn":false}

            },
        },
        [API_VERBS.LB_GET_ONE]:{
            url:'/Govs/missions',
            method:'GET',
            customFilter: {
                "where": {"uid": "{modelId}"},
                "include": {"relation": "legs",
                    "scope": {"include": [
                            "zones",
                            "zoneLeg"
                        ]}
                }
            },
        },

        [API_VERBS.LB_UPDATE]:{url:'/Govs/missions/{modelId}', method:'PUT'},

    },
    [RESOURCES.DRONES]: {
        [API_VERBS.COUNT]:{url:'/Govs/drones/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]: {
            url: '/Govs/drones',
            method: 'GET',
            customFilter: {
                "where": {"and": [{"status": { "neq": null }}]},
                "fields":{"attn":false}
            },
        },

        [API_VERBS.LB_GET_ONE]:{url:'/Govs/drones/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/drones/{modelId}', method:'PUT'},

    },
    [RESOURCES.CERTS]: {
        [API_VERBS.COUNT]:{url:'/Govs/certs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]: {
            url: '/Govs/certs',
            method: 'GET',
            customFilter: {
                "where": {"and": [{"status": { "neq": null }}]},
                "fields":{"attn":false}
            },
        },

        [API_VERBS.LB_GET_ONE]:{url:'/Govs/certs/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/certs/{modelId}', method:'PUT'},

    },

    [RESOURCES.NOTAMS]: {
        [API_VERBS.COUNT]:{url:'/Notams/count', method:'GET'},
        [API_VERBS.LB_GET_MANY]:{url:'/Notams', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Notams/{modelId}', method:'GET'},

    },

    // [RESOURCES.NOTAM_ACTIVATIONS]: {
    //     [API_VERBS.GET_LB_COUNT]:{url:'/Notams/{modelId}/notamActivations/count', method:'GET'},
    //     [API_VERBS.GET_LB_MANY]:{url:'/Notams/{modelId}/notamActivations', method:'GET'},
    // },

};


//console.warn(moment().clone().toISOString());
//fixme to dynamic calls (issue #259)
//const rtMapMissionStopFilterFrom = moment().clone().toISOString();
//const rtMapMissionStartFilterUntil = moment().clone().add('12','h').toISOString();

//console.warn("rtMapMissionStopFilterFrom", rtMapMissionStopFilterFrom );
//console.warn("rtMapMissionStartFilterUntil", rtMapMissionStartFilterUntil );


//default for gov!
export const govUrls = {
    //special
    '!AUTH!': {
        [API_VERBS.LOGIN]:{url:'/Govs/login', method:'POST', anonAccess:true},

        [API_VERBS.RESETPASSRQ]:{url:'/Govs/reset', method:'POST', anonAccess:true},
        [API_VERBS.NEWPASSRQ]:{url:'/Govs/reset-password', method:'POST', anonAccess:true},

        [API_VERBS.LOADSELF]:{
            url:'/Govs/{userId}',
            method:'GET',
            customFilter:{"include": { "relation": "units"}},
        },
        [API_VERBS.SAVESELF]:{
            url:'/Govs/{userId}',
            method:'PATCH',
        }
    },

    'ADMIN': {
        [API_VERBS.RESEND_VERIFICATION_EMAIL_OPERATOR]: {url: '/Govs/operators/emailVerify/{modelId}', method: 'PUT'},
        [API_VERBS.RESEND_VERIFICATION_EMAIL_GOV]: {url: '/Govs/govs/emailVerify/{modelId}', method: 'PUT'},
        [API_VERBS.MANAGE_BAN_OPERATOR]: {url: '/Govs/operatorsBan/{modelId}', method: 'PUT'},
        [API_VERBS.GOV_ON_OPERATOR_UPDATE]: {url:'/Govs/operators/{modelId}', method:'PUT'},
    },

    [RESOURCES.GOVS]: {
        [API_VERBS.COUNT]:{url:'/Govs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/{modelId}', method:'PATCH'},

        [API_VERBS.LB_CREATE]:{url:'/Govs', method:'POST'},

    },
    [RESOURCES.UNITS]: {
        [API_VERBS.COUNT]:{url:'/Govs/{userId}/units/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/{userId}/units', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/{userId}/units/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/{userId}/units/{modelId}', method:'PUT'},
        [API_VERBS.LB_CREATE]:{}, ///hmm

    },

    [RESOURCES.UNITS_ALL]: {
        [API_VERBS.COUNT]:{url:'/Govs/units/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/units', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/units/{modelId}', method:'GET'},
        [API_VERBS.LB_CREATE]:{}, //hmm
    },

    [RESOURCES.UNIT_GOVS]: {
        [API_VERBS.COUNT]:{url:'/Govs/unitGovs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/unitGovs', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/unitGovs/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/unitGovs/{modelId}', method:'PUT'},

        [API_VERBS.LB_CREATE]:{url:'/Govs/unitGovs', method:'POST'},
        [API_VERBS.LB_GET_MANY_REFERENCE]:{url:'/Govs/unitGovs', method:'GET'},
    },

    [RESOURCES.LOGS]: {
        [API_VERBS.COUNT]:{url:'/Govs/logs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/logs', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/logs/{modelId}', method:'GET'},

    },
    [RESOURCES.OPERATORS]: {
        [API_VERBS.COUNT]:{url:'/Govs/operators/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/operators', method:'GET'},
        //was
        //[API_VERBS.GET_LB_ONE':{url:'/Govs/operators/{modelId}', method:'GET'},

        [API_VERBS.LB_GET_ONE]:{
            url:'/Govs/operators',
            method:'GET',
            customFilter: {
                "where": {"uid": "{modelId}"},

                "include": [
                    {
                        "relation": "drones",
                        "scope":  {"fields":["name", "uid", "registry", "status", "active"]} //setting attn:false doesn;t work, so...
                    },
                ]
            },
        },

        //[API_VERBS.LB_UPDATE]:{url:'/Govs/operators/{modelId}', method:'PATCH'}, NO UPDATES FOR OPERATORS AS NOW (24-05-18)

    },
    [RESOURCES.DRONES]: {
        [API_VERBS.COUNT]:{
            url:'/Govs/drones/count', 
            method:'GET',
            customFilter:{
                where:{"and":[{"status":{"neq":null}},{"status":{"neq":"created"}}, {"active":true}]}
            }
            },

        [API_VERBS.LB_GET_MANY]: {
            url: '/Govs/drones',
            method: 'GET',
            customFilter: {
                "where": {"and": [{"status": { "neq": null }}, {"status": { "neq": CaaApplicationStatuses.CREATED }}, {"active": true}]},
                "fields":{"attn":false}
            },
        },

        [API_VERBS.LB_GET_ONE]:{url:'/Govs/drones/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/drones/{modelId}', method:'PUT'},

    },
    [RESOURCES.CERTS]: {
        [API_VERBS.COUNT]:{
            url:'/Govs/certs/count', 
            method:'GET',
            customFilter:{
                where:{"and":[{"status":{"neq":null}},{"status":{"neq":"created"}}, {"active":true}]}
            }},

        [API_VERBS.LB_GET_MANY]: {
            url: '/Govs/certs',
            method: 'GET',
            customFilter: {
                "where": {"and": [{"status": { "neq": null }}, {"status": { "neq": CaaApplicationStatuses.CREATED }}, {"active": true}]},
                "fields":{"attn":false}
            },
        },

        [API_VERBS.LB_GET_ONE]:{url:'/Govs/certs/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/certs/{modelId}', method:'PUT'},

    },
    [RESOURCES.MISSIONS]: {
        [API_VERBS.COUNT]:{
            url:'/Govs/missions/count', 
            method:'GET', 
            customFilter:{
                where:{"and":[{"status":{"neq":null}},{"status":{"neq":"created"}}]}
            }},

        //[API_VERBS.GET_LB_MANY]:{url:'/Govs/missions', method:'GET'},

        [API_VERBS.LB_GET_MANY]: {
            url: '/Govs/missions',
            method: 'GET',
            customFilter: {
                "where": {"and": [{"status": { "neq": null }}, {"status": { "neq": MissionV2Statuses.CREATED }}]},
                "include": {"relation": "legs", "scope": {"include": ["zones", "zoneLeg"]}},
                "fields":{"attn":false}

            },
        },

        //IMPORTANT: customization to use loopback capabilities..
        //and since there is no filter for GET /Govs/missions/{modelId}..
        //i am using full custom filter

        //was
        //[API_VERBS.GET_LB_ONE]:{url:'/Govs/missions/{modelId}', method:'GET'},

        //is
        [API_VERBS.LB_GET_ONE]:{
            url:'/Govs/missions',
            method:'GET',
            customFilter: {
                "where": {"uid": "{modelId}"},
                "include": {"relation": "legs",
                    "scope": {"include": [
                        "zones",
                        "zoneLeg"
                    ]}
                }
            },
        },

        [API_VERBS.LB_UPDATE]:{url:'/Govs/missions/{modelId}', method:'PUT'},

    },

    [RESOURCES.ZONE_LEGS]: {
        [API_VERBS.COUNT]:{url:'/Govs/zoneLegs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/zoneLegs', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/zoneLegs/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/zoneLegs/{modelId}', method:'PUT'},

    },

    //required by map at least now...
    //todo -> discuss what's difference between gov/op/public gets
    [RESOURCES.ZONES_ALL]: {
        [API_VERBS.COUNT]:{url:'/Govs/zones/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/zones',
            method:'GET',
//for debug only
//             customFilter: {
//                 "where": {"type": "CTR"},
//             },
        },
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/zones/{modelId}', method:'GET'},

    },

    //huh, for for get many there should be dynamic filter for owned units injected... somehow...
    //yup -> 25-02-19. if we are going to have working per zone conflicts view, we need special dynamic controller
    // (with unlimited scroll view? -> anyway not loaded on zone request), but with count?
    [RESOURCES.ZONES]: {
        [API_VERBS.COUNT]:{url:'/Govs/zones/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/zones',
            method:'GET',
            customFilter:{"include": { "relation": "zoneLeg"}} //todo review: list with zonelegs? some old requirement?
        },


        //[API_VERBS.GET_LB_ONE]:{url:'/Govs/zones/{modelId}', method:'GET'},

        //hoho, added on request...,  (19-07-18)
        // anyway should be sql probably (no secondary filters in lb)
        // (25-02-19). tons of missions makes this approach useless
        // but disabling it today is bit too risky..
        // (07-06-19) disabling heavy load, custom saga too add
        [API_VERBS.LB_GET_ONE]: {
            url: '/Govs/zones',
            method: 'GET',
            customFilter:{
                "where":{"uid":"{modelId}"},
                // "include": [
                //     // {
                //     //     "relation": "legs",
                //     //     "scope": {
                //     //         "include":[
                //     //             {
                //     //                 "relation": "mission",
                //     //                 "fields":{"attn":false},
                //     //                 //with operator. why? -> probably for conflict resolver list..
                //     //                 // to discuss, disabling now
                //     //                 //"scope": {"include": "operator"}
                //     //             },
                //     //         ]
                //     //     }
                //     // },
                //     {"relation": "zoneLeg"} //required for zoneleg status
                // ]
            },
        },

        [API_VERBS.LB_UPDATE]:{url:'/Govs/zones/{modelId}', method:'PUT'},

        [API_VERBS.LB_CREATE]:{url:'/Govs/zones', method:'POST'},

    },

    ...(ZONE_DELETE_ENABLED &&
        {
            [RESOURCES.ZONE_ARCHIVE]: {
                // [API_VERBS.GET_LB_COUNT]:{url:'/ZoneTypes/count', method:'GET'},
                // [API_VERBS.GET_LB_MANY]:{url:'/ZoneTypes', method:'GET'},
                // [API_VERBS.GET_LB_ONE]:{url:'/ZoneTypes/{modelId}', method:'GET'},
                // [API_VERBS.LB_UPDATE]:{url:'/ZoneTypes/{modelId}', method:'PATCH'},
                [API_VERBS.LB_CREATE]:{url:'/Govs/{referenceId}/zone/archive', method:'POST'},
            }
        }
    ),


    [RESOURCES.ZONE_TYPES]: {
        [API_VERBS.COUNT]:{url:'/ZoneTypes/count', method:'GET'},
        [API_VERBS.LB_GET_MANY]:{url:'/ZoneTypes', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/ZoneTypes/{modelId}', method:'GET'},
        [API_VERBS.LB_UPDATE]:{url:'/ZoneTypes/{modelId}', method:'PATCH'},
        [API_VERBS.LB_CREATE]:{url:'/ZoneTypes', method:'POST'},
    },

    [RESOURCES.POIS]: {
        [API_VERBS.COUNT]:{url:'/Govs/pois/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/pois', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/pois/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Govs/pois/{modelId}', method:'PUT'},

        [API_VERBS.LB_CREATE]:{url:'/Govs/pois', method:'POST'},

        // [API_VERBS.GET_LB_COUNT]:{url:'/Pois/count', method:'GET'},
        //
        // [API_VERBS.GET_LB_MANY]:{url:'/Pois', method:'GET'},
        // [API_VERBS.GET_LB_ONE]:{url:'/Pois/{modelId}', method:'GET'},
        //
        // [API_VERBS.LB_UPDATE]:{url:'/Pois/{modelId}', method:'PUT'},
        //
        // [API_VERBS.LB_CREATE]:{url:'/Pois', method:'POST'},
    },

    [RESOURCES.FAAS]: {
        [API_VERBS.COUNT]:{url:'/Faas/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Faas', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Faas/{modelId}', method:'GET'},

    },
    [RESOURCES.NOTAMS]: {
        [API_VERBS.COUNT]:{url:'/Notams/count', method:'GET'},
        [API_VERBS.LB_GET_MANY]:{url:'/Notams', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Notams/{modelId}', method:'GET'},
        [API_VERBS.LB_CREATE]:{url:'/Govs/notam', method:'POST'}

    },
    [RESOURCES.NOTAM_ACTIVATIONS]: {
        // [API_VERBS.GET_LB_COUNT]:{url:'/Notams/{modelId}/notamActivations/count', method:'GET'},
        // [API_VERBS.GET_LB_MANY]:{url:'/Notams/{userId}/notamActivations', method:'GET'},
        [API_VERBS.LB_GET_MANY_REFERENCE]:{url:'/NotamActivations', method:'GET'},
    },
    [RESOURCES.DRONE_MODELS]: {
        [API_VERBS.COUNT]:{url:'/Dronemodels/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Dronemodels', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Dronemodels/{modelId}', method:'GET'},

        [API_VERBS.LB_UPDATE]:{url:'/Dronemodels/{modelId}', method:'PUT'},
        [API_VERBS.LB_CREATE]:{url:'/Dronemodels', method:'POST'},

    },
    [RESOURCES.CONTAINERS]: {
        [API_VERBS.COUNT]:{url:'/Containers/count', method:'GET'}, //hmm

        [API_VERBS.LB_GET_MANY]:{url:'/Containers', method:'GET'}, //hmm
        [API_VERBS.LB_GET_ONE]:{url:'/Containers/{containerId}', method:'GET'},  //hmm

        [API_VERBS.DOWNLOAD]:{url:'/Containers/{containerId}/download/{fileId}', method:'GET'},
        //[API_VERBS.UPLOAD]:{url:'/Containers/{containerId}/upload', method:'POST'}

    },

/*
    [RESOURCES.MISSIONSV2_RT_MAP]: {
        [API_VERBS.GET_LB_COUNT]:{url:'/Govs/missions/count', method:'GET'},
        //fixme should be optimized
        // especially included zones data, as at the moment (13-11-18)
        // we need to show conflict zone name,
        // but rt-map uses old zones display for safety,
        // and old zones don't have matching id, so damn..
        // forcefully linkin new zone data

        //fixme (2) dynamic time filter (#89)
        [API_VERBS.GET_LB_MANY]:{url:'/Govs/missions',
            method:'GET',
            customFilter:

                {
                    "include": [
                        {"relation": "legs", "scope": {"include": ["zones", "zoneLeg"]}},
                        {"relation": "operator"}
                    ],
                    "where": {
                        "and": [
                            {"status": "accepted"},
                            {"start": {"lt":rtMapMissionStartFilterUntil}},
                            {"stop": {"gt": rtMapMissionStopFilterFrom}}
                        ]
                    },
                    "fields": {"attn": false}
                }
        },
    },
*/
    //https://gidrone.droneradar.ovh/playground/ra2-playground/issues/65
    [RESOURCES.MISSIONSV2_DASHBOARD]: {
        [API_VERBS.COUNT]:{url:'/Govs/missions/count', method:'GET'},
        //fixme -> temporary solution, see GET_MANY_HOTFIX in MyRestClient.js
        //dashboard HAVE TO HAVE special endpoint.
        [API_VERBS.LB_GET_MANY]:{url:'/Govs/missions/customView',
            method:'GET',
            //now called as custom filters per status
            //"where": {"status": { "neq": null } },
        },
    },

    [RESOURCES.CERTS_DASHBOARD]: {
        [API_VERBS.COUNT]:{url:'/Govs/certs/count', method:'GET'},
        //fixme -> temporary solution, see GET_MANY_HOTFIX in MyRestClient.js
        //dashboard HAVE TO HAVE special endpoint.
        [API_VERBS.LB_GET_MANY]:{url:'/Govs/certs',
            method:'GET',
            customFilter: {
                "include": [
                    {"relation": "operator"}
                ],
                "fields":{"attn":false},
                //now called as custom filters per status
                //"where": {"status": { "neq": null } },
            },
        },
    },

    [RESOURCES.DRONES_DASHBOARD]: {
        [API_VERBS.COUNT]:{url:'/Govs/drones/count', method:'GET'},
        //fixme -> temporary solution, see GET_MANY_HOTFIX in MyRestClient.js
        //dashboard HAVE TO HAVE special endpoint.
        [API_VERBS.LB_GET_MANY]:{url:'/Govs/drones',
            method:'GET',
            customFilter: {
                "include": [
                    {"relation": "operator"}
                ],
                //OPTIMIZE TESTS

                //CONFLICT! etc has also meeting data!
                "fields":{"attn":false}, //"etc":false}, //etc contains binary data now! (issue droneadmin#131), fields should be whitelisted btw
                //now called as custom filters per status
                //"where": {"status": { "neq": null } },
            },
        },
    },


    [RESOURCES.MY_ZONE_LEGS]: {
        [API_VERBS.COUNT]:{url:'/Govs/zoneLegs/count', method:'GET'}, //fck

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/{userId}/zoneLegs', method:'GET'},

        [API_VERBS.LB_GET_ONE]:{url:'/Govs/zoneLegs/{modelId}', method:'GET'},

    },

    [RESOURCES.EVENT_OPERATORS]: {
        [API_VERBS.COUNT]:{url:'/Govs/eventOperators/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/eventOperators', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/eventOperators/{modelId}', method:'GET'},

    },
    [RESOURCES.EVENT_GOVS]: {
        [API_VERBS.COUNT]:{url:'/Govs/eventGovs/count', method:'GET'},

        [API_VERBS.LB_GET_MANY]:{url:'/Govs/eventGovs', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/Govs/eventGovs/{modelId}', method:'GET'},
    },

    [RESOURCES.ACM_RULES]: {
        [API_VERBS.COUNT]:{url:'/AcmRules/count', method:'GET'},
        [API_VERBS.LB_GET_MANY]:{url:'/AcmRules', method:'GET'},
        [API_VERBS.LB_GET_ONE]:{url:'/AcmRules/{modelId}', method:'GET'},
        [API_VERBS.LB_UPDATE]:{url:'/AcmRules/{modelId}', method:'PATCH'},
        [API_VERBS.LB_CREATE]:{url:'/AcmRules', method:'POST'},
        [API_VERBS.LB_DELETE]:{url:'/AcmRules/{modelId}', method:'DELETE'},
    }
};
