import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import compose from 'recompose/compose';
import withStyles from "@material-ui/core-v3/styles/withStyles";

const styles = theme => ({
    _menuItem: {
        backgroundColor: "navyblue",
        '&:hover': {
            backgroundColor: "navyblue",
            '& $primary, & $icon': {
                color: "white",
            },
        },

    },
    menuItem: {},
    primary: {},
    icon: {},
    whitish: {color: "#ffffff"},
    icoTxt: {
        position: "absolute",
        fontSize: "0.6rem",
        top: "0.4rem",
        right: "0.3rem",
    },
    root: {},
    active: {},
});

class ExpandingMenuItem extends React.Component {

    state = {
        anchorEl: null,
    };

    handleOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {anchorEl} = this.state;
        const {
            classes,
            changeLocale,
            locale,
            className,
            primaryText,
            leftIcon,
            staticContext,
            sidebarOpen,
            children,
            items,
            pathname,
            ...props
        } = this.props;
        const open = !!(anchorEl);

        return (
            <div>
                <MenuItem
                    className={classnames(classes.root, className, {
                        [classes.active]: items.some(item => pathname.startsWith(item.props.to))
                    })}
                    {...props}
                    onClick={this.handleOpen}
                >
                    {
                        leftIcon && (
                            <span className={classes.icon}>
                                  {React.cloneElement(leftIcon, {titleAccess: primaryText})}
                             </span>
                        )}
                    {primaryText}
                </MenuItem>
                <Menu
                    id={`menu-${primaryText}`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                >
                    {items}
                </Menu>
            </div>
        );
    }
}

const enhance = compose(
    withStyles(styles),
);

export default enhance(ExpandingMenuItem);