
import {TIMER_START_RQ, TIMER_STOP_RQ, TIMER_TICK_RQ} from "../constants/TickConstants";
import { all, put,  takeEvery, select, call, take, fork, cancel, cancelled, takeLatest } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import {MAP_TICK_ENABLED} from "../constants/MapUxConfigConstants";

import {unixTimeNow} from '../oldLogic/TimeConverters'

// creates a basic channel

//main timer HAVE to be one per sec in rt-map
//see ON_BLOCK_ETA_THRESHOLD
function timerChannel({interval = 1000}) {
    console.log('timer TIMER_ start', interval);
    let ticks = 0;
    return eventChannel(emit => {
        console.log('eventChannel TIMER_ running');
        emit(ticks);
        const intervalRef = setInterval(() => {
            ticks++;
            //console.log('TIMER_ emitting', ticks);

            emit(ticks)
        }, interval);
        return () => {
            console.log('unsubscribing, TIMER_');
            clearInterval(intervalRef)
        }
    })
}

function* channelSaga(payload) {
    console.log('saga TIMER_START_RQ here', payload);
    console.log('TIMER_ saga running');
    const channel = yield call(timerChannel, payload = {});
    try {
        while (true) {
            // take(END) will cause the saga to terminate by jumping to the finally block
            let ticks = yield take(channel);
            //console.log(`TIMER_ channel: ${ticks}`);
            yield put({ type: TIMER_TICK_RQ, payload: {unixTime : unixTimeNow(), ticks} });
        }
    } finally {
        if (yield cancelled()) {
            console.log('TIMER_ channel cancelled')
        }
        channel.close();
        console.log('TIMER_ channel terminated')
    }
}

//btw https://stackoverflow.com/questions/44926758/how-to-close-an-eventchannel-when-a-certain-action-comes-along

function* handleStart({ type, payload }) {
    let timerTask;

    //while ( yield take(TIMER_START_RQ) ) {
        // starts the task in the background
    console.log('starting TIMER_ channel ', { type, payload });

    timerTask = yield fork(channelSaga, payload);
    console.log('TIMER_ channel started');
        // wait for the user stop action
        yield take(TIMER_STOP_RQ);
    console.log('TIMER_ channel cancelling');

    // user clicked stop. cancel the background task
        // this will cause the forked bgSync task to jump into its finally block
        yield cancel(timerTask);
    //}
}

function* onTick() {
    console.log('TIMER_, ahndle tick');
    yield;
}

function* onStop() {
    console.log('TIMER_, test stop');
    yield;
}

// how to store previous interval setting?
// have some ideas, (appconfig, custom payload etc)
// but in future we may need a few different timers..
// skipping for now, as this is dev only today..

function* onToggleUI({ type, payload }) {
    console.warn('TIMER_, fixme per app interval from toggle ui', type, payload);
    yield put({ type: (payload)?TIMER_START_RQ:TIMER_STOP_RQ, payload: {} })
}

export default function* () {
    // console.log("sage TIMER_")
    yield all([

        takeLatest(TIMER_START_RQ, handleStart),
        //takeEvery(TIMER_STOP_RQ, onStop), -> handleStart sets take
        //takeEvery(TIMER_TICK_RQ, onTick), -> tickReducer (store)
        takeEvery(MAP_TICK_ENABLED, onToggleUI)
    ]);

}
