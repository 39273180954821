import { all, put, takeEvery, select, call } from "redux-saga/effects";
import {
    FETCH_ALL_ALERTS_V2_RQ,
    STORE_ALL_ALERTS_V2,
    CANCEL_ALERT_V2_RQ,
    POST_ALERT_V2_RQ,
    SET_ATC_OWNED_ALERT_V2
} from "../constants/AlertsV2Constants";
import { TIMER_TICK_RQ } from "../constants/TickConstants";

import { showNotification } from "react-admin";
import axios from "axios";
import { cleanOutdatedAlerts, preProcessAlertsForRtMap } from "../oldLogic/AlertsV2List";

import { FETCH_START, FETCH_END } from "react-admin";
import {
    MAP_ALERTS_V2_ENABLE_RQ,
    MAP_ALERTS_V2_ENABLED,
    MAP_UI_ALERTS_V2_SHOW
} from "../constants/MapUxConfigConstants";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../constants/PubSubSignals";

import { APP_MODES, urls } from "../../Configs";
import {getAuthHeaderV2} from "../oldLogic/AuthConverters";
import {transformCreateAlertForm} from "../oldLogic/AlertsV2Sending";
import {isoTimeNow} from "../oldLogic/TimeConverters";
import {POST_OWNED_CHECKIN_V2} from "../constants/CheckinsV2Constants";
import {FlightStatusesV2, CheckinPostRqEnums} from "../constants/CheckinsV2Enums";
import {ALERT_STATUSES, EMIT_SIGNALS} from "../constants/AlertsV2Enums";

const getMyAppExtras = state => state.myAppExtras;
const getMyAuth = state => state.myAuth;

const getAlertsStore = state => state.alertsV2;
const getMapUxConfig = state => state.mapUxConfig;
const getMySelf = state => state.mySelf;
const getCheckinsV2 = state => state.checkinsV2;
const getZonesV2 = state => state.zonesV2;
const getZoneTypesV2 = state => state.zoneTypesV2;

//can't use react admin data provider (mobile mode)

export function* handleFetch({ type, payload }) {
    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiShowMissionsV2) {
    //     console.error('alerts fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);
    const mySelf = yield select(getMySelf);

    console.log("payload", payload);
    console.log("appExtras", appExtras);

    //fixme with custom override of RA!
    //yield put({ type: FETCH_START });

    try {

        const filter = { where: { or: [{ stop: { gt: isoTimeNow() } }, { stop: null }] } };

        let url = urls.alertsV2Urls[appExtras.app.name];
        url += '/?filter=' + JSON.stringify(filter);

        const promise = axios(encodeURI(url), {
            method: 'get',
        });


        const response = yield call(() => promise);
        const checkinsV2 = yield select(getCheckinsV2);

        const alerts = preProcessAlertsForRtMap(
            response.data,
            appExtras,
            mySelf,
        );
        console.log("fetch alerts response, processed", alerts);

        //fixme show preferences?
        yield put({ type: MAP_UI_ALERTS_V2_SHOW, payload: true });

        yield put({ type: STORE_ALL_ALERTS_V2, payload: alerts });
        yield put({ type: MAP_ALERTS_V2_ENABLED, payload: true });

        //fixme with custom override of RA!
        //yield put({ type: FETCH_END });;
    } catch (error) {
        console.error("Alerts fetch error", error);
        //fixme with custom override of RA!

        yield put(showNotification("Alerts fetch error", "warning"));
        //yield put({ type: FETCH_END });;
    }
}

function* handlePostAlert({ type, payload }) {
    const appExtras = yield select(getMyAppExtras);
    const myAuth = yield select(getMyAuth);
    const mySelf = yield select(getMySelf);
    try {

        const alertsUrls = urls.alertsV2Urls;

        const zoneTypesV2 = yield select(getZoneTypesV2);
        const zonesV2 = yield select(getZonesV2);

        const data = transformCreateAlertForm({payload, zonesV2, zoneTypesV2});

        data.etc.startedBy = mySelf.username; //hmmm
        data.govid_start = mySelf.uid;  //temp, backend should set it

        console.log("create alert rq here", data);

        const promise = axios(alertsUrls[appExtras.app.name], {
            method: "post",
            headers : getAuthHeaderV2(myAuth),
            data
        });

        const response = yield call(() => promise);

        console.log('response', response);
        yield put(showNotification("Alert created", "info",  {autoHideDuration: 1000}));


    } catch (error) {
        console.error("Alerts create error", error);

        yield put(showNotification("Alerts create error", "warning"));
        //yield put({ type: FETCH_END });;
    }

    PubSub.publish(PubSubSignals.CLOSE_CREATE_ALERT_DIALOG);
}

function* handleCancelAlert({ type, payload }) {
    const appExtras = yield select(getMyAppExtras);
    const myAuth = yield select(getMyAuth);

    try {

        const alertsUrls = urls.alertsV2Urls;
        const {alertData} = payload;
        const mySelf = yield select(getMySelf);

        //console.log("cancel alert rq here", alertData);

        //hmm
        const data = {
            uid : alertData.uid,
            etc : alertData.etc || {},
        };

        data.etc.emitSignal = EMIT_SIGNALS.CANCEL_AREA_ALERT //hmmm
        data.etc.stoppedBy = mySelf.username; //hmmm
        data.govid_stop = mySelf.uid;  //temp, backend should set it
        data.stop = isoTimeNow();
        data.status = ALERT_STATUSES.ENDED;
        console.log("cancel alert rq data", data);
        const promise = axios(alertsUrls[appExtras.app.name], {
            method: "patch",
            headers : getAuthHeaderV2(myAuth),
            data
        });

        const response = yield call(() => promise);

        console.log('handleCancelAlert response', response);

        yield put(showNotification("Alert cancelled", "info",  {autoHideDuration: 1000}));


    } catch (error) {
        console.error("Alerts cancel error", error);

        yield put(showNotification("Alerts cancel error", "warning"));
        //yield put({ type: FETCH_END });;
    }

}

function* handleTick({ type, payload }) {
    //console.log('handleTick in alerts (cleaning)', payload);
    const alertsStore = yield select(getAlertsStore);
    //console.log('alertsStore', alertsStore);
    const ret = cleanOutdatedAlerts(alertsStore.rawData, payload);
    //console.log('cleanedAlerts', ret);
    const { isDirty, cleanedList } = ret;
    if (isDirty) {
        yield put({
            type: STORE_ALL_ALERTS_V2,
            payload: { rawData: cleanedList }
        });
    } else yield null;
}

//fixme show preferences?
function* handleEnableToggle({ payload }) {
    //console.error('handle MAP_ALERTS_V2_ENABLE_RQ, payload:', payload);
    if (payload) {
        yield put({ type: FETCH_ALL_ALERTS_V2_RQ });
    } else {
        //just disabling
        yield put({ type: MAP_ALERTS_V2_ENABLED, payload: false });
        yield put({ type: MAP_UI_ALERTS_V2_SHOW, payload: false });
    }
}

//just force refersh with new timestamp..
//fixes dirty check in map draw
function* handleShownToggle({ type, payload }) {
    //console.log('handleTick in alerts (cleaning)', payload);
    yield put({ type: STORE_ALL_ALERTS_V2, payload: {} });
}

export default function*() {
    console.log("enabling alertsv2 sagas");
    yield all([

        //takeEvery(MAP_ALERTS_V2_ENABLE_RQ, handleEnableToggle), //needed always in rt-map!
        takeEvery(MAP_UI_ALERTS_V2_SHOW, handleShownToggle),

        takeEvery(FETCH_ALL_ALERTS_V2_RQ, handleFetch),
        takeEvery(TIMER_TICK_RQ, handleTick),
        takeEvery(POST_ALERT_V2_RQ, handlePostAlert),
        takeEvery(CANCEL_ALERT_V2_RQ, handleCancelAlert)
    ]);
}
