import React from 'react';

import {
    CardActions,
    ListButton,
} from 'react-admin';

import withStyles from "@material-ui/core/styles/withStyles";
import BackButton from "../../../../mui_test_suite/customComponents/BackButton";


const styles = {
    box: {
        display: "contents"
    },
    push: {
        marginLeft: "auto"
    }
};

class AcmRulesCreateEditActions extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {classes, basePath, data, resource, designator} = this.props;
        const to = {
            pathname: basePath,
            state: {
                designator
            }
        };

        return (
            <CardActions>
                <div className={classes.box}>
                    <BackButton basePath={to}/>
                    <div className={classes.push}/>
                    <ListButton basePath={to}/>
                </div>
            </CardActions>
        )
    }
}

export default withStyles(styles)(AcmRulesCreateEditActions);
