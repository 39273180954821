import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { FETCH_START, FETCH_END } from "react-admin";
import {
    BAN_OPERATOR, GOV_ON_OPERATOR_UPDATE, MANAGE_BAN_OPERATOR,
    RESEND_VERIFICATION_EMAIL_GOV,
    RESEND_VERIFICATION_EMAIL_OPERATOR,
    SET_PRIVILEGE_112UAVO_AND_SUAVO,
    SET_PRIVILEGE_SUAVO,
    UNBAN_OPERATOR,
    UNSET_PRIVILEGE_112UAVO,
    UNSET_PRIVILEGE_SUAVO
} from "../constants/AdminConstants";
import DataProvider from "../../dr_ra2/MyDataProvider";
import {LOADING_END, LOADING_START} from "../../dr_ra2/logic/ui/MyUiActions";
import moment from "moment";
import { refreshView as refreshViewAction } from 'ra-core';
import {OperatorPrivileges} from "../../dr_ra2/utils/operatorHelpers";


function* handleVerificationEmailResend({ type, payload }) {
    const dataProvider = DataProvider.getDataProvider();

    yield put({type: FETCH_START});
    yield put({type: LOADING_START, primaryMessage: 'myroot.message.actionExecute'});


    try {
        const isActionForGov = type === RESEND_VERIFICATION_EMAIL_GOV;

        const params = {
            id: payload.userData.uid,
            ...(isActionForGov && {data:{domainPrefix: getGovDomainPrefix()}}),
        };

        const verifyEmail = yield dataProvider(type, 'ADMIN', params);
        if(verifyEmail.data.status !== 'success') {
            yield put(showNotification(
                'myroot.admin.messages.resendEmailError',
                'warning',
                {messageArgs: {status: verifyEmail.data.status}}
                ));
        }
        else {
            yield put(showNotification('myroot.admin.messages.resendEmailSuccess', 'info'));
        }

        yield put(refreshViewAction());
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});

    } catch (e) {
        yield put(showNotification(
            'myroot.admin.messages.resendEmailError',
            'warning',
            {messageArgs: {status: e}}
            ));
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});
    }
}

export function getGovDomainPrefix() {
    let domainPrefix = window.location.host.match(/mil|lau|caa|gov/);
    return !domainPrefix ? 'gov' : domainPrefix[0];
}


function* handleUserBan({ type, payload }) {
    const dataProvider = DataProvider.getDataProvider();

    yield put({type: FETCH_START});
    yield put({type: LOADING_START, primaryMessage: 'myroot.message.actionExecute'});

    try {
        const userBan = yield dataProvider(
            MANAGE_BAN_OPERATOR,
            'ADMIN',
            {
                id: payload.userData.uid,
                data: {
                    ban: moment().add(100, 'years').utc().format()
                }
            });

        yield put(showNotification('myroot.admin.messages.banUserSuccess', 'info'));
        yield put(refreshViewAction());
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});

    } catch (e) {
        yield put(showNotification(
            'myroot.admin.messages.banUserError',
            'warning',
            {messageArgs: {status: e.message}}
        ));
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});
    }
}

function* handleUserUnban({ type, payload }) {
    const dataProvider = DataProvider.getDataProvider();

    yield put({type: FETCH_START});
    yield put({type: LOADING_START, primaryMessage: 'myroot.message.actionExecute'});

    try {
        const userBan = yield dataProvider(
            MANAGE_BAN_OPERATOR,
            'ADMIN',
            {
                id: payload.userData.uid,
                data: {
                    ban: null
                }
            });

        yield put(showNotification('myroot.admin.messages.unbanUserSuccess', 'info'));
        yield put(refreshViewAction());
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});

    } catch (e) {
        yield put(showNotification(
            'myroot.admin.messages.unbanUserError',
            'warning',
            {messageArgs: {status: e.message}}
        ));
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});
    }
}

function* handlePrivileges({ type, payload }) {

    const dataProvider = DataProvider.getDataProvider();

    yield put({type: FETCH_START});
    yield put({type: LOADING_START, primaryMessage: 'myroot.message.actionExecute'});

    try {
        const {uid, priv} =  payload.userData;
        const privileges = new OperatorPrivileges(priv);
        const {FLAG_SUAVO, FLAG_112UAVO} = OperatorPrivileges;

        switch (type) {
            case SET_PRIVILEGE_SUAVO:
                privileges.setPrivileges(FLAG_SUAVO);
                break;
            case SET_PRIVILEGE_112UAVO_AND_SUAVO:
                privileges.setPrivileges(FLAG_112UAVO | FLAG_SUAVO);
                break;
            case UNSET_PRIVILEGE_SUAVO:
                privileges.removePrivileges(FLAG_SUAVO);
                break;
            case UNSET_PRIVILEGE_112UAVO:
                privileges.removePrivileges(FLAG_112UAVO);
                break;
            default:
                break;
        }

        const privilegeSet = yield dataProvider(
            GOV_ON_OPERATOR_UPDATE,
            'ADMIN',
            {
                id: uid,
                data: {
                    priv: privileges.get()
                }
            });

        yield put(showNotification('myroot.admin.messages.priviSetSuccess', 'info'));
        yield put(refreshViewAction());
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});

    } catch (e) {
        yield put(showNotification(
            'myroot.admin.messages.priviSetError',
            'warning',
            {messageArgs: {status: e.message}}
        ));
        yield put({type: FETCH_END});
        yield put({type: LOADING_END});
    }
}

export default function* () {

    yield all([
        takeEvery(RESEND_VERIFICATION_EMAIL_OPERATOR, handleVerificationEmailResend),
        takeEvery(RESEND_VERIFICATION_EMAIL_GOV, handleVerificationEmailResend),
        takeEvery(BAN_OPERATOR, handleUserBan),
        takeEvery(UNBAN_OPERATOR, handleUserUnban),
        takeEvery([SET_PRIVILEGE_112UAVO_AND_SUAVO, SET_PRIVILEGE_SUAVO, UNSET_PRIVILEGE_112UAVO, UNSET_PRIVILEGE_SUAVO], handlePrivileges)
    ]);
}
