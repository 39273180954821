//not done.
//but will use import for logger stuff
import { ZoneTypesProcessor, ZoneActivityProcessor, ThreeLightsProcessor, InspectTypes, ModuleError } from "../";
import {Logger, LOGGERS} from '../../DebugConfig';
import {val2code as v2c} from "../threeLights/ThreeLightsConverters";

import {get} from 'lodash';
import { unixTimeNow } from "../common/src/TimeUtils";

const l = Logger.get(LOGGERS.ZONE_ACTIVITY);

//singletons maybe not tonight... FIXME
let activityProcessor = null;
let zoneTypeProcessor = null;
let threeLightsProcessor = null;

//was *usually* called independently from connect (frontend)
//but since we have master/slave zones, we have to use looped connectDynamicData
function calcActivity ({inspectData, time}) {

    try {


        const ret = activityProcessor.calculate({inspectData, time});

        if (ret.archived) {
            l.log('skipping archived %s', inspectData.zoneRecord.name);
            return null;
        }

        return ret;
    } catch (e) {
        if (e instanceof ModuleError) {
            l.warn(e)
        } else {
            l.log('rethrowing error in module');
            throw e;
        }
    }

}

//fixme passing checkin modifiers, add alerts support
export function calcThreeLightsFromInspectDatum(inspectDatum, calcParams) {
    if (threeLightsProcessor === null) {
        threeLightsProcessor = new ThreeLightsProcessor({debug: true})
    }

    console.log(inspectDatum, calcParams);
    return threeLightsProcessor.calculate(inspectDatum, calcParams);
}

//was really dirty, to review
export function connectDynamicData ({zones, webcatActivations, aixmTimeslices, notamActivations }) {

    const timeNow = unixTimeNow();

    const inspectDataList = [];

    const zonesWithMaster = [];

    const keyedMasterZones = {};

    if (activityProcessor === null) {
        //hmm not working with js-logger? to test
        //activityProcessor = new ZoneActivityProcessor({debug: true, logger:l})
        activityProcessor = new ZoneActivityProcessor({debug: true})
    }
    //l.error('connecting dynamic data, ', aixmTimeslices )

    //l.info('aixmTimeslices', aixmTimeslices);
    for (let i = 0; i < zones.length; i++) {


        let {inspectData} = zones[i];

        console.debug('inspectData in connectDynamicData', inspectData);

        const zoneMaster = get(inspectData.zoneRecord, 'etc.master', false);

        if (zoneMaster) {
            inspectData.__master = zoneMaster;
            zonesWithMaster.push(zones[i]);
            //first we need all other zones calculated
            continue;
        }

        if (aixmTimeslices && aixmTimeslices[inspectData.name]) {
            inspectData.aixmTimeslices = aixmTimeslices[inspectData.name];
        }

        if (webcatActivations && webcatActivations[inspectData.name]) {
            inspectData.webcatActivations = webcatActivations[inspectData.name];
        }

        if (notamActivations[inspectData.name]) {
            inspectData.notamActivations = notamActivations[inspectData.name];
        }

        const recalced = calcActivity({inspectData, time:timeNow});
        //l.error('connecting dynamic data, ', inspectData )

        if (recalced) {
            inspectData = {...inspectData, ...recalced};
            keyedMasterZones[inspectData.name] = inspectData;
        } else {
            inspectData.__inactive = true;
            inspectData.__archived = true;
        }

        zones[i].inspectData = inspectData;
    }

    for (let i = 0; i < zonesWithMaster.length; i++) {
        let {inspectData} = zonesWithMaster[i];
        const masterName = inspectData.__master;

        const masterData = keyedMasterZones[masterName];

        if (!masterData) {
            console.log('masterData not found, skipping,', inspectData.name, masterName);
            inspectData.__inactive = true;
            inspectData.__archived = true;
            continue;
        }

        //rest is copied from master
        inspectData.__inactive = masterData.__inactive;
        inspectData.__min = masterData.__min;
        inspectData.__max = masterData.__max;
        inspectData.webcatActivations = masterData.webcatActivations;
        inspectData.aixmTimeslices = masterData.aixmTimeslices;

        //inspectDataList.push(inspectData);
    }

    //l.error('connecting dynamic data, ', inspectDataList )

    return zones;
}


export function getZoneRules(zoneTypes, zoneType) {
    if (zoneTypeProcessor === null) {
        zoneTypeProcessor = new ZoneTypesProcessor({debug: true})
    }

    return zoneTypeProcessor.getZoneRules(zoneTypes, zoneType);
}
