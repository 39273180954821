import React from 'react';
import {
    Responsive,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    LongTextInput,
    SimpleForm,
    TextInput,
    SimpleShowLayout,
    Show,
    ShowButton,
    FormDataConsumer,
    Labeled
} from 'react-admin';



import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {isSuperAdmin} from "../../logic/MyRestConfig";
import BasicEditActions from "../MyBasicEditActions";
import BasicListActions from "../MyBasicListActions";
import BasicShowActions from "../MyBasicShowActions";
import MyTransitionDecorator from "../MyTransitionDecorator";
import {MyRecordTitle, MyResourceListTitle} from "../MyTitle";
import MyPagination from "../MyPagination";
import {ListDecorator as List} from "../ListDecorator";
import {JSONInput} from "../MyJSONInput";
import {SaveOnlyToolbar} from "../MyBasicEditToolbar";




const ZoneTypesActionButton = (props) => {
    const {mySelf, record, permissions, ...rest} = props;

    if (!permissions) return null;

    const canEdit = isSuperAdmin(permissions.roles);
    return (
        (canEdit)
            ? <EditButton record={record} {...rest} />
            : <ShowButton record={record} {...rest}/>
    )
};

const ZoneTypesListBase = (props) => (
    <MyTransitionDecorator timeout={700} classNames="menucard" unmountOnExit>
        <PureZoneTypesListBase {...props}/>
    </MyTransitionDecorator>
);

const PureZoneTypesListBase = (props) => {
    const {permissions, mySelf, myAppExtras, dispatch, ...rest } = props;
    return (
        <List {...rest}
              sort={{ field: 'name', order: 'ASC' }}
              bulkActions={false}
              actions={<BasicListActions />}
              title={<MyResourceListTitle resource={'zoneTypes'}/>}
              pagination={<MyPagination/>}

        >
            <Responsive
                small={
                    <Datagrid>
                        <TextField source="name" />
                        <ZoneTypesActionButton resource={props.resource}
                                          permissions={permissions}
                                          mySelf = {mySelf}
                                          record="record" />
                    </Datagrid>
                }
            />
        </List>
    );
};

const mapStateToProps = state => {
    return {
        mySelf : state.mySelf, //forEdit
        myAppExtras : state.myAppExtras, //forShow
        locale: state.i18n.locale,
    }
};

const enhance = compose(
    connect(
        mapStateToProps,
    ),
);

export const ZoneTypesList = enhance(ZoneTypesListBase);

export const ZoneTypesShowBase = ({permissions, myAppExtras, mySelf, dispatch, locale, ...props}) => {

    return (
        <Show {...props}
              actions={<BasicShowActions/>}
              title={<MyRecordTitle resource={'zoneTypes'}/>}
        >
            <SimpleShowLayout>

                <TextField source="name"/>
                <TextField source={`description.${locale.toLowerCase()}`}/>
                <FormDataConsumer>
                    {
                        ({formData}) =>
                            isSuperAdmin(permissions.roles) ?
                                <Labeled label={'etc'}>
                                    <textarea disabled={true} style={{width: "100%", minHeight: "100px"}} value={JSON.stringify(formData.etc)}/>
                                </Labeled>
                                :
                                null
                    }
                </FormDataConsumer>


            </SimpleShowLayout>
        </Show>
    );
};


export const ZoneTypesShow = compose(
    connect(mapStateToProps),
) (ZoneTypesShowBase);

export const ZoneTypesCreate = ({permissions, ...props}) => (
    <Create {...props}
            title={<MyRecordTitle resource={'zoneTypes'}/>}
    >
            <SimpleForm
                toolbar={
                    <SaveOnlyToolbar/>
                }
            >
                <TextInput source="name"/>
                <LongTextInput source="description.pl"/>
                <LongTextInput source="description.en"/>
                <JSONInput source='etc'/>
            </SimpleForm>
    </Create>
);

export const ZoneTypesEdit = ({permissions, ...props}) => {

    return (
        <Edit {...props}
              undoable={false}
              actions={<BasicEditActions />}
              title={<MyRecordTitle resource={'zoneTypes'}/>}
        >
            <SimpleForm
                toolbar={
                    <SaveOnlyToolbar/>
                }
            >
                <TextInput source="name"/>
                <LongTextInput source="description.pl"/>
                <LongTextInput source="description.en"/>
                <JSONInput source='etc'/>
            </SimpleForm>
        </Edit>
    );
};
