import has from "lodash.has";
import {THREE_LIGHTS} from "../../../modules2lib/threeLights";
import {THREE_LIGHTS_CODES, THREE_LIGHTS_COLORS} from "../../../modules2lib/threeLights/ThreeLightsEnums";

/**
 * RPA color factory
 * @param color css color string
 * @returns RPA rpa color class instance
 */
export function FactoryRPA(color) {
    switch (color) {
        case THREE_LIGHTS_CODES[THREE_LIGHTS.BLUE]:
            return new BlueRPA();
        case THREE_LIGHTS_CODES[THREE_LIGHTS.GREEN]:
            return new GreenRPA();
        case THREE_LIGHTS_CODES[THREE_LIGHTS.YELLOW]:
            return new YellowRPA();
        case THREE_LIGHTS_CODES[THREE_LIGHTS.RED]:
            return new RedRPA();
    }
}


/**
 * Returns highest rpa color in css color string
 * @param warnings Warnings array where single warning object has a color property
 * @param maxHeight amsl ft max height from checkin or mission (CURRENTLY WHOLE MISSION, with known srtm eval issue from centroid)
 * @returns {string} css color string
 */
export function getHighestRPAcssColor(warnings, maxHeight) {
    let color = Infinity;

    //console.log('getHighestRPAcssColor warnings', warnings, maxHeight);

    for(let i = 0; i < warnings.length; i++) {
        const warning = warnings[i];
        if(has(warning, 'color')) {
            //missions/checkins OVER RPA have red color (F.Sosin request on 8.01.2020)
            if (maxHeight > warning.max) {
                //console.log('getHighestRPAcssColor: height over max zone, color:Red');
                color = FactoryRPA('RED');
                break;
            }
            const newColor = FactoryRPA(warning.color);
            if(newColor < color) color = newColor;
        }
    }
    //console.log("getHighestRPAcssColor: warning COLOR", color.toString());
    return color.toString();
}

export class RPA {}

export class RedRPA extends RPA {
    valueOf() {
        return THREE_LIGHTS.RED;
    }

    toString() {
        return THREE_LIGHTS_COLORS[THREE_LIGHTS.RED];
    }
}

export class YellowRPA extends RPA {
    valueOf() {
        return THREE_LIGHTS.YELLOW;
    }
    toString() {
        return THREE_LIGHTS_COLORS[THREE_LIGHTS.YELLOW]
    }
}

export class GreenRPA extends RPA {
    valueOf() {
        return THREE_LIGHTS.GREEN;
    }
    toString() {
        return THREE_LIGHTS_COLORS[THREE_LIGHTS.GREEN]
    }
}

export class BlueRPA extends RPA {
    valueOf() {
        return THREE_LIGHTS.BLUE;
    }
    toString() {
        return THREE_LIGHTS_COLORS[THREE_LIGHTS.BLUE]
    }
}
