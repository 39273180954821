import BackButton from "../../../../mui_test_suite/customComponents/BackButton";
import BasicListActions from "../../MyBasicListActions";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    box: {
        display: "contents"
    },
    push: {
        marginLeft: "auto"
    }
};

export default withStyles(styles)(
    ({designator, classes, className, ...props}) =>
        <BasicListActions createButtonProps={{
            to: {
                pathname: `${props.resource}/create`,
                state: {
                    designator
                }
            },
            label: `resources.${props.resource}.createException`
        }}
                          className={classes.box}
                          {...props}
        >
            <BackButton/>
            <div className={classes.push}/>
        </BasicListActions>
)