import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {STORE_CUSTOM_LAYER, CLEAR_CUSTOM_LAYER} from "../constants/CustomLayerConstants";
import {processCheckinsV1ToDeck} from "../oldLogic/CheckInsV1List";
import {unixTimeNow} from "../oldLogic/TimeConverters";

const initialState = {rawData: [], deckData: initializeLayersObject(), storeTimestamp: 0};

//preprocessed deck layer
const customLayerReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_CUSTOM_LAYER:
            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};
        case CLEAR_CUSTOM_LAYER:
            return initialState;
        default:
            return previousState;
    }
};

export default customLayerReducer;
