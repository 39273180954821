import {currentCountry, myUrls} from "../../MyConfig";

import axios from "axios";
//import StoreProvider from "../dr_ra2/MyStoreProvider";
//import { showNotification } from 'react-admin';
import {groupBy, get} from "lodash";
import moment from "moment";
import { isoString2UnixTime } from "../../modules2lib/common/src/TimeUtils";

function decorateAixmTimeslices(data) {
    console.log('===Aixm timeslice data');

    console.warn('=== fix time comparison!')

    //yikes! a LOT of things there
    for (let i = 0; i < data.length; i++ ) {

        const currTS = data[i];

        currTS.__startT = isoString2UnixTime(currTS.start);
        currTS.__stopT = isoString2UnixTime(currTS.stop);

        currTS.__hasDabSubtract = false;
        currTS.__hasDabOther = false;

        const activations = currTS.aixmActivations;
        for (let j = 0; j < activations.length; j++ ) {
            //console.log(JSON.stringify(activations[j].etc));
            //console.log(activations[j].etc.dab.note);

            activations[j].__startT = isoString2UnixTime(activations[j].start);
            activations[j].__stopT = isoString2UnixTime(activations[j].stop);

            if (activations[j].__startT === activations[j].__stopT) {
                console.warn('stop === start', activations[j]);
            } else if (activations[j].__stopT < activations[j].__startT) {
                console.warn('stop before start', activations[j]);
            } else {
                //console.log('looks.ok');
            }

            const dabNote = get (activations[j], 'etc.dab.note', false);

            if (dabNote && dabNote === 'subtract') {
                console.log(dabNote, currTS.designator);
                currTS.__hasDabSubtract = true;
            } else {
                currTS.__hasDabOther = true;
            }

        }

        if (currTS.__hasDabSubtract && currTS.__hasDabOther) {
            console.error('dabs Conflict, ', data[i].designator);
        }
    }

    let grouped = groupBy(data, 'designator');

    console.log('===Aixm timeslice ret');
    return grouped;
}

export function requestAixmTimeslices() {
    // min_time = '00:00';
    // max_time = '23:59'; //?or '24:00';  (+1 day)

    let url = myUrls.getAixmTimeslicesUrl;
    let aupZuluStartHour = currentCountry.aupZuluStartHour;

    let now = moment().clone(); //was timezoned
    let aupZuluStart = moment.utc(aupZuluStartHour, "hh:mm").clone();

    ///beetween 0 and zulu we'll be in previus day aup. aargh....
    if (now.isBefore(aupZuluStart)) {
        //Logger.get(LOGGERS.MAIN).warn('=== checkme! nightly before 6 am rollback');
        //logger.debug('rollback 2 previous day!!..');
        now.subtract(1, "d");
        aupZuluStart.subtract(1, "d");
        //aupZuluStart.subtract(1, 'd');
        //dt_from.subtract(1,'d') ;
        //dt_to.subtract(1,'d');
    }

    const rtMapAixmTimeslicesStopFilterFrom = aupZuluStart.toISOString();
    const rtMapAixmTimeslicesStartFilterUntil = aupZuluStart
        .clone()
        .add("1", "d") //parametrize me
        .toISOString();

    console.log("=== aupZuluStart", rtMapAixmTimeslicesStopFilterFrom);
    console.log("=== aupZuluEnd", rtMapAixmTimeslicesStartFilterUntil);

    const filter = {
        include: {
            relation: "aixmActivations",
            scope: {
                where: {
                    // or: [
                    //     { note: "E M P T Y  D A B" },
                    //     {
                            and: [
                                {
                                    start: {
                                        lt: rtMapAixmTimeslicesStartFilterUntil
                                    }
                                },
                                {
                                    stop: {
                                        gt: rtMapAixmTimeslicesStopFilterFrom
                                    }
                                }
                            ]
                        //}
                    // ]
                },
                order: "start ASC"
            }
        },
        fields: {
            id: true,
            designator: true,
            start: true,
            stop: true,
            min: true,
            max: true,
            etc: false
        },
        where: {
            and: [
                { start: { lt: rtMapAixmTimeslicesStartFilterUntil } },
                { stop: { gt: rtMapAixmTimeslicesStopFilterFrom } }
            ]
        },
        order: "start ASC"
    }; //24h? // same time filtering /order as in parent.
    //limit : 2

    url += "/?filter=" + JSON.stringify(filter);
    console.log("getAixmTimeslicesUrl", url);

    const promise = axios(encodeURI(url), {
        method: "get"
    });

    return promise
        .then(response => {
            console.log("requestAixmTimeslices axios response");

            return Promise.resolve(decorateAixmTimeslices(response.data));
        })
        .catch(function(error) {
            //const store = StoreProvider.getStore();
            //store.dispatch(showNotification('requestAixmTimeslices error', 'warning'));

            //console.dir(promise);
            //fixme with notification here? or in zone saga?

            console.warn(" requestAixmTimeslices axios error", error);

            return Promise.reject("axios error");
        });
}
