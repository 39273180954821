import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {STORE_ALL_RTDM_SOAP_LPR_UNITS_TICKS} from "../constants/RtdmSoapLprUnitsTicksConstants";
import {processRtdmSoapLprUnitsTicksToDeck} from "../oldLogic/RtdmSources";

const initialState = {rawData: [], deckData: initializeLayersObject(), storeTimestamp: 0};

const rtdmSoapLprUnitsTicksReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_RTDM_SOAP_LPR_UNITS_TICKS:
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            if (payload.rawData) {
                payload.deckData = processRtdmSoapLprUnitsTicksToDeck(payload);
            }
            //console.log('----- checkinsReducer reducer STORE_ALL_CHECKINS_V1:', type, payload);

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default rtdmSoapLprUnitsTicksReducer;
