import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import {
    FETCH_ALL_MISSIONS_V2_RQ,
    STORE_ALL_MISSIONS_V2,
    STORE_SELECTED_MISSION
} from '../constants/MissionsV2Constants';
import DataProvider from "../../dr_ra2/MyDataProvider";

import { showNotification } from 'react-admin';
import logger from "../oldLogic/Logger";

import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import {deserializeMissionsV2, processTimers} from "../oldLogic/MissionsListV2";
import {TIMER_TICK_RQ} from "../constants/TickConstants";
import {
    MAP_MISSIONS_V2_ENABLE_RQ,
    MAP_MISSIONS_V2_ENABLED, MAP_UI_HEIGHT_FILTER,

    MAP_UI_MISSIONS_V2_SHOW,
} from "../constants/MapUxConfigConstants";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {MAP_SHOW_INSPECT_DATA, STORE_INSPECT_DATA} from "../constants/InspectConstants";
import {InspectTypes} from "../../modules2lib/common/src/InspectTypes";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../constants/PubSubSignals";
import {primitives2Bbox} from "../../gl_map/glSetups/oldCommon/_CommonStuff";
import {currentCountry, myUrls as urls} from "../../MyConfig";
import moment from "moment";
import axios from "axios";
import {getAuthHeaderV2} from "../oldLogic/AuthConverters";
import {NEW_PANDORA_TIMEOUTS_BEHAVIOUR} from "../../Configs";


const getMyAppExtras = (state) => state.myAppExtras;
const getMapUxConfig = (state) => state.mapUxConfig;
const getMissionsV2 = (state) => state.missionsV2;
const getZoneTypesV2 = (state) => state.zoneTypesV2;
const getZonesV2 = (state) => state.zonesV2;
const getMyAuth = (state) => state.myAuth;

const getMySelf = (state) => state.mySelf;


//todo check if FETCH START/END should be added, check error handling

export function* handleFetch({ type, payload }) {
    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiShowMissionsV2) {
    //     console.error('rtMap missionv2 fetch skipped -> disabled with config');
    //     return yield null;
    // }

    try {
        const myAppExtras = yield select(getMyAppExtras);
        const mySelf = yield select(getMySelf);
        const zoneTypesV2 = yield select(getZoneTypesV2);
        const zonesV2 = yield select(getZonesV2);

        console.log('payload', payload);

        console.log('appExtras', myAppExtras);

        /* was before 7-11-2019
            // const dataProvider = DataProvider.getDataProvider();
            // const missionsAll = yield dataProvider(API_VERBS.GET_LIST, 'missionsV2RtMap', {});

            //and related note in provider setup->

            //fixme should be optimized
            // especially included zones data, as at the moment (13-11-18)
            // we need to show conflict zone name,
            // but rt-map uses old zones display for safety,
            // and old zones don't have matching id, so damn..
            // forcefully linkin new zone data

            now dynamic link fixed, scopes TODO review

            30.05.20. mission window 00:00 - 23:59
         */

        let zuluStart = moment.utc("00:00", 'hh:mm').clone();
        let zuluEnd = moment.utc("23:59", 'hh:mm').clone();

        //const zonesStartFilterUntil = zuluStart.clone().add('1','d').toISOString(); //parametrize me

        //fixme -> temporary switch for behaviour
        const rtMapMissionStopFilterFrom = (NEW_PANDORA_TIMEOUTS_BEHAVIOUR)
            ?zuluStart.toISOString()
            :moment().clone().toISOString()
        ;
        const rtMapMissionStartFilterUntil = (NEW_PANDORA_TIMEOUTS_BEHAVIOUR)
            ?zuluEnd.toISOString()
            :moment().clone().add('12','h').toISOString();

        const filter = {
            fields: {"attn": false},

            include: [
                {"relation": "legs", "scope": {"include": ["zones", "zoneLeg"]}},
                {"relation": "operator"}
            ],
            where: {
                "and": [
                    {"status": "accepted"},
                    {"start": {"lt":rtMapMissionStartFilterUntil}},
                    {"stop": {"gt": rtMapMissionStopFilterFrom}}
                ]
            },
        };

        const url = `${urls.getRtMapMissions}?filter=${JSON.stringify(filter)}`; //API_VERBS.GET_MANY?
        const myAuth = yield select(getMyAuth);

        const promise = axios(encodeURI(url), {
            method: 'get',
            headers: getAuthHeaderV2(myAuth)
        });

        const missionsAll = yield call(() => promise);

        console.log('handleFetch FETCH_ALL_MISSIONS_V2_RQ response', missionsAll);
        console.warn('optimize deserialization for rt-map!');
        const missionsProcessed = deserializeMissionsV2(missionsAll.data, {myAppExtras, mySelf, slimRequest:true, zoneTypesV2, zonesV2});

        console.log('handleFetch deserializeMissionsV2 response', missionsProcessed);

        const ret = processTimers(missionsProcessed, {unixTime : unixTimeNow() });
        //console.log('cleanedAlerts', ret);
        const {isDirty, cleanedList} = ret;

        //fixme show preferences?
        yield put({type: MAP_UI_MISSIONS_V2_SHOW, payload:true});

        yield put({type: STORE_ALL_MISSIONS_V2, payload: {
                rawData:cleanedList,
                bottomCutoff:mapUxConfig.heightFilter.bottomCutoff,
                topCutoff:mapUxConfig.heightFilter.topCutoff,
            }});


        yield put({type: MAP_MISSIONS_V2_ENABLED, payload:true});


        //yield put({ type: FETCH_END });

    } catch (error) {
        console.error('handleFetch FETCH_ALL_MISSIONS_V2_RQ error', error);
        yield put(showNotification('missionsV2 fetch error', 'warning'));
        //yield put({ type: FETCH_END });
    }
}


function* handleTick({ type, payload }) {

    const missionsStore = yield select(getMissionsV2);
    const mapUxConfig = yield select(getMapUxConfig);

    //console.log('alertsStore', alertsStore);
    const ret = processTimers(missionsStore.rawData, payload);
    //console.log('cleanedAlerts', ret);
    const {isDirty, cleanedList} = ret;
    if (isDirty) {
        yield put({type: STORE_ALL_MISSIONS_V2, payload: {
            rawData:cleanedList,
            bottomCutoff:mapUxConfig.heightFilter.bottomCutoff,
            topCutoff:mapUxConfig.heightFilter.topCutoff,
        }});
    }

}


function* handleEnableToggle({payload}) {
    //console.error('handle MAP_MISSIONS_V2_ENABLE_RQ, payload:', payload)

    if (payload) {
        yield put({type: FETCH_ALL_MISSIONS_V2_RQ});
    } else {
        //just disabling

        yield put({type: MAP_MISSIONS_V2_ENABLED, payload:false});
        yield put({type: MAP_UI_MISSIONS_V2_SHOW, payload:false});
    }
}

//just force refersh with new timestamp..
//fixes dirty check in map draw
function* handleShownToggle({ type, payload }) {

    //console.log('handleTick in alerts (cleaning)', payload);
    yield put({type: STORE_ALL_MISSIONS_V2, payload: {}});

}

//force recalc deck filters
function* handleDisplayPrefsChange({ type, payload }) {

    console.log('on handle display prefs change', type, payload);

    const missionsV2 = yield select(getMissionsV2);

    const mapUxConfig = yield select(getMapUxConfig);

    yield put({ type: STORE_ALL_MISSIONS_V2, payload:{
            rawData:missionsV2.rawData,
            //zoneTypesVis:mapUxConfig.visibilityPrefs.zoneTypes,
            bottomCutoff:mapUxConfig.heightFilter.bottomCutoff,
            topCutoff:mapUxConfig.heightFilter.topCutoff,
        }
    });
}

function* handleInspectDataShowOnMap({ type, payload }) {

    if(payload.inspectType === InspectTypes.MISSIONV2) {

        const {primitives} = payload.mission;
        const bBox = primitives2Bbox(primitives);



        PubSub.publish(PubSubSignals.MAP_VIEWPORT_SET_BBOX, {bBox});
    }

}

function* handleInspectDataChange({ type, payload }) {

    const {selectedMissionUid} = yield select(getMissionsV2);

    let newselectedMissionV2 = null;
    console.log('handleInspectDataChange i map', type, payload);

    if (payload.inspectType === InspectTypes.MISSIONV2) {

        const {uid} = payload.mission.missionRecord;
        if(uid !== selectedMissionUid) {
            newselectedMissionV2 = uid;
        }
        else return;
    }
    else if(payload.inspectType === InspectTypes.MISSIONLEGFIXME) {
        const {uid} = payload.missionInspect.missionRecord;
        if(uid !== selectedMissionUid) {
            newselectedMissionV2 = uid;
        }
        else return;
    }

    yield put({type: STORE_SELECTED_MISSION, payload: {
            selectedMissionUid: newselectedMissionV2
        }});
}


export default function* () {

    yield all([
        takeEvery(MAP_MISSIONS_V2_ENABLE_RQ, handleEnableToggle),

        takeEvery(FETCH_ALL_MISSIONS_V2_RQ, handleFetch),
        takeEvery(TIMER_TICK_RQ, handleTick),

        takeEvery(MAP_UI_MISSIONS_V2_SHOW, handleShownToggle),
        takeEvery(MAP_UI_HEIGHT_FILTER, handleDisplayPrefsChange),

        takeEvery(MAP_SHOW_INSPECT_DATA, handleInspectDataShowOnMap),
        takeEvery(STORE_INSPECT_DATA, handleInspectDataChange),

    ]);
}


