import {ListDecorator as List} from "../ListDecorator";
import {MyRecordTitle, MyResourceListTitle} from "../MyTitle";
import MyPagination from "../MyPagination";
import {
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
} from "ra-ui-materialui";
import {canDelete, canUpdate, RESOURCES} from "../../logic/MyRestConfig";
import React from "react";
import {has} from "lodash";
import {FormDataConsumer, maxLength, minLength, minValue, number, required, translate} from 'react-admin';
import {Redirect} from 'react-router-dom';
import {DateTimePickerLocale} from "../TimePickersLocaleProxy";
import {AcmRulesCreateToolbar, AcmRulesSaveToolbar} from "./AcmRulesCustomComponents/AcmRulesSaveToolbar";
import {connect} from "react-redux";
import {formValueSelector} from 'redux-form';
import compose from 'recompose/compose';
import AcmRulesCreateEditActions from "./AcmRulesCustomComponents/AcmRulesCreateEditActions";
import AcmRulesListActions from "./AcmRulesCustomComponents/AcmRulesListActions";
import MyTextField from "../MyTextField";
import {notamMoment} from "../../utils/notamDiurnal";
import Typography from "@material-ui/core/Typography";
import TranslatableTypography from "../TranslatableTypography";
import * as moment from "moment";
import {withStyles} from "@material-ui/core";
import {colorListStyles} from "./AcmRulesCustomComponents/AcmListStyles";
import {formatHourSlot} from "../../utils/acmHelpers";
import {AcmParentField} from "./AcmRulesCustomComponents/AcmParentField";
import AcmGrid, {ACM_GRID_MODE} from "./AcmRulesCustomComponents/AcmGrid";
import {AcmTermsInputTree} from "./AcmRulesCustomComponents/AcmTermsInputTree";
import * as PropTypes from "prop-types";



const createFormName = 'record-form';
const editFormName = 'record-form';
const simplyRequired = [required()];
const defaultRequired = [required(), minLength(2), maxLength(64)];
const defaultNonRequired = [minLength(2), maxLength(64)];
const rpaValidation = [required(), number(), minValue(0)];

const AcmDateTimePicker = (props) =>
    <DateTimePickerLocale source="start"
                          label={`resources.${RESOURCES.ACM_RULES}.fields.start`}
                          options={{
                              ampm: false,
                              cancelLabel: <TranslatableTypography
                                  content={'ra.action.cancel'}/>,
                              format: 'HH:mm, DD-MM-YYYY',
                              minDate: notamMoment().add(1, 'day'),

                          }}
                          momentFunc={(time) => notamMoment(time).utc()}
                          validate={simplyRequired}

                          parse={(value) => {
                              return moment.isMoment(value) ? value.startOf('hour') : value
                          }}
                          {...props}
    />;

export const AcmRulesList = withStyles(colorListStyles)(
    ({permissions, classes, ...props}) => {

    const designator = has(props, 'location.state.designator') ? props.location.state.designator : null;
    if(!designator) return <Redirect to={`/${RESOURCES.ZONES}`}/>;

    const filter = designator ? {designator} : undefined;

    return (
    <List {...props}
          bulkActions={false}
          actions={<AcmRulesListActions designator={designator}/>}
          title={<MyResourceListTitle resource={props.resource}/>}
          pagination={<MyPagination/>}
          filter={filter}
          sort={{ field: 'start', order: 'ASC' }}
          perPage={30}
    >

        <Datagrid classes={{rowCell: classes.rowCell}}>
            <TextField source="designator" />
            <FormDataConsumer label={`resources.${RESOURCES.ACM_RULES}.fields.start`}>
                {
                    ({formData, ...rest}) => (
                       <MyTextField source={'start'} {...rest} format={formatHourSlot(!formData.parentid)}/>
                    )
                }
            </FormDataConsumer>
            <TextField source="blue"/>
            <TextField source="green"/>
            <TextField source="yellow"/>
            <TextField source="red"/>
            <FormDataConsumer>
                {
                    ({formData, ...rest}) =>
                        <>
                            {formData.parentid && canDelete(props.resource, permissions) && <DeleteButton {...rest}/>}
                            {!formData.parentid && <span>DEF</span>}
                        </>
                }
            </FormDataConsumer>
            {
                canUpdate(props.resource, permissions) ? <EditButton/> : <ShowButton/>
            }

        </Datagrid>
    </List>
)});

class PureAcmRulesCreate extends React.Component {

    state = {
        terms: {}
    };

    _updateTerms = (terms) => {
        this.setState({terms});
    };

    render() {
        let {permissions, translate, start, ...props} = this.props;
        const designator = has(props, 'location.state.designator') ? props.location.state.designator : null;
        if (!designator) return <Redirect to={`/${RESOURCES.ZONES}`}/>;

        return (
            <Create {...props}
                    title={`resources.${RESOURCES.ACM_RULES}.create`}
                    actions={<AcmRulesCreateEditActions designator={designator}/>}
            >
                <SimpleForm
                    toolbar={<AcmRulesCreateToolbar designator={designator}/>}
                    defaultValue={{
                        blue: 0,
                        green: 0,
                        red: 0,
                        yellow: 0,
                        designator,
                        start: notamMoment().add(1, 'day').startOf('hour'),
                        etc: {
                            terms: this.state.terms
                        }
                    }}
                    form={createFormName}
                >
                    <AcmGrid mode={ACM_GRID_MODE.FIND_PARENT} designator={designator} start={start}
                             onRulesFetched={(rules) => this._updateTerms(has(rules[0], 'etc.terms') ? rules[0].etc.terms : {})}/>
                    <FormDataConsumer>
                        {
                            () =>
                                <Typography variant={'body2'}>
                                    {translate(`resources.${RESOURCES.ACM_RULES}.exceptionRule`, {smart_count: 1})}
                                </Typography>
                        }
                    </FormDataConsumer>
                    <AcmDateTimePicker/>
                    <TextInput source="blue" validate={rpaValidation}/>
                    <TextInput source="green" validate={rpaValidation}/>
                    <TextInput source="yellow" validate={rpaValidation}/>
                    <TextInput source="red" validate={rpaValidation}/>
                    <AcmTermsInputTree source={'etc.terms'} isOpen formName={createFormName}/>
                </SimpleForm>
            </Create>
        );
    }
}

PureAcmRulesCreate.propTypes = {
    permissions: PropTypes.any,
    translate: PropTypes.any,
    start: PropTypes.any
};

export const AcmRulesCreate = compose(
    translate,
    connect(
        (state)=>({
            start: formValueSelector(createFormName)(state, 'start'),
        }),
        null
    )
)(PureAcmRulesCreate);

const PureAcmRulesEdit = ({permissions, designator, isDefault, translate, dispatch, parentid, uid, ...props}) => {
    return (
    <Edit {...props}
          undoable={false}
          actions={<AcmRulesCreateEditActions designator={designator}/>}
          title={<MyRecordTitle resource={props.resource} recordName={'designator'}/>}
    >
        <SimpleForm
            toolbar={<AcmRulesSaveToolbar designator={designator}/>}
            form={editFormName}
        >
            {
                !isDefault && <AcmParentField source='parentid'/>
            }
            <FormDataConsumer>
                {
                    () =>
                        isDefault ?
                            <Typography
                                variant={'body2'}>{translate(`resources.${RESOURCES.ACM_RULES}.defaultRule`)}</Typography>
                            :
                            <Typography
                                variant={'body2'}>{translate(`resources.${RESOURCES.ACM_RULES}.exceptionRule`, {smart_count: 1})}</Typography>
                }
            </FormDataConsumer>

            <DisabledInput source="start" format={formatHourSlot(isDefault)}/>
            <TextInput source="blue" validate={rpaValidation}/>
            <TextInput source="green" validate={rpaValidation}/>
            <TextInput source="yellow" validate={rpaValidation}/>
            <TextInput source="red" validate={rpaValidation}/>
            <AcmTermsInputTree source="etc.terms" isOpen formName={editFormName}/>
            {
                isDefault && <AcmGrid mode={ACM_GRID_MODE.EXCEPTIONS} parentid={uid}/>
            }
        </SimpleForm>
    </Edit>
)};

export const AcmRulesEdit = compose(
    translate,
    connect(
        (state)=>({
            designator: formValueSelector(editFormName)(state, 'designator'),
            isDefault: formValueSelector(editFormName)(state, 'parentid') === null,
            parentid: formValueSelector(editFormName)(state, 'parentid'),
            uid: formValueSelector(editFormName)(state, 'uid'),
        }),
        null
    )
)(PureAcmRulesEdit);