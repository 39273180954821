import {initializeLayersObject} from "../../gl_map/glSetups/newCommon/mapStateConstructors";
import {unixTimeNow} from "../oldLogic/TimeConverters";
import {STORE_ALL_RTDM_CREOTECH_TICKS} from "../constants/RtdmCreotechTicksConstants";
import {processRtdmCreotechTicksToDeck} from "../oldLogic/RtdmSources";

const initialState = {rawData: [], deckData: initializeLayersObject(), storeTimestamp: 0};

const rtdmCreotechReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_ALL_RTDM_CREOTECH_TICKS:

            if (payload.rawData) {
                payload.deckData = processRtdmCreotechTicksToDeck(payload);
            }

            return {...previousState, ...payload, storeTimestamp:unixTimeNow()};

        default:
            return previousState;
    }
};

export default rtdmCreotechReducer;
