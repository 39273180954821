import {SaveButton, Toolbar} from "ra-ui-materialui";
import React from "react";
import {connect} from "react-redux";
import {createAcmRule} from "../../../../LogicV1Redux/actions/AcmRulesActions";
import compose from 'recompose/compose';

// tried to implement in SaveOnlyToolbar but somewhere in, basePath is lost and redirect does not work.
// that's why another very similar standalone component
export const AcmRulesSaveToolbar = ({designator, ...props}) => (
    <Toolbar {...props} >
        <SaveButton {...props} redirect={{
            pathname: props.basePath,
            state: {
                designator
            }
        }}  />
    </Toolbar>
);

const AcmRulesCreateToolbarPure = ({designator, dispatchCreateAcmRule, ...props}) => {

    const handleClick = () => {
        console.log('handle click', props);
        const {handleSubmit, resource} = props;

        return handleSubmit(
            values => {
                dispatchCreateAcmRule({
                    record: values,
                    redirect: {
                        pathname: props.basePath,
                        state: {
                            designator
                        }
                    },
                    resource
                })
            }
        )
    };

    return (
        <Toolbar {...props} >
            <SaveButton {...props}
                        handleSubmitWithRedirect={handleClick}
                        redirect={false}
            />
        </Toolbar>
    )
};

export const AcmRulesCreateToolbar = compose(
    connect(
        null,
        dispatch => ({
            dispatchCreateAcmRule: (values) => dispatch(createAcmRule(values))
        })
    )
)(AcmRulesCreateToolbarPure);