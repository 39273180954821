//not done.
//but will use import for logger stuff
import { ZoneTypesProcessor, ZoneActivityProcessor, ThreeLightsProcessor, InspectTypes, ModuleError } from "../";
//import {Logger, LOGGERS} from '../../../app/configs/DebugConfig';
import {get} from 'lodash';
import {val2code as v2c} from "../threeLights/ThreeLightsConverters";
import {WeightClassesEnums} from "../../LogicV1Redux/constants/CheckinsV2Enums";
import { unixTimeNow } from "../common/src/TimeUtils";

const l = console;

//singletons maybe not tonight... FIXME
let activityProcessor = null;
let zoneTypeProcessor = null;
let threeLightsProcessor = null;

//fixme passing checkin modifiers, add alerts support
//and yes, i think better call as loop, not precalc
//currently for debug in rt-map
export function calcThreeLights({zones, alerts, }) {
    if (threeLightsProcessor === null) {
        threeLightsProcessor = new ThreeLightsProcessor({debug: true})
    }

    const ret = [];

    const fakeCalcParams = {
        weightClass : WeightClassesEnums.MEDIUM,
        aglMinMax:[0, 100]
    }

    for (let i = 0; i < zones.length; i++) {
        const zone = zones[i];
        const val = threeLightsProcessor.calculate(zone, fakeCalcParams);
        ret.push({value:val, code:v2c(val), __zoneName:zone.name});
    }

    return ret;
}


//was *usually* called independently from connect (frontend)
//but since we have master/slave zones, we have to use looped connectDynamicData
function calcActivity ({inspectData, time}) {

    try {


        const ret = activityProcessor.calculate({inspectData, time});

        if (ret.archived) {
            console.log('skipping archived %s', inspectData.zoneRecord.name);
            return null;
        }

        return ret;
    } catch (e) {
        if (e instanceof ModuleError) {
            l.warn(e)
        } else {
            l.log('rethrowing error in module');
            throw e;
        }
    }

}

//fixme passing checkin modifiers, add alerts support
export function calcThreeLightsFromInspectDatum(inspectDatum, calcParams) {
    if (threeLightsProcessor === null) {
        threeLightsProcessor = new ThreeLightsProcessor({debug: true})
    }

    console.log(inspectDatum, calcParams);
    return threeLightsProcessor.calculate(inspectDatum, calcParams);
}

//was really dirty, to review
export function connectDynamicData ({zones, webcatActivations, aixmTimeslices, notams, skipActivationCalc=false }) {

    console.log('connectDynamicData', zones);

    const timeNow = unixTimeNow();

    const inspectDataList = [];

    const zonesWithMaster = [];

    const keyedMasterZones = {};

    if (activityProcessor === null) {
        //hmm not working with js-logger? to test
        //activityProcessor = new ZoneActivityProcessor({debug: true, logger:l})
        activityProcessor = new ZoneActivityProcessor({debug: true})
    }
    //l.error('connecting dynamic data, ', aixmTimeslices )

    //l.info('aixmTimeslices', aixmTimeslices);
    for (let i = 0; i < zones.length; i++) {


        let {inspectData} = zones[i];

        //console.log('inspectData in connectDynamicData', inspectData);

        const zoneMaster = get(inspectData.zoneRecord, 'etc.master', false);

        if (zoneMaster) {
            //console.log('---zoneMaster in connectDynamicData', inspectData);

            inspectData.__master = zoneMaster;
            zonesWithMaster.push(zones[i]);
            //first we need all other zones calculated
            continue;
        }

        if (aixmTimeslices && aixmTimeslices[inspectData.name]) {
            inspectData.aixmTimeslices = aixmTimeslices[inspectData.name];
        }

        if (webcatActivations && webcatActivations[inspectData.name]) {
            inspectData.webcatActivations = webcatActivations[inspectData.name];
        }

        if (notams && notams[inspectData.name]) {
            //console.error('notam activations for', inspectData.name)
            inspectData.notams = notams[inspectData.name];
        }

        let recalced;

        if (!skipActivationCalc) {
            recalced = calcActivity({inspectData, time: timeNow});
            if (!recalced) {
                console.error('cannot recalc connecting dynamic data, ', inspectData );
                inspectData.__inactive = true;
                inspectData.__archived = true;
            } else {
                inspectData = {...inspectData, ...recalced};
                //console.log('inspectData', inspectData.name, inspectData.__min, inspectData.__max)
            }
        }

        zones[i].inspectData = inspectData;

        //fck reconnect to primitive
        if (zones[i].primitive) {
            zones[i].primitive.inspectData = inspectData;
        }
        //console.log('zone', inspectData.name, zones[i]);

        keyedMasterZones[inspectData.name] = inspectData;

    }

    //console.error('zonesWithMaster', zonesWithMaster);

    for (let i = 0; i < zonesWithMaster.length; i++) {
        let {inspectData} = zonesWithMaster[i];
        const masterName = inspectData.__master;

        const masterData = keyedMasterZones[masterName];

        if (!masterData) {
            console.log('masterData not found, skipping,', inspectData.name, masterName);
            inspectData.__inactive = true;
            inspectData.__archived = true;
            continue;
        }

        //rest is copied from master
        inspectData.__inactive = masterData.__inactive;

        if (inspectData.__zoneRules.use_master_heights) {
            inspectData.__min = masterData.__min;
            inspectData.__max = masterData.__max;
        } else {
            inspectData.__min = inspectData.zoneRecord.min;
            inspectData.__max = inspectData.zoneRecord.max;
        }
        inspectData.webcatActivations = masterData.webcatActivations;
        inspectData.aixmTimeslices = masterData.aixmTimeslices;

        //fck reconnect to primitive
        if (zonesWithMaster[i].primitive) {
            zonesWithMaster[i].primitive.inspectData = inspectData;
        }

        //inspectDataList.push(inspectData);
    }

    //l.error('connecting dynamic data, ', inspectDataList )

    return zones;
}


export function getZoneRules(zoneTypes, zoneType) {
    if (zoneTypeProcessor === null) {
        zoneTypeProcessor = new ZoneTypesProcessor({debug: true})
    }

    return zoneTypeProcessor.getZoneRules(zoneTypes, zoneType);
}
