//second io connection client -> this one for loopback server...
//works CONCURENTLY with v1 at the moment.

import io from 'socket.io-client';
import {urls, APP_MODES} from '../../Configs'
import PubSub from 'pubsub-js'
import {get} from 'lodash'
import {PubSubSignals} from '../constants/PubSubSignals'
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {fetchAllAlertsV2Rq} from "../actions/AlertsV2Actions";
import {fetchAllMissionsV2Rq} from "../actions/MissionsV2Actions";
import {fetchAllCheckinsV2Rq} from "../actions/CheckinsV2Actions";
import {storeSocketData} from "../actions/SocketActions";
import {isSuperAdmin} from "../../dr_ra2/utils/tempCommon";
import {showNotification, hideNotification} from 'react-admin';
import {MissionV2Statuses} from "../constants/MissionsV2Enums";


let mySocket;
let myheartbeat;
let lastClientTs;
let connectivityIssues;
let fromInit;

function overrideOnClose(socket){
    socket.io.cleanup();
    socket.io.backoff.reset();
    socket.io.readyState = 'closed';

    if (socket.io._reconnection && !socket.io.skipReconnect) {
        socket.io.reconnect();
    }
}

function currTs() {return new Date().getTime()/1000}



function close() {

    if (mySocket) {
        console.log(mySocket);
        mySocket.disconnect();
    }
    //mySocket = null;
    console.log('-----------SocketV2 closing loopback socket');

}

//add init check!
export function emit(msg, data) {

    mySocket.emit(msg, data);
}


export function init({myAppExtras, apiKey}) {
    fromInit = true;

    if (mySocket) {
        console.log('SocketV2 socket already opened, closing');
        mySocket.close();
    }


    console.log('-----------SocketV2 opening socket');
    //huh,

    mySocket = io.connect(
        urls.socketV2Url,
        {
            path:urls.socketV2Path,
            withCredentials: false
        });

    mySocket.on('connect', () => {
        console.log('SocketV2 connect', mySocket);

        const {myAuth = {}} = StoreProvider.getState();
        const authToken = myAuth.token;

        const authData = (authToken)?{token:authToken}:{apiKey};

        const isGov = myAppExtras.appMode === 'GOV';

        if (isGov) {
            authData.isGov = true;
            authData.isSuperAdmin = isSuperAdmin();
        }

        console.log('SocketV2 authentication data', authData);
        mySocket.emit('authentication', authData);

    });

    mySocket.on('unauthorized', function(err){
        console.error("SocketV2 there was an error with the authentication:", err.message);
    });

    mySocket.on("postAuthenticate", msg => {
        StoreProvider.getStore().dispatch(storeSocketData({postAuthTs: msg, lastServerTs:msg}));
        console.log('SocketV2 postAuthenticate ts', msg);

        //resyncing, probably should be saga
        if (!fromInit && myAppExtras.app.name === 'RT_MAP') {
            StoreProvider.getStore().dispatch(showNotification('Syncing data', 'info'));

            StoreProvider.getStore().dispatch(fetchAllAlertsV2Rq());
            StoreProvider.getStore().dispatch(fetchAllCheckinsV2Rq());
            StoreProvider.getStore().dispatch(fetchAllMissionsV2Rq());
        }
        lastClientTs = currTs();

        if (myheartbeat) clearInterval(myheartbeat);

        fromInit = false;

        myheartbeat = setInterval(() => {
            const clientTs = currTs();
            //console.log('localHeartbeat real diff', clientTs - lastClientTs);
            lastClientTs = clientTs;

            const state = StoreProvider.getState();
            if (state.socket.connected && state.socket.lastClientTs) {
                const hbDiff = clientTs - state.socket.lastClientTs;
                //console.log('localHeartbeat last server diff', hbDiff);

                if (hbDiff > 3) {
                    if (!connectivityIssues) {
                        //for gov only now
                        if (myAppExtras.app.name === 'RT_MAP' || myAppExtras.app.name === 'DR2_GOV') {
                            StoreProvider.getStore().dispatch(showNotification('Server connectivity issues', 'warning'));
                        }

                        if (myAppExtras.app.name === 'RT_MAP') {
                            console.log('socketv2 on connectivity issues', mySocket);
                            overrideOnClose(mySocket)
                            //mySocket.io.onclose('connectivityIssues');
                            //setTimeout(() => open(), 1000);
                        }

                        connectivityIssues = true;
                    }
                } else {
                    connectivityIssues = false;
                }
            }

        }, 1000)
    });

    mySocket.on("serverTs", msg => {
        const state = StoreProvider.getState();
        if (state.socket.postAuthTs) {
            StoreProvider.getStore().dispatch(storeSocketData({lastServerTs: msg, lastClientTs}));
        }
        //console.log('SocketV2 serverTs', msg, state.socket);
    });

    mySocket.on("msg", msg => {
        // console.log the message for posterity
        console.log('SocketV2 msg', msg);

    });

    //ra2 mode
    if (myAppExtras.app.name !== 'RT_MAP') {

        mySocket.on("missionStatusChange", msg => {
            console.log('SocketV2 missionStatusChange', msg);
            PubSub.publish(PubSubSignals.MISSION_STATUS_CHANGE, msg)

        });

        mySocket.on("droneStatusChange", msg => {
            console.log('SocketV2 droneStatusChange', msg);
            PubSub.publish(PubSubSignals.DRONE_STATUS_CHANGE, msg)

        });

        mySocket.on("certStatusChange", msg => {
            console.log('SocketV2 certStatusChange', msg);
            PubSub.publish(PubSubSignals.CERT_STATUS_CHANGE, msg)

        });

    }
    //superadmin only
    if (myAppExtras.app.name === 'DR2_GOV' && isSuperAdmin()) {

        console.log('superadmin -> socket listening to log');
        mySocket.on("logPublished", msg => {
            console.log('SocketV2 logPublished', msg);
            PubSub.publish(PubSubSignals.LOG_PUBLISHED, msg)
        });
    }

    //rt map only
    if (myAppExtras.app.name === 'RT_MAP') {

        mySocket.on("missionStatusChange", msg => {
            console.log('SocketV2 missionStatusChange', msg);
            if (msg.status === MissionV2Statuses.ACCEPTED) {
                StoreProvider.getStore().dispatch(fetchAllMissionsV2Rq());
            }
        });

        mySocket.on("operationNotification", msg => {
            console.log('SocketV2 operationNotification', msg);
            StoreProvider.getStore().dispatch(fetchAllCheckinsV2Rq());
        });

        mySocket.on("operationsDeactivation", msg => {
            console.error('SocketV2 operationsDeactivation', msg);
            console.log('SocketV2 operationsDeactivation', msg);
            StoreProvider.getStore().dispatch(fetchAllCheckinsV2Rq());
        });

        mySocket.on("alertNotification", msg => {
            console.log('SocketV2 alertNotification', msg);
            StoreProvider.getStore().dispatch(fetchAllAlertsV2Rq());
            StoreProvider.getStore().dispatch(fetchAllCheckinsV2Rq());

        });
    }

    mySocket.on('authenticated', function() {
        // use the socket as usual

        console.log('SocketV2 User is authenticated (anon supported)', mySocket);
        StoreProvider.getStore().dispatch(storeSocketData({connected: mySocket.connected}));
        if (myAppExtras.app.name === 'RT_MAP' || myAppExtras.app.name === 'DR2_GOV') {
            StoreProvider.getStore().dispatch(showNotification('Server socket connected', 'info'));
        }
    });

    mySocket.on('disconnect', function(msg){
        console.error('SocketV2 you were disconnected', msg);
        if (myheartbeat) clearInterval(myheartbeat);

        StoreProvider.getStore().dispatch(storeSocketData({connected: mySocket.connected}));
        if (myAppExtras.app.name === 'RT_MAP' || myAppExtras.app.name === 'DR2_GOV') {
            StoreProvider.getStore().dispatch(showNotification('Server socket disconnect', 'warning', {autoHideDuration: 5000}));
        }

    });

    mySocket.on('ping', function(data) {

        console.log('SocketV2 ping: ');
        const transport = get(mySocket, 'io.engine.transport');
        console.log(transport)

    });

    mySocket.on('pong', function(msg){
        console.log('SocketV2 pong', msg);
    });

    mySocket.on('connect_error', (error) => {
        StoreProvider.getStore().dispatch(hideNotification());
        StoreProvider.getStore().dispatch(showNotification('Server socket connect error', 'warning', {autoHideDuration: 2000}));
        console.log('SocketV2 connect_error: ', error.toString());
        //console.dir(error);
    });

    mySocket.on('reconnect_attempt', (number) => {
        console.log('SocketV2 reconnect_attempt: ', number);
    });

    mySocket.on('reconnect', (number) => {
        console.log('SocketV2 reconnect: ', number);
    });

    mySocket.on('reconnect_error', (error) => {

        console.log('SocketV2 reconnect_error: ', error.toString());
        //console.dir(error);
    });

}

export default {
    init,
    close,
    emit
}
