import {FormSection, formValueSelector} from "redux-form";
import React from "react";
import Tree from 'react-animated-tree'
import {AcmTermsInputBranch} from "./AcmTermsInputBranch";
import Typography from "@material-ui/core/Typography";
import {translate} from 'react-admin'
import compose from 'recompose/compose';
import {RESOURCES} from "../../../logic/MyRestConfig";
import {withStyles} from "@material-ui/core"
import {get} from "lodash";
import {connect} from "react-redux";

const areTermsDefined = ({source, record, rpaType, langs = []}) => {
    for(const lang of langs) {
        const hasEntry = !!get(record, `${source}.${rpaType}.${lang}`);
        if(!hasEntry) return false;
    }
    return true;
};

const areTermsNotEmpty = ({rpaType, langs = [], terms}) => {
    for(const lang of langs) {
        const entry = get(terms, `${rpaType}.${lang}`);
        if(typeof entry !== 'string' || entry.length === 0) {
            return false;
        }
    }
    return true;
};

const langs = ['pl', 'en'];

const styles = theme => ({
        root: {
            marginBottom: theme.spacing.unit,
            marginTop: 2 * theme.spacing.unit
        }
    })
;

export const AcmTermsInputTree = compose(
    translate,
    withStyles(styles),
    connect(
        (state, ownProps) => ({
            termsObject: formValueSelector(ownProps.formName)(state, 'etc.terms'),
        })
    )
)(
    ({source, translate, isOpen, classes, record, termsObject, formName}) => {

        const areTermsDefinedFnComposer = ({rpaType}) => areTermsDefined({source, record, rpaType, langs});
        const areTermsNotEmptyFnComposer = ({rpaType}) => areTermsNotEmpty({rpaType, langs, terms: termsObject});

        const acmTermsInputBranchComposer = (source) =>
            <AcmTermsInputBranch source={source}
                                 isDefined={areTermsDefinedFnComposer({rpaType: source})}
                                 isNotEmpty={areTermsNotEmptyFnComposer({rpaType: source})}
                                 formName={formName}
                                 langs={langs}
            />;

        const branches = <>
            {acmTermsInputBranchComposer('def')}
            {acmTermsInputBranchComposer('red')}
            {acmTermsInputBranchComposer('yellow')}
            {acmTermsInputBranchComposer('green')}
            {acmTermsInputBranchComposer('blue')}
        </>;

        return (
            <FormSection name={source}>
                <Typography variant='body1' className={classes.root} component={'div'}>
                    <Tree content={translate(`resources.${RESOURCES.ACM_RULES}.fields.etc.terms.header`)}
                          open={isOpen}
                    >
                        {branches}
                    </Tree>
                </Typography>
            </FormSection>
        )
    }
);