import {ALERT_SHOW_MSG} from "./AlertboxConstants";

const initialState = {
    queue: [],
    popVersion: 0,
};

export default (state = initialState, {type, situationID, locationValid, data}) => {
    switch (type) {

        case ALERT_SHOW_MSG:

            const alertMsgInit = {
                situationID,
                locationValid,
                data
            };

            const queue = state.queue;
            queue.push(alertMsgInit);
            const popVersion = state.popVersion + 1;

            return {
                ...state,
                queue,
                popVersion
            };

        default:
            return state;
    }
}

