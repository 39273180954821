import { all, put, takeEvery,  select } from 'redux-saga/effects';
import {FETCH_ALL_UNITS_V2_RQ, STORE_ALL_UNITS_V2, SAVE_UNIT_DATA_V2_RQ} from '../constants/UnitsV2Constants';
import DataProvider from "../../dr_ra2/MyDataProvider";

import { showNotification } from 'react-admin';
import { deserializeUnitsV2 } from '../oldLogic/UnitsListV2';
import { refreshView } from 'react-admin';
import { serializePrimitive } from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";
import { push } from 'react-router-redux';

import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import {
    MAP_UI_UNITS_V2_SHOW,
    MAP_UNITS_V2_ENABLE_RQ,
    MAP_UNITS_V2_ENABLED
} from "../constants/MapUxConfigConstants";

import {LOADING_END, LOADING_START} from "../../dr_ra2/logic/ui/MyUiActions";
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

export const getMySelf = (state) => state.mySelf;
export const getEditablePrimitives = (state) => state.drawEditorData.editablePrimitives;

//copy/paste of zone saga. DRY me later!

//todo check if FETCH START/END should be added
const getMapUxConfig = (state) => state.mapUxConfig;
const getMyAppExtras = (state) => state.myAppExtras;
const getUnitsV2 = (state) => state.unitsV2;


function* handleFullSave({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'myroot.message.saving',
    });

    //yield put(showNotification('missionsV2 save here', 'warning'));
    try {

        const editablePrimitives = yield select(getEditablePrimitives);

        console.warn('handleFullSave SAVE_UNIT_DATA_V2_RQ');
        console.log('unitV2 save here', type, payload);

        const dataProvider = DataProvider.getDataProvider();

        const unitRecord = Object.assign({}, payload);

        if (!unitRecord.etc) {
            unitRecord.etc = {};
        }

        if (editablePrimitives.length !== 0) {

            //currently single primitive

            if (editablePrimitives.length !== 1) {
                yield earlyFailSafe('resources.units.notifications.oneAreaRequiredErr');
                //wtf. not working..
                //yield put(setAllEditablePrimitives(editablePrimitives));
                return;
            }

            const {primitive, wkb, min, max } = serializePrimitive(editablePrimitives[0]);
            unitRecord.etc.primitive = primitive;
            unitRecord.geometry = wkb;
            //no min max check here.

            //only on geometry change?
            yield put(showNotification('resources.units.notifications.jurisdictionChange'));

        }

        console.log('unitV2 save here', unitRecord);

        let unitId = unitRecord.uid;

        console.warn('===============unitId', unitId);
        let unitSaved, isCreate;
        isCreate = (!unitId);

        if (isCreate) {
            unitSaved = yield dataProvider(API_VERBS.CREATE, 'units', {data: unitRecord});
            console.warn('=================new unit saved', unitSaved);
            //throw "new mission saved"
            unitId = unitSaved.data.uid;

        } else {
            unitSaved = yield dataProvider(API_VERBS.UPDATE, 'units', {id: unitId, data: unitRecord});
            console.warn('=================old unit updated', unitSaved);
        }


        yield put({ type: FETCH_END });

        if (isCreate) {
            yield put(push(`/units/${unitId}/edit`));
            yield put({type: LOADING_END});
        }
        else {
            yield put(refreshView());
            yield put({type: LOADING_END});
        }

    } catch (error) {
        console.error('handleSave SAVE_UNIT_DATA_V2_RQ error', error);
        yield put(showNotification('resources.units.notifications.saveFailedErr', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});

    }
}


function* handleFetch({ type, payload = {}}) {

    const appExtras = yield select(getMyAppExtras);

    const unitsV2 = yield select(getUnitsV2);

    if (unitsV2.rawData.length > 0) {
        console.warn('====unitsv2 already loaded, skipping fetch request, some meta maybe later');
        return;
    }

    console.log('payload', payload);
    console.log('appExtras', appExtras);

    try {

        const dataProvider = DataProvider.getDataProvider();

        console.log('data provider', dataProvider);

        const unitsAll = yield dataProvider(API_VERBS.GET_LIST, 'unitsAll', {});
        console.log('handleFetch FETCH_ALL_UNITS_V2_RQ response', unitsAll);
        const unitsProcessed = deserializeUnitsV2(unitsAll.data);
        console.log('handleFetch deserializeUnitsV2 response', unitsProcessed);

        //fixme other similar calls to support forceShow..
        if (payload.meta && payload.meta.forceShow)
            yield put({type: MAP_UI_UNITS_V2_SHOW, payload:true});

        yield put({ type: STORE_ALL_UNITS_V2, payload:{rawData:unitsProcessed} });

        yield put({type: MAP_UNITS_V2_ENABLED, payload:true});

        //aargh.
        //PubSub.publish(PubSubSignals.ON_UNITS_RULES_CHANGE, getUnitRules());
        //yield put({ type: FETCH_END });

    } catch (error) {
        console.error('handleFetch FETCH_ALL_UNITS_V2_RQ error', error);
        yield put(showNotification('unitsV2 fetch error', 'warning'));
        //yield put({ type: FETCH_END });
    }
}

//fixme show preferences?
function* handleEnableToggle({payload}) {
    console.warn('handle MAP_UNITS_V2_ENABLE_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_UNITS_V2_RQ, payload});
    } else {
        //just disabling
        yield put({type: MAP_UNITS_V2_ENABLED, payload:false});
        yield put({type: MAP_UI_UNITS_V2_SHOW, payload:false});
    }

}

//just force refersh with new timestamp..
//fixes dirty check in map draw
function* handleShownToggle({ type, payload }) {

    //console.log('handleTick in alerts (cleaning)', payload);
    yield put({type: STORE_ALL_UNITS_V2, payload: {}});

}

export default function* () {

    yield all([
        takeEvery(MAP_UNITS_V2_ENABLE_RQ, handleEnableToggle),

        takeEvery(FETCH_ALL_UNITS_V2_RQ, handleFetch),

        takeEvery(MAP_UI_UNITS_V2_SHOW, handleShownToggle),

        takeEvery(SAVE_UNIT_DATA_V2_RQ, handleFullSave),
    ]);
}

//----------helpers

function* earlyFailSafe(msg) {
    yield put(showNotification(msg, 'warning'));
    yield put({ type: FETCH_END });
    yield put({type: LOADING_END});

    //yield put(refreshView()); //no refresh on failsafe!
}
