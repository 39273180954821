import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import { urls} from "../../Configs";
import axios from "axios";
import { showNotification } from 'react-admin';

import {FETCH_ALL_RTDM_EXCHANGE_AIRCRAFTS_RQ, STORE_ALL_RTDM_EXCHANGE_AIRCRAFTS} from "../constants/RtdmExchangeAircraftsConstants";
import {
    MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ,
    MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLED,
} from "../constants/MapUxConfigConstants";

import {TIMER_TICK_RQ} from "../constants/TickConstants";


const getMyAppExtras = (state) => state.myAppExtras;
const getMapUxConfig = (state) => state.mapUxConfig;

//this one got messy.
//redis adsb exchange support was split into two endpoints
//one for ticks (position)
//second for aircraft/flight data... (this one)
//idea was to reduce bandwith when using creotech box.
//(and connect flight data db to creotech)
//but we still are using exchange ticks,
//and creotech is still in demo phase...
//on the other hand finally i should finally fix socket support, so...
// ... keeping splitted,

function* handleFetch({ type, payload }) {

    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiRtdmSourcesEnabled) {
    //     console.error('lprHelipads fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);

    //console.log('payload', payload);
    //console.log('fetch exchangeAircrafts start');

    yield put({ type: FETCH_START });

    try {

        const rtdmExchangeAircraftsUrl = urls.rtdmExchangeAircraftsUrl;

        const opts = {
            method:'get',
        };

        const promise = axios(rtdmExchangeAircraftsUrl, opts);

        const response = yield call(() => promise);

        //fixme show preferences?
        //currently display is connected to exchange ticks!
        //yield put({type: MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW, payload:true});
        // const mapUxConfig = yield select(getMapUxConfig);
        //
        // if (isPandora) {
        //     yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:mapUxConfig.pandoraTrafficEnabled});
        //     yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLED, payload:mapUxConfig.pandoraTrafficEnabled});
        // } else {
        //     yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:true});
        //     yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLED, payload:true});
        // }

        yield put({type: MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLED, payload:true});

        console.log('fetch exchangeAircrafts response', response);
        yield put({ type: STORE_ALL_RTDM_EXCHANGE_AIRCRAFTS, payload:{rawData:response.data} });



        yield put({ type: FETCH_END });

    } catch (error) {
        console.error('exchangeAircrafts fetch error', error);
        yield put(showNotification('exchangeAircrafts fetch error', 'warning'));
        yield put({ type: FETCH_END });
    }

}


//fixme show preferences?
function* handleEnableToggle({payload}) {
    //console.error('handle MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_RTDM_EXCHANGE_AIRCRAFTS_RQ});
    } else {
        //just disabling

        yield put({type: MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLED, payload:false});

        //currently display is connected to exchange ticks!
        //yield put({type: MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW, payload:false});
    }
}

function* handleTick({payload}) {
    //console.error('handle TIMER_TICK_RQ for exchange tick, payload:', payload);

    //every 10 ticks -> flight info after all
    //probably could be even less. but we need better quality test on redis
    //but also requires reread on show...
    if (payload.ticks % 10 === 0) {
        const mapUxConfig = yield select(getMapUxConfig);
        //let's not fetch in background.. only when visible
        if (mapUxConfig.uiRtdmExchangeTicksShow) {
            yield put({type: FETCH_ALL_RTDM_EXCHANGE_AIRCRAFTS_RQ});
        }
    }
}


export default function* () {
    //disabling exchange (no longer works)

    const fx = [
        //takeEvery(MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ, handleEnableToggle),
        //takeEvery(FETCH_ALL_RTDM_EXCHANGE_AIRCRAFTS_RQ, handleFetch),
        //takeEvery(TIMER_TICK_RQ, handleTick),
    ];

    // height filter derived from exchange ticks
    yield all(fx);
}
