import { all, put, takeEvery } from 'redux-saga/effects';
import {LOADING_END, LOADING_START} from "../../dr_ra2/logic/ui/MyUiActions";
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import DataProvider from "../../dr_ra2/MyDataProvider";
import { showNotification, refreshView } from 'react-admin';
import { push } from 'react-router-redux';
import {PUBLISH_NOTAM} from "../constants/NotamsConstants";
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";


/**
 * @param type
 * @param payload {[uid],[status],[resource],[redirect]}
 * @returns {IterableIterator<*>}
 */
function* handlePublishNotam({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'resources.notams.publishing'
        // primaryMessage: 'myroot.message.saving'
    });

    try {

        console.log('========= publish notam here', type, payload);


        const {actDef, zoneReferenceType, ...cleanedRecord} = payload.record;
        const etc = {
            actDef,
            zoneReferenceType
        };

        const dataProvider = DataProvider.getDataProvider();
        const publishedNotam = yield dataProvider(API_VERBS.CREATE, payload.resource,
            {
                data: {
                    ...cleanedRecord,
                    etc
                }
            });

        const notamId = publishedNotam.data.uid;

        yield put(showNotification('ra.notification.updated', 'info'));

        yield put({ type: FETCH_END });
        yield put(push(`/${payload.resource}/${notamId}/show`));
        yield put(refreshView());
        yield put({type: LOADING_END});

    } catch (error) {
        yield put(showNotification('ra.message.error', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});
    }
}


export default function* () {

    yield all([
        takeEvery(PUBLISH_NOTAM, handlePublishNotam),
    ])
}
