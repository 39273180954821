import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import {
    FETCH_ALL_CHECKINS_V2_RQ,
    STORE_ALL_CHECKINS_V2,
    POST_OWNED_CHECKIN_V2,
    POST_ATC_REVIEWED_CHECKIN_V2, SET_LOST_CONTROL_REVIEWED
} from '../constants/CheckinsV2Constants';
import { TIMER_TICK_RQ} from '../constants/TickConstants';
import {MAP_SHOW_INSPECT_DATA, STORE_INSPECT_DATA} from "../constants/InspectConstants";
import { showNotification } from 'react-admin';
import axios from "axios";

import {fixFileName, fixModelId, fixReferenceId, fixUserId} from '../oldLogic/LoopbackUtils'

import {FlightStatusesV2, CheckinPostRqEnums, LostControlAlert} from "../constants/CheckinsV2Enums";
import {srtmHeightAsPromise} from '../oldLogic/MySrtm';
import has from 'lodash.has';

import {
    FETCH_START,
    FETCH_END
} from 'react-admin';

import {APP_MODES, enabledFeatures, FEATURES_V1_2_ENUMS, urls} from "../../Configs";
import {checkForChanges} from "../oldLogic/NewCheckinV2FSM";
import inspectData from "../reducers/InspectDataReducer";
import {InspectTypes} from "../../modules2lib/common/";
import { m2ftCeiling} from "../oldLogic/GeoConverters";
import {isoTimeNow, unixTimeNow,} from "../oldLogic/TimeConverters";
import {
    MAP_CHECKINS_V2_ENABLE_RQ,
    MAP_CHECKINS_V2_ENABLED, MAP_UI_CHECKINS_V2_SHOW, MAP_UI_HEIGHT_FILTER
} from "../constants/MapUxConfigConstants";
import {isInMyJurisdiction} from '../oldLogic/MySelf'

import {
    postCheckInDataTransform,
} from "../oldLogic/NewCheckinV2FSM";
import {cleanOutdatedCheckins, findCheckinRawDataByUid, preProcessCheckIns} from "../oldLogic/CheckInsV2List";
import {getAuthHeaderV2} from "../oldLogic/AuthConverters";
import {LOADING_END} from "../../dr_ra2/logic/ui/MyUiActions";
import PubSub from "pubsub-js";
import {PubSubSignals} from "../constants/PubSubSignals";
import {CHECKIN_VIEWPORT_BBOX_RADIUS, defaultViewportData} from "../../gl_map/ConfigurableOpts";
import {primitives2Bbox} from "../../gl_map/glSetups/oldCommon/_CommonStuff";
import {wkb2geojson} from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";
import DeckCircle from "../../gl_map/glSetups/deckPrimitives/DeckCircle";

import {NEW_PANDORA_TIMEOUTS_BEHAVIOUR} from '../../Configs';

const getMyAppExtras = (state) => state.myAppExtras;
const getCheckinsV2 = (state) => state.checkinsV2;
const getZonesV2 = (state) => state.zonesV2;
const getInspectData = (state) => state.inspectData;
const getMapUxConfig = (state) => state.mapUxConfig;
const getMySelf = (state) => state.mySelf;
const getMyAuth = (state) => state.myAuth;
const getTick = (state) => state.tick;

const CUSTOM_RT_MAP_OPS = enabledFeatures.includes(FEATURES_V1_2_ENUMS.CUSTOM_RT_MAP_OPS);

//can't use react admin data provider (mobile mode)

export function* handleFetch({ type, payload = {} }) {
    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiCheckinsV1Show) {
    //     console.error('checkinsV1 fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);
    const myAuth = yield select(getMyAuth);

    //console.log('payload', payload);
    //console.log('appExtras', appExtras);

    yield put({ type: FETCH_START });

    try {

        const filter =  NEW_PANDORA_TIMEOUTS_BEHAVIOUR?{
            where: {
                and: [
                    {
                        status: {
                            //nin:['cancelled', 'landed']
                            neq: 'cancelled'
                        }
                    },
                    { active:true }//wip, to review behaviour
                    //{
                    //     or: [
                    //         {stop: {gt: isoTimeNow()}},
                    //         {ctrl: true},
                    //         {status: 'lostControl'},
                    //     ]
                    // }
                ]

            },
                include: [
                    {
                        relation: "operatorHack",
                        scope: {
                            fields: ['priv']
                        }
                    }
                ],
        }
        :
            {
                where: {
                    and: [
                        {
                            status: {
                                //nin:['cancelled', 'landed']
                                neq: 'cancelled'
                            }
                        },
                        {
                            stop: {
                                gt: isoTimeNow()
                            }
                        }
                    ]
                },
                or: {status: 'lostControl'},
            }
        ;

        let url;

        if (CUSTOM_RT_MAP_OPS) {
            url = urls.checkinsV2Urls.RT_MAP_CUSTOM;
        } else {
            url = urls.checkinsV2Urls[appExtras.app.name];
            url += '/?filter=' + JSON.stringify(filter);
        }

        const promise = axios(encodeURI(url), {
            method: 'get',
            headers: getAuthHeaderV2(myAuth)
        });

        const response = yield call(() => promise);

        //fck 21-02-2018 hack for missing GND in m (or heigth in AMSL f)
        console.log('=====fetch checkinsV2', response.data);
        const data = response.data;

        //should be on backend btw
        for (let i = 0; i < data.length ; i++) {
            // const coords = featureColl.features[i].geometry.coordinates;
            // console.warn('======fetch checkins srtmHeightAsPromise', featureColl);
            // const srtmResponse = yield srtmHeightAsPromise(appExtras.app.name, [coords[0], coords[1]]);
            // //console.warn("======srtm response", srtmResponse);
            // //const legUpdated = yield dataProvider(API_VERBS.UPDATE, 'legs', {id:currLeg.uid, data:currLeg})
            // featureColl.features[i] = processSrtmResponse(srtmResponse, featureColl.features[i]);

        }

        //damn, warnigns list have to be processed on frontend for checkinsV2, for now..
        const zonesV2 = yield select(getZonesV2);
        const mySelf = yield select(getMySelf);
        const currCheckins = yield select(getCheckinsV2);
        const preprocessed = preProcessCheckIns(data, appExtras, currCheckins, zonesV2, mySelf);
        console.log('=====fetch checkins response', preprocessed, zonesV2);

        let tick = yield select(getTick);
        const ret = cleanOutdatedCheckins(preprocessed, tick);

        const {isDirty, cleanedList, selectedCheckinV2, operatorCheckinV2} = ret;

        console.log('isDirty', isDirty, cleanedList);

        const cleaned =  {rawData: cleanedList, selectedCheckinV2, operatorCheckinV2};


        //fixme show preferences?
        yield put({type: MAP_UI_CHECKINS_V2_SHOW, payload:true});

        yield put({
            type: STORE_ALL_CHECKINS_V2,
            payload: {
                //...preprocessed,
                ...cleaned,
                bottomCutoff:mapUxConfig.heightFilter.bottomCutoff,
                topCutoff:mapUxConfig.heightFilter.topCutoff,
            }
        });

        /* and we are supposed to disable outdated checkins for rt-map ...
        //Duplicating data -> important
        //we dont want to disappear last selection even if it is no longer valid on backend!
        if (selectedCheckinV2Update) {
            yield put({
                type: STORE_INSPECT_DATA,
                payload: {checkin: selectedCheckinV2Update, inspectType: InspectTypes.CHECKINV1}
            });
        }
        */

        yield put({type: MAP_CHECKINS_V2_ENABLED, payload:true});

        yield put({ type: FETCH_END });

    } catch (error) {
        console.error('Checkins fetch error', error);
        yield put(showNotification('Checkins fetch error', 'warning'));
        yield put({ type: FETCH_END });
    }

}

function* handleTick({ type, payload }) {

    console.log('handleTick in checkins (cleaning/state change)', payload);
    const checkinsStore = yield select(getCheckinsV2);

    const ret = cleanOutdatedCheckins(checkinsStore, payload);

    const {isDirty, cleanedList, selectedCheckinV2} = ret;

    console.log('isDirty', isDirty, cleanedList);

    // if (isDirty) {
        const checkins =  {rawData: cleanedList, selectedCheckinV2:selectedCheckinV2};

        yield put({type: STORE_ALL_CHECKINS_V2, payload:checkins});
    // } else if (selectedCheckinV2) {
    //     const checkins = { selectedCheckinV2:selectedCheckinV2};
    //
    // }
    // else yield null;
}


// function* handleStoreoperatorCheckinV2V2({type, payload}) {
//     console.log('handleStoreoperatorCheckinV2 in checkins', payload);
// }



function* handleInspectDataChange({ type, payload }) {

    const {rawData, selectedCheckinV2, lostControlReviews} = yield select(getCheckinsV2);
    //console.error('refactored, double check');

    let newselectedCheckinV2;
    //maybe ould be optimized to store inspectData only, but after mobile redraw/reload check
    if (payload.inspectType === InspectTypes.CHECKINV2) {
        // for (let i = 0; i < rawData.length; i++) {
        //     console.log(rawData[i]);
        //     if (rawData[i].inspectData.checkinRecord.uid === payload.checkinRecord.uid) {
        //         newselectedCheckinV2 = rawData[i].inspectData;
        //         break;
        //     }
        // }
        const el = findCheckinRawDataByUid(rawData, payload.checkinRecord.uid);
        newselectedCheckinV2 = el.inspectData;

        const {uid} = el.inspectData.checkinRecord;
        if(lostControlReviews.has(uid) && lostControlReviews.get(uid) === LostControlAlert.SHOULD_REVIEW) {

            yield put({type: SET_LOST_CONTROL_REVIEWED, payload: {uid}});
        }

    }

    //if (selectedCheckinV2)
    console.warn('====selected checkin from payload ', newselectedCheckinV2);

    const checkins = {
        rawData,
        selectedCheckinV2: newselectedCheckinV2,
    };

    //if neither exists skip update;
    if (newselectedCheckinV2 || selectedCheckinV2) {
        yield put({type: STORE_ALL_CHECKINS_V2, payload: checkins});
    }
}


//fixme show preferences?
function* handleEnableToggle({payload}) {
    console.log('handle MAP_CHECKINS_V2_ENABLE_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_CHECKINS_V2_RQ});
    } else {
        //just disabling
        yield put({type: MAP_CHECKINS_V2_ENABLED, payload:false});
        yield put({type: MAP_UI_CHECKINS_V2_SHOW, payload:false});
    }

}

function* opNewCheckinRq({payload}) {
    console.error('removed in non-mobile')
    yield null;
}

function* opNewStatusRq(status) {
    console.error('removed in non-mobile')
    yield null;
}


function* opLostControlRq({payload}) {

    console.error('removed in non-mobile')
    yield null;

}


function* handlePostOwnedCheckinV2({payload}) {

    console.error('wrap postOwnedCheckinV2 with try catch! ')

    if (payload.meta && payload.meta.postRqMode === CheckinPostRqEnums.CREATE) {
        yield opNewCheckinRq({payload});
    } else if (payload.meta && payload.meta.postRqMode === CheckinPostRqEnums.STATUS_UPDATE) {
        yield opNewStatusRq(payload.status);
    } else if (payload.meta && payload.meta.postRqMode === CheckinPostRqEnums.LOST_CONTROL) {
        yield opLostControlRq({payload})
    }
}


function* handleAtcReviewedCheckinV2({payload}) {
    //console.error('double check if this the same checkin as in form', payload)

    PubSub.publish(PubSubSignals.SHOW_OPERATION_TAB, {});

    const {selectedCheckinV2, status, modData} = payload;

    //const {operatorCheckinV2} = yield select(getCheckinsV2);
    const appExtras = yield select(getMyAppExtras);
    console.log('handleReviewCheckinV2', payload);

    const checkinId = selectedCheckinV2.checkinRecord.uid;

    try {
        const finalUrl = fixModelId(checkinId, urls.govPatchCheckin);
        const myAuth = yield select(getMyAuth);

        let data;

        if (modData) {
            //overriding at the moment, probably should be accepted before change..
            data = {status, ...modData};
        } else {
            data = {status}
        }

        const mySelf = yield select(getMySelf);

        const myDefaultUnit = mySelf.units[0] || {};

        const newMsgBlock = {
            ...data,
            issuerEmail:mySelf.email,
            timestamp: unixTimeNow(),
            issuerUnitId: myDefaultUnit.uid,
            issuerUnitName: myDefaultUnit.name //yup, overhead, but no unit access for operator today..
        };

        if (payload.__msg) {
            newMsgBlock.msg = payload.__msg;
        }

        data.govMsgBlock = newMsgBlock;

        const promise = axios(finalUrl, {
            method: 'patch',
            data,
            headers: getAuthHeaderV2(myAuth),
        });

        const response = yield call(() => promise);
        console.log('handleReviewCheckinV2 response ', response);

        yield null;
    } catch (error) {
        console.error('handle POST_ATC_REVIEWED_CHECKIN_V2 error', error);
        yield put(showNotification('resources.operations.notifications.saveFailedErr', 'warning'));
        //yield put({ type: FETCH_END });
        //yield put(refreshView());
        //yield put({type: LOADING_END});
    }
}

//force recalc deck filters
function* handleDisplayPrefsChange({ type, payload }) {

    console.log('on handle display prefs change', type, payload);

    const checkinsV2 = yield select(getCheckinsV2);

    const mapUxConfig = yield select(getMapUxConfig);


    yield put({ type: STORE_ALL_CHECKINS_V2, payload:{

            rawData:checkinsV2.rawData,
            //currentInspectData:inspectData,
            selectedCheckinV2:checkinsV2.selectedCheckinV2,
            //zoneTypesVis:mapUxConfig.visibilityPrefs.zoneTypes,
            bottomCutoff:mapUxConfig.heightFilter.bottomCutoff,
            topCutoff:mapUxConfig.heightFilter.topCutoff,
        }
    });

}

function* handleInspectDataShowOnMap({ type, payload }) {

    if(payload.inspectType === InspectTypes.CHECKINV2) {

        //console.error('removed geometry', payload);
        // const {geometry} = payload.checkinRecord;
        // const geojson = wkb2geojson(geometry);
        //const primitives = [new DeckCircle(geojson.coordinates, CHECKIN_VIEWPORT_BBOX_RADIUS)];
        const primitives = [new DeckCircle(payload.__latLng, CHECKIN_VIEWPORT_BBOX_RADIUS)];
        const bBox = primitives2Bbox(primitives);

        PubSub.publish(PubSubSignals.MAP_VIEWPORT_SET_BBOX, {bBox});
    }
}


export default function* () {

    yield all([
        takeEvery(MAP_CHECKINS_V2_ENABLE_RQ, handleEnableToggle),

        takeEvery(FETCH_ALL_CHECKINS_V2_RQ, handleFetch),
        takeEvery(TIMER_TICK_RQ, handleTick),
        takeEvery(STORE_INSPECT_DATA, handleInspectDataChange),
        takeEvery(MAP_SHOW_INSPECT_DATA, handleInspectDataShowOnMap),
        takeEvery(POST_OWNED_CHECKIN_V2, handlePostOwnedCheckinV2),
        takeEvery(POST_ATC_REVIEWED_CHECKIN_V2, handleAtcReviewedCheckinV2),
        takeEvery(MAP_UI_HEIGHT_FILTER, handleDisplayPrefsChange)

    ]);
}


/// to move to server

function processSrtmResponse(response, height) {

    const __srtmV1Gnd = Math.ceil(response.data.hgtElevation);

    console.log('processing srtm response')
    console.log(__srtmV1Gnd+height);


    const __max = m2ftCeiling(__srtmV1Gnd+height);

    console.log(__max);

    return {__srtmV1Gnd, __max};
}
