import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import {
    //bandwidthIssues,
    urls
} from "../../Configs";
import axios from "axios";
import { showNotification } from 'react-admin';

import {FETCH_ALL_RTDM_CREOTECH_TICKS_RQ, STORE_ALL_RTDM_CREOTECH_TICKS} from "../constants/RtdmCreotechTicksConstants";
import {
    MAP_UI_RTDM_CREOTECH_TICKS_SHOW,
    MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ,
    MAP_RTDM_CREOTECH_TICKS_ENABLED,
    MAP_UI_HEIGHT_FILTER,
    PANDORA_TRAFFIC_HEIGHT_FILTER
} from "../constants/MapUxConfigConstants";
import {TIMER_TICK_RQ} from "../constants/TickConstants";


const getMyAppExtras = (state) => state.myAppExtras;
const getMapUxConfig = (state) => state.mapUxConfig;
const getInspectData = (state) => state.inspectData;
const getRtdmCreotechTicks = (state) => state.rtdmCreotechTicks;

//should be per app? pandora is default now
const isPandora = true;

function* handleFetch({ type, payload }) {

    // if (bandwidthIssues) {
    //     console.log('bandwidthIssues');
    //     return;
    // }
    //else console.log('bandwidthIssues no!')

    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiRtdmSourcesEnabled) {
    //     console.error('lprHelipads fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);

    //console.log('payload', payload);
    //console.log('fetch creotechTicks start');

    yield put({ type: FETCH_START });

    try {

        const creotechTicksUrl = urls.rtdmCreotechTicksUrl;

        const opts = {
            method:'get',
        };

        const promise = axios(creotechTicksUrl, opts);

        const response = yield call(() => promise);

        const inspectData = yield select(getInspectData);

        //fixme show preferences!!
        //console.log('fetch creotechTicks response', response);
        const mapUxConfig = yield select(getMapUxConfig);

        if (isPandora) {
            yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:mapUxConfig.pandoraCreotechTrafficEnabled});
            yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLED, payload:mapUxConfig.pandoraCreotechTrafficEnabled});
        } else {
            yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:true});
            yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLED, payload:true});
        }



        const {bottomCutoff, topCutoff} = (isPandora)? mapUxConfig.trafficHeightFilter:mapUxConfig.heightFilter;

        const nextPayload = {
            rawData:response.data,
            currentInspectData:inspectData,
            meta:{
                bottomCutoff,
                topCutoff
            }
        };

        yield put({ type: STORE_ALL_RTDM_CREOTECH_TICKS, payload:nextPayload});

        yield put({ type: FETCH_END });

    } catch (error) {
        console.error('creotechTicks fetch error', error);
        yield put(showNotification('creotechTicks fetch error', 'warning'));
        yield put({ type: FETCH_END });
    }

}


//fixme show preferences?
function* handleEnableToggle({payload}) {
    //console.error('handle MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_RTDM_CREOTECH_TICKS_RQ});
    } else {
        //just disabling

        yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLED, payload:false});
        yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:false});
    }
}

function* handleTick({payload}) {
    //console.error('handle TIMER_TICK_RQ for exchange tick, payload:', payload);

    //every tick
    if (payload.ticks) {
        const mapUxConfig = yield select(getMapUxConfig);
        //let's not fetch in background.. only when visible
        if (mapUxConfig.uiRtdmCreotechTicksShow) {
            yield put({type: FETCH_ALL_RTDM_CREOTECH_TICKS_RQ});
        }
    }
}

function* handleDisplayPrefsChange({ type, payload }) {

    console.log('on handle display prefs change', type, payload);

    const rtdmCreotechTicks = yield select(getRtdmCreotechTicks);

    const mapUxConfig = yield select(getMapUxConfig);
    const inspectData = yield select(getInspectData);


    const {bottomCutoff, topCutoff} = (isPandora)? mapUxConfig.trafficHeightFilter:mapUxConfig.heightFilter;

    const nextPayload = {
        rawData:rtdmCreotechTicks.rawData,
        currentInspectData:inspectData,
        meta:{
            bottomCutoff,
            topCutoff
        }
    };

    yield put({ type: STORE_ALL_RTDM_CREOTECH_TICKS, payload:nextPayload});

}

export default function* () {

    const fx = [
        takeEvery(MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ, handleEnableToggle),
        takeEvery(FETCH_ALL_RTDM_CREOTECH_TICKS_RQ, handleFetch),
        takeEvery(TIMER_TICK_RQ, handleTick),
    ];

    if (isPandora) {
        fx.push(takeEvery(PANDORA_TRAFFIC_HEIGHT_FILTER, handleDisplayPrefsChange))
    } else {
        fx.push(takeEvery(MAP_UI_HEIGHT_FILTER, handleDisplayPrefsChange))
    }

    yield all(fx);


}
