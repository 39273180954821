import {FormSection, getFormSyncErrors} from "redux-form";
import React from "react";
import Tree from "react-animated-tree";
import {AcmTermsTextInput} from "./AcmTermsTextInput";
import {ACM_TERMS_BRANCH, ACM_TERMS_END_NODE} from "../../../../LogicV1Redux/constants/AcmRulesConstants";
import {translate} from 'react-admin'
import compose from 'recompose/compose';
import {RESOURCES} from "../../../logic/MyRestConfig";
import {FactoryRPA} from "../../../../rt_map_ra2/components/utils/rpaColors";
import {connect} from "react-redux";
import {has} from "lodash";
import Icon from "@material-ui/core/Icon";

const AcmTermsTreeEndNode = ({source, rpaType}) =>
    <Tree content={<AcmTermsTextInput source={source} rpaType={rpaType}/>}
          type={ACM_TERMS_END_NODE}
    />;



export const AcmTermsInputBranch = compose(
    translate,
    connect(
        (state, ownProps) => ({
            formSyncErrors: getFormSyncErrors(ownProps.formName)(state),
        })
    )
)(
    ({style, source, translate, isDefined, isNotEmpty, formSyncErrors, langs = []}) =>
    {
        const iconStyle = {
            color: source !== 'def' ? FactoryRPA(source.toUpperCase()).toString() : '#0008',
            fontSize: '2rem',
            verticalAlign: 'middle'
        };

        const errorIconStyle = {
            color: "#F00",
            verticalAlign: 'bottom'
        };

        const rpaAreaIcon = <span style={iconStyle}>&#x25C9;&nbsp;</span>;
        const titleTranslation = translate(`resources.${RESOURCES.ACM_RULES}.fields.etc.terms.${source}`);
        const validationErrorIcon = has(formSyncErrors, `etc.terms.${source}`) ? <>&nbsp;<Icon style={errorIconStyle}>error</Icon></> : null;

        let title = isDefined ?
            <span style={{fontWeight: "500"}}>{titleTranslation}</span>
            :
            <span style={{color: isNotEmpty ? 'inherit' : '#0008', textDecoration: isNotEmpty ? 'inherit' : "line-through"}}>{titleTranslation}</span>;

        return (
            <FormSection name={source}>
                <Tree type={ACM_TERMS_BRANCH} content={<>{rpaAreaIcon}{title}{validationErrorIcon}</>} style={style}>
                    {
                        langs.map(lang => <AcmTermsTreeEndNode source={lang} rpaType={source} key={lang}/>)
                    }

                </Tree>
            </FormSection>
        )
    }
);

