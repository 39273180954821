import axios from "axios";
import {urls} from "../../Configs";
import StoreProvider from "../../dr_ra2/MyStoreProvider";
import {storeLoopbackVersion} from "../actions/MyAppExtrasActions";


export function requestLoopbackVersion() {

    const promise = axios(urls.loopbackVersionUrl, {
        method: 'get',
    });

    return promise.then(response => {
        //logger.debug('axios response', response);
        const store = StoreProvider.getStore();
        console.log('getLoopbackVersion axios response', response);
        store.dispatch(storeLoopbackVersion(response.data));
        //currentUser = response.data;
        return Promise.resolve('success');
    })
        .catch(function (error) {
            //console.dir(promise);
            console.error('getLoopbackVersion axios error', error);
            return Promise.reject('axios error')
        })

}
