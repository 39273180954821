import axios from 'axios';

import {API_VERBS} from './MyApiVerbs';

import storeProvider from './MyStoreProvider';
import {throwLoopbackError} from "./utils/axiosLoopbackErrors"
import {isSuperAdmin} from "./utils/tempCommon";
import {encodeQuery} from './utils/tempCommon';
import { stringify } from 'query-string';
import {defaultLocale} from "../Configs";
import {fixFileName, fixModelId, fixReferenceId, fixUserId} from '../LogicV1Redux/oldLogic/LoopbackUtils'

//import {AUTH_REGISTER, AUTH_RESETPASSDATA, AUTH_RESETPASSRQ} from "../src_from_v1/auth/MyAuthActions";

//TODO replace encodeQuery calls with query-string!! (added by reactadmin_)
/**
 * Maps admin-on-rest queries to a loopback powered REST API
 *
 * @see https://github.com/strongloop/loopback
 * @example
 * GET_LIST     => GET http://my.api.url/posts?filter[sort]="title ASC"&filter[skip]=0&filter[limit]=20
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter[where][or]=[{id:123},{id:456}]
 * UPDATE       => PATCH http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */


const {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,

    LB_GET_ONE,
    COUNT,
    LB_GET_MANY,
    LB_GET_MANY_REFERENCE,
    LB_CREATE,
    LB_DELETE,
    LB_UPDATE,

    UPLOAD,
    DOWNLOAD,
    PATCH,
    LOADSELF,
    REQUEST_NICKNAMES,
    SAVESELF,
    PATCH_PHONE,
    VERIFY_PHONE,
    LOGIN,
    NEWPASSRQ,
    RESETPASSRQ,
    REGISTER,
    REGISTERCONFIRM,
    PUT_NICKNAME,
    GOV_ON_OPERATOR_UPDATE,
    MANAGE_BAN_OPERATOR,
    RESEND_VERIFICATION_EMAIL_GOV,
    RESEND_VERIFICATION_EMAIL_OPERATOR,

} = API_VERBS;

//based on aor rest client see -> /node_modules/aor-loopback/src/index.js
//changing apiUrl to apiUrlFn -> dynamically getting url....
//hacking id => uid
//and a lot more.

//hmm works in conjuction w/auth...
//not exactly nicest store approach

// imho for clarity should be rewritten to singleton with init phase,
// and required client function should be returned by instance, not as lambda
// but as usually ... later. later..

// 15.09.18 hmm what? no idea what i meant...

// ad 2-phase upload...
// due to yet another hmm change, files are no longer uploaded to different endpoint, but
// rather passed as an encoded array (jsonb) in single field..
// keeping old code, as it won't break anything , and who knows when we'll need multiple uploads again..


export default ({apiBase, superAdminTweaks, defaultREST,}) => {

    let authFromState, localeFromState;

    //helper for uid->id fix
    const modelsUsingUIDs = ['operators', 'govs', 'units', 'unitGovs', 'drones', 'dronesArchive', 'certs', 'certsArchive','missions', 'zones', 'pois', 'faas', 'notams', 'notamActivations',
        'unitsAll' //temp -> special case (fake resource) for units all panel for debug for govs
    ];

    const isModelUsingUID = (resource) => (modelsUsingUIDs.indexOf(resource) !== -1);

    const isModelWithFiles = () => {return false}; //was using 2-phase upload, defined per resource

    //was required for 2-phase upload to hardcoded endpoint
    const hardcodedContainers = 'containers';
    //const isContainer = (resource) => (resource === 'containers');
    const isContainer = () => {return false};

    const isUploadPhaseRequired = (resource, type, params) => {

        const firstCheck = isModelWithFiles(resource)
            && (type === UPDATE || type === CREATE);
        if (!firstCheck) return false;
        const secondCheck = (params.data.etc
            && params.data.etc.files
            && params.data.etc.files.length > 0);
        if (!secondCheck) return false;

        console.log('isUploadPhaseRequired', params.data.etc);

        let fixedArr = [];
        let files = params.data.etc.files;
        let hasNewFiles = false;
        for (let i = 0; i < files.length; i++) {
            console.log('precompare', files[i]);
            if (!files[i].rawFile) {
                //fixme?. there is slight issue with processing src/title in upload component (file input preview to be precise)..
                //so hackin it now.
                let dbData = (files[i].__dbData)?files[i].__dbData:files[i];
                fixedArr.push(dbData);
            } else {
                hasNewFiles = true;
            }
        }
        params.data.etc.files = files;

        return hasNewFiles;
    };
    /**
     * @param {String} verb One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (verb, resource, params) => {

        console.log(`convertRESTRequestToHTTP ${verb} / ${resource} in:`, params);

        //resource = resource.toLowerCase();
        let finalUrl = '';
        const options = { headers : {}};
        const extras = {};
        //console.warn('fixed lbtoken storage load', authCp);
        //const lbToken = storage.load('lbtoken');
        if (authFromState && authFromState.token) {
            options.headers = {Authorization: authFromState.token};
        } else {
            console.log('convertRESTRequestToHTTP -> no auth in state....');
        }

        //verbs to review if can be unified into more RA/CRUD way, but after whole stuff is working and accepted
        switch (verb) {
            case GET_LIST: {
                const {page, perPage} = params.pagination || {};
                let {field, order} = params.sort || {};
                let query = {};
                //temp? hackin containers
                if (!isContainer(resource)) {

                    query['where'] = {...params.filter};
                    if (field) {
                        if (field === 'id' && isModelUsingUID(resource)) {
                            //console.log('hacking id => uid');
                            field = 'uid';
                        }
                        query['order'] = [field + ' ' + order];
                    }
                    if (perPage && perPage > 0) {
                        query['limit'] = perPage;
                        if (page >= 0) {
                            query['skip'] = (page - 1) * perPage;
                        }
                    }
                }

                if (Object.keys(query['where']).length === 0) {
                    //error in endpoint (units for govs)
                    delete query.where;
                }


                //let t = `?${queryParams({filter: JSON.stringify(query)})}`;
                //console.warn('disabling paging and filter temporarily', query);
                //console.log ('query', query);
                //url = `${apiUrlFn()}/${resource}?${queryParameters({filter: JSON.stringify(query)})}`;
                const {url, method, customFilter} = apiUrlFn({resource, verb:LB_GET_MANY});
                if (!url) return null;

                //different than GET_ONE?
                if (customFilter) {
                    //probably wrong mixing!
                    const origQ = {...query};
                    query = { ...customFilter, ...query};

                    if (Object.keys(customFilter).length !== 0
                        && Object.keys(query).length !==0 ) {
                        console.warn('rff untested query mixing');
                        console.log('rff mix in', customFilter, origQ);
                        console.log('rff mix out', query);

                    }
                }

                console.log(GET_LIST, query);

                finalUrl = `${url}?${encodeQuery({filter: JSON.stringify(query)})}`;
                options.method = method;

                break;
            }
            case GET_ONE: {
                //customizing call -> for now for missions (see restConfig table)
                const {url, method, customFilter} = apiUrlFn({resource, verb:LB_GET_ONE});
                if (!url) return null;

                //btw -> gets bit dirty.. again...
                //to refactor when filters and new custom points will be finished
                if (customFilter) {
                    let fixedUrl = fixModelId(params.id, url); //hmm not needed .. at the moment :)
                    let fixedFilter = fixModelId(params.id, JSON.stringify(customFilter));
                    console.log(GET_ONE, 'fixedFilter', fixedFilter);
                    finalUrl = `${fixedUrl}?${encodeQuery({filter: fixedFilter})}`;
                }
                else
                {
                    finalUrl = fixModelId(params.id, url);
                }
                options.method = method;

                break;
            }
            case GET_MANY: {
                //fixme? params from query to data? (long uids)
                const idOrUid = (isModelUsingUID(resource))?'uid':'id';
                const listId = params.ids.map(id => {
                    return {[idOrUid]: id};
                });
                const query = {
                    'where': {'or': listId}
                };
                //url = `${apiUrlFn()}/${resource}?${queryParameters({filter: JSON.stringify(query)})}`;
                const {url, method} = apiUrlFn({resource, verb:LB_GET_MANY});
                if (!url) return null;

                finalUrl = `${url}?${encodeQuery({filter: JSON.stringify(query)})}`;
                options.method = method;

                break;
            }
            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                let {field, order} = params.sort;
                const query = {};
                query['where'] = {...params.filter};
                query['where'][params.target] = params.id;
                if (field) {
                    if (field === 'id' && isModelUsingUID(resource)) {
                        console.log('hacking id => uid');
                        field = 'uid';
                    }
                    query['order'] = [field + ' ' + order];
                }
                if (perPage > 0) {
                    query['limit'] = perPage;
                    if (page >= 0) {
                        query['skip'] = (page - 1) * perPage;
                    }
                }
                //url = `${apiUrlFn()}/${resource}?${queryParameters({filter: JSON.stringify(query)})}`;
                const {url, method} = apiUrlFn({resource, verb:LB_GET_MANY_REFERENCE});
                if (!url) return null;

                finalUrl = `${url}?${encodeQuery({filter: JSON.stringify(query)})}`;
                options.method = method;
                break;
            }
            case UPDATE: {
                const {url, method} = apiUrlFn({resource, verb:LB_UPDATE});
                if (!url) return null;

                finalUrl = fixModelId(params.id, url);
                options.method = method;
                options.data = params.data;
                break;
            }
            case CREATE: {
                const {url, method} = apiUrlFn({resource, verb:LB_CREATE});
                if (!url) return null;

                console.log(CREATE, 'params', params);
                finalUrl = fixReferenceId(params.id, url);
                options.method = method;
                options.data = params.data;
                break;
            }
            case DELETE: {
                const {url, method} = apiUrlFn({resource, verb:LB_DELETE});
                if (!url) return null;

                finalUrl = fixModelId(params.id, url);
                options.method = method;
                break;
            }
            //hacks,
            case COUNT: {
                const {url, method, customFilter} = apiUrlFn({resource, verb});
                if (!url) return null;

                //if used - should be passed from get_list call,
                if (params.filter && Object.keys(params.filter).length > 0) {
                    //filter in lb count is already defined as WHERE (see explorer)
                    //finalUrl = `${url}?${encodeQuery({filter: JSON.stringify({where: params.filter})})}`;
                    finalUrl = `${url}?${encodeQuery({where: JSON.stringify(params.filter)})}`;
                    console.log(COUNT, 'params', params.filter);

                } else if(customFilter){
                    finalUrl = `${url}?where=${JSON.stringify(customFilter.where)}`;
                    console.log(COUNT, 'no filter');
                }else{
                    finalUrl = url
                }

                options.method = method;
                break;
            }
            case PATCH: {
                console.warn("looks unused! API VERB", PATCH);

                options.method = 'PATCH';
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                //err.
                finalUrl = `${apiUrlFn({resource, verb})}/${params.id}`;
                console.log(PATCH, 'params', params.data);
                options.data = params.data;

                break;
            }
            case UPLOAD: {
                //console.warn(`to review new paths, ${UPLOAD}`);
                console.log(UPLOAD, 'params', params.data);

                const {url, method} = apiUrlFn({resource: hardcodedContainers, verb});
                if (!url) return null;

                finalUrl = url;
                options.method = method;
                options.headers['content-type'] = 'multipart/form-data';

                let data = new FormData();
                let files = params.data.etc.files;
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    console.log('file' + i, file);
                    if (file.rawFile)
                        data.append('files[' + i + ']', file.rawFile, file.rawFile.name);
                }

                options.data = data;
                console.log(UPLOAD, 'result', options.data);
                break;
            }
            //custom endpoints to fix operator/gov setup
            case LOADSELF:
            case REQUEST_NICKNAMES: {
                const {url, method, customFilter} = apiUrlFn({resource, verb});
                if (!url) return null;

                if (customFilter) {
                    console.warn(`${LOADSELF}/ ${REQUEST_NICKNAMES} using customFilter!!`, customFilter);
                    finalUrl = `${url}?${encodeQuery({filter: JSON.stringify(customFilter)})}`;
                    //finalUrl = url;
                } else {
                    finalUrl = url;
                }
                //console.log('SELF params', params);
                options.method = method;
                options.data = params.data;
                break;
            }
            //same as update?
            case SAVESELF:
            case PUT_NICKNAME:
            case RESEND_VERIFICATION_EMAIL_OPERATOR:
            case RESEND_VERIFICATION_EMAIL_GOV:
            case MANAGE_BAN_OPERATOR:
            case GOV_ON_OPERATOR_UPDATE: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                finalUrl = fixModelId(params.id, url);
                options.method = method;
                options.data = params.data;
                break;
            }
            case PATCH_PHONE: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                finalUrl = url;
                options.method = 'POST';
                console.log(PATCH_PHONE, 'params', params.data);
                options.data = params.data;
                break;}
            case VERIFY_PHONE: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                finalUrl = url;
                options.method = 'POST';
                console.log(VERIFY_PHONE, 'params', params.data);
                options.data = {phoneCode: params.data.phoneCode};
                break;}
            case LOGIN: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                finalUrl = url;
                console.log(LOGIN, 'params', params);
                options.method = method;
                options.data = params.data;
                break;
            }
            case NEWPASSRQ: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                console.log(NEWPASSRQ, 'params', params);
                console.warn('===fix locale from state (hardcoded'); //probably done, to check out
                //dang, Jasiek
                //po pol roku -> chodzilo zapewne o x-www-form-urlencoded,, ale nie jestem pewien na teraz

                //curl -X POST --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: text/html' -d 'newPassword=cqq' 'https://api.droneradar.xyz/v2/Operators/reset-password?access_token=TxqUZNFXVWPUGGDLeLIZDPJj4An7AEKHfjvNoR1dzrRxw2w0lm4jiS96w91NRrYb'


                // curl 'https://api.droneradar.xyz/v2/Operators/reset-password?access_token=TxqUZNFXVWPUGGDLeLIZDPJj4An7AEKHfjvNoR1dzrRxw2w0lm4jiS96w91NRrYb' -H 'Accept: application/json, text/plain, */*' -H 'Referer: http://localhost:3000/'  -H 'Content-Type: application/x-www-form-urlencoded' --data '{"newPassword":"bqq","retype":"bqq","access_token":"TxqUZNFXVWPUGGDLeLIZDPJj4An7AEKHfjvNoR1dzrRxw2w0lm4jiS96w91NRrYb","lang":"pl"}'

                // options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                // options.headers['Accept'] = 'text/html';

                finalUrl = `${url}?access_token=${params.data.access_token}`;

                options.method = method;
                // options.data = `newPassword=${params.data.newPassword}`;
                options.data = {newPassword: params.data.newPassword};

                //options.data.lang = localeFromState; //wysyla mail czy nie?

                break;
            }
            case RESETPASSRQ: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                finalUrl = url;

                console.log(RESETPASSRQ, 'params', params);
                console.warn('===fix locale from state (hardcoded'); //probably done, to check out
                options.method = method;
                options.data = params.data;
                options.data.lang = localeFromState;

                break;
            }
            case REGISTER: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                finalUrl = url;

                console.log(REGISTER, 'params', params);
                console.warn('===fix locale from state (hardcoded'); //probably done, to check out

                options.method = method;
                options.data = params.data;
                options.data.lang = localeFromState;

                break;
            }
            case REGISTERCONFIRM: {
                const {url, method} = apiUrlFn({resource, verb});
                if (!url) return null;

                finalUrl = `${url}?${stringify(params.data)}`;

                console.log(REGISTERCONFIRM, {params, finalUrl});
                //console.warn('===fix locale from state (hardcoded');
                options.method = method;
                //options.data = params.data;
                //options.data.lang = localeFromState;

                break;
            }
            default:
                //add missing

                console.warn(`Unsupported fetch action type ${verb}`);
                return null;
                //throw new Error(`Unsupported fetch action type ${verb}`);
        }

        console.warn(`convertRESTRequestToHTTP out:`, finalUrl, options);

        return {url: finalUrl, options, extras};
    };

    /**
     * @param {Object} response HTTP response from axios()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @param {int} xCount optional count retrieved earlier (hacking server setup)
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params, xCount) => {
        //const {headers, json} = response;
        console.log('convertHTTPResponseToREST', response, type, resource, params, xCount);

        const transformFilesFromDB = (record) => {
            if (record.etc && record.etc.files) {
                console.warn("to review new paths, transformFilesFromDB");

                //fixme! unsafe approach
                let authString = '';
                if (authFromState && authFromState.token) {
                    authString = `?access_token=${authFromState.token}`;
                }

                let files = record.etc.files;
                for (let i=0; i < files.length; i++) {
                    if (!files[i].__dbData) {
                        let dbData = files[i];
                        let {url, method} = apiUrlFn({resource:hardcodedContainers, verb:DOWNLOAD, });
                        let src = `${fixFileName(dbData.name, url)}${authString}`;
                        console.log('=========src', src);
                        files[i] = {
                            __dbData: dbData,
                            title: (dbData.originalFilename) ? dbData.originalFilename : dbData.name,
                            src
                            //was
                            //src: `${apiUrlFn(hardcodedContainers)}/download/${dbData.name}${authString}`
                        }
                    }
                }
            }
            return record;
        };

        switch (type) {
            case GET_MANY_REFERENCE:
                //throw new Error('GET_MANY_REFERENCE'); //was combined w get list, but when it is called acutally
                //break;
            case GET_MANY:
            case GET_LIST:
                //fixing uid/id
                //console.log('GET_LIST here', resource, response);
                const retGL = response.data.map((x) => { return { ...transformFilesFromDB(x), id: (x.uid)?x.uid:x.id } });

                let ret = {data:retGL};

                if (type === GET_LIST) {
                    ret.total =  xCount
                }
                else ret.total = retGL.length;

                return ret;

            case UPDATE:
            case DELETE:
            case GET_ONE:
                //supporting 1-length array as response (customFilter calls)
                const fixedResponse = (Array.isArray(response.data))?response.data[0]:response.data;

                const retGO = { data: { ...transformFilesFromDB(fixedResponse), id: (fixedResponse.uid)?fixedResponse.uid:fixedResponse.id }};
                //console.log('get_one ret', retGO);
                return retGO;
            case CREATE:
                const retC = { data: { ...transformFilesFromDB(response.data), id: (response.data.uid)?response.data.uid:response.data.id } };
                //console.log('create ret', response.data, retC);

                return retC;
            case REQUEST_NICKNAMES:
                const retN = { data: response.data };
                return retN;
            default:
                return { data: { ...transformFilesFromDB(response.data), id: (response.data.uid)?response.data.uid:response.data.id } };
        }
    };


    //function to fix dynamically urls
    const apiUrlFn = ({resource, verb}) => {
        //console.warn('fixed lbtoken -> aka set authid', authCp);
        console.log(`apiUrlFn call for : ${verb} ${resource} `);

        console.log('defaultRest', defaultREST);
        //console.log('superAdminTweaks', superAdminTweaks);

        try {

            if (!(defaultREST[resource] || defaultREST[resource][verb])) {
                console.warn('no match in RestConfig, throwing error here', defaultREST);
            }

            let {url, method, anonAccess, customFilter} = defaultREST[resource][verb];

            const userId = (authFromState && authFromState.userId) ? authFromState.userId : undefined;

            //let's keep it double checked for now,
            //auth flow still to finish
            if (!(userId || anonAccess)) {
                console.warn('no user id, throwing error here');
                return {};
                //throw new Error('no user id set');
            }

            //todo fix checks, superadmin eval, and more?
            //buu -> hardcoded in common
            if (isSuperAdmin()) {
                const resourceOverride = (superAdminTweaks[resource]) ? superAdminTweaks[resource][verb] : false;
                if (resourceOverride) {
                    url = resourceOverride.url;
                    method = resourceOverride.method;
                    customFilter = resourceOverride.customFilter;
                }
            }

            if (!(url && method)) {
                console.warn('no match in RestConfig, throwing error here', url, method);
                return {};
                //throw new Error('no user id set');
            }

            url = apiBase + fixUserId(userId, url);

            console.log(`--->url:${method} ${url}`);

            return {url, method, customFilter};

        } catch (e) {
            console.warn('silent error in apiUrlFn', e)
        }


    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts" //!STRING. so no workarounds per resource
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */

    //fixme -> move custom conversions to convert.
    return (type, resource, params) => {

        console.log('===my rest client', type, resource, params)

        const store = storeProvider.getStore();
        const state = store.getState();
        //console.warn('store in myrestclient', store, state);
        authFromState = state.myAuth;
        localeFromState = defaultLocale; //FIXME
        console.warn('myrestClient call', type, resource, params, authFromState);

        const http = convertRESTRequestToHTTP(type, resource, params);

        if (!http) {
            console.error('REST_TYPE_ERROR', type, resource, params);
            return Promise.reject({ type:"REST_TYPE_ERROR", restRqType:type, resource, params});
        }

        const {url, options, extras} = http;

        let promise, xCount;

        //paged list -> but no count on containers! count itself 2-phased
        if (!isContainer(resource) && (type === GET_LIST) && params && !params.skipCount) {

            const countHack = convertRESTRequestToHTTP(COUNT, resource, params);
            console.warn(COUNT, countHack.url, countHack.options, );

            //OPTIMIZE TESTS
            // xCount = -1;
            // promise = axios(url, options);
            promise = axios(countHack.url, countHack.options, countHack.extras)
                .then((response) => {
                    console.log('list count received '+response.data.count);
                    xCount = response.data.count;
                    return axios(url, options);
                })
                .catch((err) => {
                    throwLoopbackError(err);
                });

        }
        //2-phase upload
        else if (isUploadPhaseRequired(resource, type, params)) {
            const uploadsHack = convertRESTRequestToHTTP(UPLOAD, hardcodedContainers, params);
            console.warn('uploadsHack', uploadsHack.url, uploadsHack.options);

            promise = axios(uploadsHack.url, uploadsHack.options)
                .then((response) => {
                    console.log('uploads finished', response, options);
                    //xCount = response.data.count;
                    //data.etc.files = response;
                    //return axios(url, options);
                    let cleanedArr = [];

                    let files = options.data.etc.files;

                    //filter not uploadable
                    for (let i = 0; i < files.length; i++) {
                        console.log('precompare', files[i]);
                        if (!files[i].rawFile) {
                            cleanedArr.push(files[i]);
                        }
                    }

                    Object.entries(response.data.result.files).forEach(
                        ([key, value]) => {
                            console.log(`key: ${key}, value:`, value);
                            let ret = value[0];
                            //fixme -> optimize data more? maybe later
                            delete ret.field;
                            cleanedArr.push(ret);
                        }
                    );
                    options.data.etc.files = cleanedArr;

                    return axios(url, options);
                })
                .catch((err) => {
                    throwLoopbackError(err);
                });
        }
        else {
            promise = axios(url, options);
        }

        console.warn('axios promise', promise);

        return promise
            .then(response => {
                console.warn('axios response & xCount ', response, xCount);

                const ret = convertHTTPResponseToREST(response, type, resource, params, xCount);
                console.warn ('conversion result', ret);
                return ret;
            })
            .catch((err) => {
                throwLoopbackError(err);
            });

    };
};


