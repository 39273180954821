import {STORE_SOCKET_DATA} from "../constants/SocketConstants";


const initialState = {};

const socketReducer = (previousState = initialState, { type, payload }) => {

    switch (type) {
        case STORE_SOCKET_DATA:
            return {...previousState, ...payload };

        default:
            return previousState;
    }
};

export default socketReducer;
