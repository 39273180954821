

import {
    createGenerateClassName,
} from '@material-ui/core-v3/styles';

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: false,
    productionPrefix: 'c',
});

export default generateClassName;

//customized src - not working
//https://github.com/mui-org/material-ui/blob/2361339fd6df9bfbb85ed2ee4da9d42ee6fee71c/src/styles/createGenerateClassName.js#L26-L28
//modified from ts

// import warning from 'warning';
//
// import JssProvider from 'react-jss/lib/JssProvider';
/*
// Returns a function which generates unique class names based on counters.
// When new generator function is created, rule counter is reset.
// We need to reset the rule counter for SSR for each request.
//
// It's an improved version of
// https://github.com/cssinjs/jss/blob/4e6a05dd3f7b6572fdd3ab216861d9e446c20331/src/utils/createGenerateClassName.js
export default function createGenerateClassName() {
    let ruleCounter = 0;

    return (rule, sheet)=> {
        ruleCounter += 1;
        warning(
            ruleCounter < 1e10,
            [
                'Material-UI: you might have a memory leak.',
                'The ruleCounter is not supposed to grow that much.',
            ].join(''),
        );

        // if (process.env.NODE_ENV === 'production') {
        //     return `c${ruleCounter}`;
        // }

        if (sheet && sheet.options.meta) {
            return `${sheet.options.meta}-${rule.key}-${ruleCounter}`;
        }

        return `${rule.key}-${ruleCounter}`;
    };
}

*/
