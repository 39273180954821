import { all, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_APPLICATION,
    SAVE_APPLICATION_STATUS,
    SAVE_APPLICATION_STATUS_AND_UPDATE
} from "../constants/ApplicationsConstants";
import {LOADING_END, LOADING_START} from "../../dr_ra2/logic/ui/MyUiActions";
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import DataProvider from "../../dr_ra2/MyDataProvider";
import { showNotification, refreshView } from 'react-admin';
import { push } from 'react-router-redux';
import {CaaApplicationStatuses} from "../constants/CaaApplicationEnums";
import moment from 'moment';
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

/**
 * @param type
 * @param payload {[uid],[status],[resource],[redirect]}
 * @returns {IterableIterator<*>}
 */
function* handleStatusSave({ type, payload }) {

    const newStatus = payload.status;
    // feature from 1.2, in 1.1 this will be undefined so no feature switch here
    const {govMsgs, userMsgs} = payload;

    const data = {status: newStatus, govMsgs, userMsgs};
    const isCaa =
        newStatus === CaaApplicationStatuses.REJECTED ||
        newStatus === CaaApplicationStatuses.ACCEPTED ||
        newStatus === CaaApplicationStatuses.HOLD_MEETING_REQUEST;

    //currently passed from action...
    //could be connected directly to store myself/appextras,
    //see missions

    data.validated = null;

    if (isCaa) {
        data.etc = (payload.etc)?payload.etc:{}; //hacking missing public govs data (email)
        //removing hack for madrid
        //todo check if is passing etc still required? probably yes
        //data.etc.modified = unixTimeNow();

        if (payload.govid) {
            data.govid = payload.govid; //hacking missing public govs data (email)
        }
        data.validated = moment().format();
    } else {
        data.etc = (payload.etc)?payload.etc:{}; //hacking missing public govs data (email)
        //removing hack for madrid
        //todo check if is passing etc still required? probably yes
        //data.etc.modified = unixTimeNow();

    }

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage:
            isCaa
            ? 'resources.applications.notifications.applicationChangingStatus'
            : 'resources.applications.notifications.applicationSending'
    });

    try {

        console.log('==========verifyStatus save here', type, payload);

        let applicationId = payload.uid;

        const dataProvider = DataProvider.getDataProvider();
        yield dataProvider(API_VERBS.UPDATE, payload.resource, {id: applicationId, data});


        yield put(showNotification(
            isCaa
            ? 'resources.applications.notifications.applicationChangeStatusMsg'
            : 'resources.applications.notifications.applicationSentMsg'
            , 'info'));

        yield put({ type: FETCH_END });
        yield put(push(payload.redirect)); //for now going to dashbord, redirect should be overridable, in meta??
        yield put({type: LOADING_END});

    } catch (error) {
        yield put(showNotification(
            isCaa
            ? 'resources.applications.notifications.applicationChangeStatusFailMsg'
            : 'resources.applications.notifications.applicationSentFailMsg'
            , 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});
    }
}

/**
 * @param type
 * @param payload {[uid],[status],[resource],[redirect]}
 * @returns {IterableIterator<*>}
 */
function* handleStatusSaveWithUpdate({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'myroot.message.saving'
    });

    try {

        console.log('========= status with update save here', type, payload);

        let applicationId = payload.uid;

        //status should be null?
        const data = {...payload.values, status: payload.status, etc: {...payload.values.etc, diffs: payload.diffs}};
        //removing hack for madrid
        //todo check if is passing etc still required? probably yes
        //data.etc.modified = unixTimeNow();

        //should be backend
        data.validated = null;

        const dataProvider = DataProvider.getDataProvider();
        yield dataProvider(API_VERBS.UPDATE, payload.resource, {id: applicationId, data});


        yield put(showNotification('ra.notification.updated', 'info'));

        yield put({ type: FETCH_END });
        yield put(push(payload.redirect)); //for now going to dashbord, redirect should be overridable, in meta??
        yield put(refreshView());
        yield put({type: LOADING_END});

    } catch (error) {
        yield put(showNotification('ra.message.error', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});
    }
}

/**
 * @param type
 * @param payload {[uid],[status],[resource],[redirect]}
 * @returns {IterableIterator<*>}
 */
function* handleCreateApplication({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'myroot.message.saving'
    });

    try {

        console.log('========= create application here', type, payload);

        const dataProvider = DataProvider.getDataProvider();
        const applicationSaved = yield dataProvider(API_VERBS.CREATE, payload.resource,
            {
                data: {
                    ...payload.record,
                    status: CaaApplicationStatuses.CREATED
                }
            });

        const applicationId = applicationSaved.data.uid;

        yield put(showNotification('ra.notification.updated', 'info'));

        yield put({ type: FETCH_END });
        yield put(push(`/${payload.resource}/${applicationId}/edit`));
        yield put(refreshView());
        yield put({type: LOADING_END});

    } catch (error) {
        yield put(showNotification('ra.message.error', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});
    }
}


export default function* () {

    yield all([
        takeEvery(SAVE_APPLICATION_STATUS, handleStatusSave),
        takeEvery(SAVE_APPLICATION_STATUS_AND_UPDATE, handleStatusSaveWithUpdate),
        takeEvery(CREATE_APPLICATION, handleCreateApplication),
    ])
}
