import { all, put,  takeEvery, select, call } from 'redux-saga/effects';

import {
    MAP_SETUP_RQ,
    MAP_ALERTS_V1_ENABLE_RQ,
    MAP_ALERTS_V2_ENABLE_RQ,
    MAP_CHECKINS_V1_ENABLE_RQ,
    MAP_CHECKINS_V2_ENABLE_RQ,
    MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ,
    MAP_LPR_HELIPADS_V1_ENABLE_RQ,
    MAP_MISSIONS_V2_ENABLE_RQ,
    MAP_RT_MAP_ZONES_V1_ENABLE_RQ,
    MAP_ZONES_V2_ENABLE_RQ,
    MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ,
    MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ,
    MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ,
    MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ,
    MAP_TICK_ENABLED,

    MAP_UI_RTDM_CREOTECH_TICKS_SHOW,
    MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW,
    MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW,
    MAP_UI_RTDM_EXCHANGE_TICKS_SHOW,
    PANDORA_TRAFFIC_ENABLED,
    PANDORA_LPR_TRAFFIC_ENABLED,
    PANDORA_CREOTECH_TRAFFIC_ENABLED,
    PANDORA_TRAFFIC_HEIGHT_FILTER
    //MAP_ZONES_V2_ENABLED,
    //MAP_UI_ZONES_V2_SHOW,

} from "../constants/MapUxConfigConstants";
import {handleFetch as fetchZoneTypes} from './ZoneTypesV2Sagas'

const getMyAppExtras = (state) => state.myAppExtras;

const getMapUxConfig = (state) => state.mapUxConfig;

const l = console;

//shorthand for config..
function* handleMapSetup({ type, payload }) {

    const prevMapUxConfig = yield select(getMapUxConfig);
    const myAppExtras = yield select(getMyAppExtras);

    yield* fetchZoneTypes({});

    console.warn('(re) initing map setup here', payload);

    if (payload.lprHelipadsV1) {
        yield put({type: MAP_LPR_HELIPADS_V1_ENABLE_RQ, payload: payload.lprHelipadsV1});
    }

    if (payload.alertsV1) {
        yield put({type: MAP_ALERTS_V1_ENABLE_RQ, payload: payload.alertsV1});
    }
    if (payload.alertsV2) {
        yield put({type: MAP_ALERTS_V2_ENABLE_RQ, payload: payload.alertsV2});
    }
    if (payload.checkinsV1) {
        yield put({type: MAP_CHECKINS_V1_ENABLE_RQ, payload: payload.checkinsV1});
    }
    if (payload.checkinsV2) {
        yield put({type: MAP_CHECKINS_V2_ENABLE_RQ, payload: payload.checkinsV2});
    }
    if (payload.rtMapZonesV1) {
        yield put({type: MAP_RT_MAP_ZONES_V1_ENABLE_RQ, payload: payload.rtMapZonesV1});
    }
    if (payload.zonesV2) {
        yield put({type: MAP_ZONES_V2_ENABLE_RQ, payload: payload.zonesV2});
    }
    if (payload.missionsV2) {
        yield put({type: MAP_MISSIONS_V2_ENABLE_RQ, payload: payload.missionsV2});
    }
    if (payload.liveAbsbExchange) {
        yield put({type: MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ, payload: payload.liveAbsbExchange});
    }
    if (payload.rtdmCreotechTicks) {
        yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ, payload: payload.rtdmCreotechTicks});
    }
    if (payload.rtdmSoapLprUnitsTicks) {
        yield put({type: MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ, payload: payload.rtdmSoapLprUnitsTicks});
    }
    if (payload.rtdmExchangeAircrafts) {
        yield put({type: MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ, payload: payload.rtdmExchangeAircrafts});
    }
    if (payload.rtdmExchangeTicks) {
        yield put({type: MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ, payload: payload.rtdmExchangeTicks});
    }
    if (payload.tickUpdate) {
        yield put({type: MAP_TICK_ENABLED, payload: payload.tickUpdate});
    }
}

//see atcMap.jsx init
// rtdmExchangeTicks: pandoraTrafficEnabled,
// rtdmCreotechTicks: pandoraTrafficEnabled,
// rtdmExchangeAircrafts: pandoraTrafficEnabled,
// rtdmSoapLprUnitsTicks: pandoraTrafficEnabled,
//master pandora switch. used once a time, so no optimization needed
function* handlePandoraTrafficEnabled({ type, payload }) {
    //aargh, sprint, config persisted, disabling for now. unused anyway.
    l.warn('!!!disabled master PANDORA_TRAFFIC_ENABLED, payload:', payload);

    // if (payload) {
    //     yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ, payload:true});
    //     yield put({type: MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ, payload:true});
    //     yield put({type: MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ, payload:true});
    //     yield put({type: MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ, payload:true});
    //     yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:true});
    //     yield put({type: MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW, payload:true});
    //     yield put({type: MAP_UI_RTDM_EXCHANGE_TICKS_SHOW, payload:true});
    //     yield put({type: MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW, payload:true});
    //
    //
    // } else {
    //     yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ, payload:false});
    //     yield put({type: MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ, payload:false});
    //     yield put({type: MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ, payload:false});
    //     yield put({type: MAP_RTDM_EXCHANGE_AIRCRAFTS_ENABLE_RQ, payload:false});
    //     yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:false});
    //     yield put({type: MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW, payload:false});
    //     yield put({type: MAP_UI_RTDM_EXCHANGE_TICKS_SHOW, payload:false});
    //     yield put({type: MAP_UI_RTDM_EXCHANGE_AIRCRAFTS_SHOW, payload:false});
    // }
}


function* handlePandoraLprTrafficEnabled({ type, payload }) {
    l.warn('handle PANDORA_TRAFFIC_ENABLED, payload:', payload);

    if (payload) {
        yield put({type: MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ, payload: true});
        yield put({type: MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW, payload: true});
    } else {
        yield put({type: MAP_RTDM_SOAP_LPR_UNITS_TICKS_ENABLE_RQ, payload:false});
        yield put({type: MAP_UI_RTDM_SOAP_LPR_UNITS_TICKS_SHOW, payload:false});
    }
}

function* handlePandoraCreotechTrafficEnabled({ type, payload }) {
    l.warn('handle PANDORA_TRAFFIC_ENABLED, payload:', payload);

    if (payload) {
        yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ, payload:true});
        yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:true});
    } else {
        yield put({type: MAP_RTDM_CREOTECH_TICKS_ENABLE_RQ, payload:false});
        yield put({type: MAP_UI_RTDM_CREOTECH_TICKS_SHOW, payload:false});
    }
}

function* handlePandoraTrafficHeightFilter({ type, payload }) {
    l.warn('handle PANDORA_TRAFFIC_HEIGHT_FILTER, payload:', payload);


}



export default function* () {

    yield all([
        takeEvery(MAP_SETUP_RQ, handleMapSetup),
        //takeEvery(PANDORA_TRAFFIC_ENABLED, handlePandoraTrafficEnabled),
        takeEvery(PANDORA_LPR_TRAFFIC_ENABLED, handlePandoraLprTrafficEnabled),
        takeEvery(PANDORA_CREOTECH_TRAFFIC_ENABLED, handlePandoraCreotechTrafficEnabled),
        takeEvery(PANDORA_TRAFFIC_HEIGHT_FILTER, handlePandoraTrafficHeightFilter)

    ]);
}
