import { all, put, call, select, takeEvery, take} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import { push } from 'react-router-redux';
import {reset} from 'redux-form';

import {has, isEmpty} from "lodash";

import {
    showNotification,
} from 'react-admin';
import {
    FETCH_START,
    FETCH_END,
    USER_LOGOUT
} from 'react-admin';

import {
    MY_STORE_SETAUTH,
    MY_STORE_SETSELF,
    MY_AUTHSAGA_REGISTER_RQ,
    MY_AUTHSAGA_REGISTERCONFIRM_RQ,
    MY_AUTHSAGA_RESETPASS_RQ,
    MY_AUTHSAGA_NEWPASS_RQ,
    MY_AUTHSAGA_LOADSELF_RQ,
    MY_AUTHSAGA_NEWPHONE_RQ,
    MY_AUTHSAGA_PHONECODEVERIFY_RQ,
    MY_AUTH_REGISTER_RQ,
    MY_AUTH_RESETPASS_RQ,
    MY_AUTH_NEWPASS_RQ,
    MY_AUTH_LOADSELF_RQ,
    MY_AUTH_SAVESELF_RQ,
    MY_AUTH_REGISTERCONFIRM_RQ,
    MY_AUTH_NEWPHONE_RQ,
    MY_AUTH_PHONECODEVERIFY_RQ,
    MY_AUTH_NICKNAME_RQ,
    MY_AUTH_ADDNICKNAME_RQ,
    MY_AUTHSAGA_NICKNAME_RQ,
    MY_AUTHSAGA_SAVESELF_GOV_RQ,
    MY_AUTHSAGA_SAVESELF_OPERATOR_RQ

} from "./MyAuthConstants";

import {
    NICKNAME_DIALOG_CALLBACK,
    TOGGLE_PROFILE_VIEW,
    toggleNicknameDialog
} from "../../dr_ra2/logic/settings/MySettingsActions";
import {myAuthSagaNicknameRq} from "./MyExtraAuthActions";
import {LOADING_END, LOADING_START} from "../../dr_ra2/logic/ui/MyUiActions";
import {getGovDomainPrefix} from "../../LogicV1Redux/sagas/AdminSagas";
import {APP_MODES} from "../../Configs";

//nextPathnameSelector unused now
// const nextPathnameSelector = state => {
//     const locationState = state.routing.location.state;
//     return locationState && locationState.nextPathname;
// };


//todo to review in final version

//redirect path should be passed in meta? or in payload?, or redirect:False?
//for now forced anyway... (after 2 stage verification -> maybe, maybe)
//NOTE -> redirectTo is set in action -> like myAuthSagaResetPassRq, but hardcoded here now..
//and maybe, just maybe, some things can be rewritten to ra FETCH actions
//and what about errors.. we need to have customized errors, so let's double check best place to put them.. having in mind that finally rest-y verbs probably will be unified...
export default (dataProvider, authProvider) => {
    if (!authProvider) return () => null;
    function* handleExtraAuth(action) {
        //const { type, payload, error, meta } = action; //error and meta unused now
        const { type, payload } = action;
        switch (type) {
            case MY_AUTHSAGA_RESETPASS_RQ: {
                try {
                    console.log(MY_AUTHSAGA_RESETPASS_RQ);

                    yield put({ type: FETCH_START });

                    const myAppExtras = yield select(
                        (state) => state.myAppExtras
                    );
                    if(myAppExtras && myAppExtras.appMode === APP_MODES.GOV) {
                        payload.domainPrefix = getGovDomainPrefix();
                    }

                    const authPayload = yield call(authProvider, MY_AUTH_RESETPASS_RQ, payload);
                    //yield put({ type: USER_RESETPASSRQ_SUCCESS, payload: authPayload });
                    yield put({ type: FETCH_END });

                    console.log('testin', MY_AUTHSAGA_RESETPASS_RQ, payload, authPayload);
                    //const parametrizedMsg = {phrase:'myroot.auth.passwordResetMailSentMsg', params:{email:payload.email}};
                    //yield call(alertSaga, { message:parametrizedMsg});


                    yield put(push('/reset-pass-intermediate'));
                } catch ({...e}) {
                    yield put({ type: FETCH_END });

                    //yield put({ type: USER_RESETPASSRQ_FAILURE, error: e, meta: { auth: true } });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_RESETPASS_RQ, {...e});

                    const resetPass_errorRq = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.passwordResetRequestFailMsg' : e.message);
                    yield put(showNotification(resetPass_errorRq, 'warning'));
                }

                break;
            }
            case MY_AUTHSAGA_NEWPASS_RQ: {
                try {
                    console.log(MY_AUTHSAGA_NEWPASS_RQ, payload);

                    yield put({ type: FETCH_START });
                    const authPayload = yield call(authProvider, MY_AUTH_NEWPASS_RQ, payload);
                    //yield put({ type: USER_RESETPASSRQ_SUCCESS, payload: authPayload });
                    yield put({ type: FETCH_END });

                    console.log('testin', MY_AUTHSAGA_NEWPASS_RQ, payload,',' , authPayload);
                    //const parametrizedMsg = {phrase:'myroot.auth.passwordResetMailSentMsg', params:{email:payload.email}};
                    //yield call(alertSaga, { message:parametrizedMsg});

                    yield put(push('/reset-pass-final'));
                } catch ({...e}) {
                    yield put({ type: FETCH_END });

                    //yield put({ type: USER_RESETPASSRQ_FAILURE, error: e, meta: { auth: true } });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_NEWPASS_RQ, {...e});

                    const newPass_errorRq = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.passwordResetRequestFailMsg' : e.message);
                    yield put(showNotification(newPass_errorRq, 'warning'));
                }

                break;
            }
            case MY_AUTHSAGA_REGISTER_RQ: {
                try {
                    console.log(MY_AUTHSAGA_REGISTER_RQ);
                    yield put({ type: FETCH_START });
                    const authPayload = yield call(authProvider, MY_AUTH_REGISTER_RQ, payload);
                    //yield put({ type: USER_REGISTER_SUCCESS, payload: authPayload });
                    yield put({ type: FETCH_END });

                    console.log('testin', MY_AUTH_REGISTER_RQ, authPayload);
                    //const parametrizedMsg = {phrase:'myroot.auth.registrationMailSentMsg', params:{email:authPayload.email}};
                    //yield call(alertSaga, { message:parametrizedMsg});
                    yield put(push('/confirm-register'));
                } catch ({...e}) {
                    yield put({ type: FETCH_END });

                    //yield put({ type: USER_REGISTER_FAILURE, error: e, meta: { auth: true } });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_REGISTER_RQ, {...e});

                    const register_error = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.registerRqError' : e.message);
                    yield put(showNotification(register_error, 'warning'));
                }

                break;
            }
            case MY_AUTHSAGA_REGISTERCONFIRM_RQ: {
                try {
                    console.log(MY_AUTHSAGA_REGISTERCONFIRM_RQ, payload);

                    yield put({ type: FETCH_START });
                    const authPayload = yield call(authProvider, MY_AUTH_REGISTERCONFIRM_RQ, payload);
                    //yield put({ type: USER_REGISTER_SUCCESS, payload: authPayload });
                    yield put({ type: FETCH_END });

                    console.log('testin', MY_AUTH_REGISTERCONFIRM_RQ, authPayload);
                    //btw - no payload on verified.. so cannot skip login
                    yield put(push('/verified'));
                } catch ({...e}) {
                    yield put({ type: FETCH_END });

                    //yield put({ type: USER_REGISTER_FAILURE, error: e, meta: { auth: true } });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_REGISTERCONFIRM_RQ, {...e});

                    const confirm_error = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.registerRqError' : e.message);
                    yield put(showNotification(confirm_error, 'warning'));
                }

                break;
            }
            case MY_AUTHSAGA_LOADSELF_RQ: {
                console.warn(type, 'not supported! (old appraoch to rewrite)');
                break;
            }
            case MY_AUTHSAGA_SAVESELF_GOV_RQ: {
                try {
                    console.log(MY_AUTHSAGA_SAVESELF_GOV_RQ, payload);
                    yield put({type: FETCH_START});

                    const ret = yield call(authProvider, MY_AUTH_SAVESELF_RQ, {data: payload.data});
                    console.log(type, ret);
                    yield put(showNotification('myroot.auth.accountUpdatedMsg', 'info'));
                    yield put({type: FETCH_END});

                } catch (e) {
                    yield put({type: FETCH_END});
                    console.warn('SAGA ERROR', MY_AUTHSAGA_SAVESELF_GOV_RQ, {...e});

                    const err = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.accountUpdateFailMsg' : e.message);
                    yield put(showNotification(err, 'warning'));
                }
                break;
            }
            case MY_AUTHSAGA_SAVESELF_OPERATOR_RQ: {
                try {
                    console.log(MY_AUTHSAGA_SAVESELF_OPERATOR_RQ, payload);
                    yield put({type: FETCH_START});

                    const {firstName, lastName, nickName, ...cleanedPayload} = payload.data;

                    //prevent overwriting keys in etc
                    if(has(cleanedPayload, 'etc')) {
                        console.log('merging etc', cleanedPayload.etc);
                        cleanedPayload.etc = yield _mergeEtcWithMyself(cleanedPayload.etc);
                        console.log('after merging etc', cleanedPayload.etc);
                    }

                    if (yield _hasNicknameDataChangedOrNew(firstName, lastName)) {
                        yield put(myAuthSagaNicknameRq({firstname: firstName, lastname: lastName}))
                    }

                    if (isEmpty(cleanedPayload)) {
                        yield put({type: FETCH_END});
                    }
                    // disabled and fixed for username only
                    // disabled because is_approved key is passed and this cannot happen at this time
                        // LGZ note -> is_approved is removed from payload, so probably comment out can be reverted.
                    // else if (
                    //     has(cleanedPayload, 'username') &&
                    //     keys(cleanedPayload).length === 1 &&
                    //     !(yield _hasUsernameChanged(cleanedPayload.username))
                    // ) {
                    //     yield put({type: FETCH_END});
                    // }
                    else {
                        const ret = yield call(authProvider, MY_AUTH_SAVESELF_RQ, {data: cleanedPayload});
                        console.log(type, ret);
                        yield put(showNotification('myroot.auth.accountUpdatedMsg', 'info'));
                        yield put({type: FETCH_END});
                    }
                } catch (e) {
                    yield put({type: FETCH_END});

                    //yield put({ type: USER_REGISTER_FAILURE, error: e, meta: { auth: true } });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_SAVESELF_OPERATOR_RQ, {...e});

                    const err = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.accountUpdateFailMsg' : e.message);
                    yield put(showNotification(err, 'warning'));
                }
                break;
            }
            case MY_AUTHSAGA_NEWPHONE_RQ: {
                try {
                    yield put({ type: FETCH_START });
                    const ret = yield call(authProvider, MY_AUTH_NEWPHONE_RQ, payload);
                    console.log(type, ret);
                    yield put(showNotification( 'myroot.auth.phoneChangeRequestSuccessMsg', 'info'));
                    yield put({ type: FETCH_END });
                } catch (e) {
                    yield put({ type: FETCH_END });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_NEWPHONE_RQ, {...e});
                    const err = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.phoneChangeRequestFailMsg' : e.message);
                    yield put(showNotification(err, 'warning'));
                }
                break;
            }
            case MY_AUTHSAGA_PHONECODEVERIFY_RQ: {
                try {
                    yield put({ type: FETCH_START });
                    const ret = yield call(authProvider, MY_AUTH_PHONECODEVERIFY_RQ, payload);
                    console.log(type, ret);
                    if(ret.data.status.code === 'SUCCESSFUL_MIGRATING_TO_MOBILE_ACCOUNT') {
                        yield put({
                            type: TOGGLE_PROFILE_VIEW,
                            show: false
                        });
                        yield put({
                            type: LOADING_START,
                            primaryMessage: 'myroot.message.logoutTimeout'
                        });
                        yield put(showNotification('myroot.auth.phoneVerifySuccessMigrationMsg', 'info', {autoHideDuration: 9000}));
                        yield delay(10000);

                        yield put({type: LOADING_END});
                        yield put({ type: FETCH_END });
                        yield put({type: USER_LOGOUT});
                    }
                    else {
                        yield put(showNotification( 'myroot.auth.phoneVerifySuccessMsg', 'info'));
                        //Reset form for isDirty button validation
                        yield put(reset('self-form-phone'));
                        yield put({ type: FETCH_END });
                    }


                } catch (e) {
                    yield put({ type: FETCH_END });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_PHONECODEVERIFY_RQ, {...e});
                    let err = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.phoneVerifyFailMsg' : e.message);
                    if(e.message.search('422') !== -1) err = 'myroot.auth.phoneVerifyFailMsg';
                    yield put(showNotification(err, 'warning'));
                }
                break;
            }
            case MY_AUTHSAGA_NICKNAME_RQ: {
                try {
                    yield put({ type: FETCH_START });
                    const {data} = yield call(authProvider, MY_AUTH_NICKNAME_RQ, payload);
                    yield put(toggleNicknameDialog({show: true, nicknames: data}));
                    const {result} = yield take(NICKNAME_DIALOG_CALLBACK);

                    if(!!result) {
                        yield call(authProvider, MY_AUTH_ADDNICKNAME_RQ, {firstName: payload.firstname, lastName: payload.lastname, nickName: result});
                        yield put(showNotification( 'myroot.auth.accountUpdatedNicknameMsg', 'info'));
                        yield put({ type: FETCH_END });
                    }
                    else {
                        yield put({ type: FETCH_END });
                    }
                }
                catch (e) {
                    yield put({ type: FETCH_END });
                    console.warn('SAGA ERROR', MY_AUTHSAGA_NICKNAME_RQ, {...e});

                    const err = typeof e === 'string'
                        ? e
                        : (typeof e === 'undefined' || !e.message ? 'myroot.auth.accountUpdateFailMsg' : e.message);
                    yield put(showNotification(err, 'warning'));
                }
                break;
            }
            default:{
                console.error('unknown extra auth saga', type, payload,)
            }
        }

    }

    return function* watchAuthActions() {
        yield all([
            takeEvery(action => action.meta && action.meta.myauth, handleExtraAuth),
            //hot fix
            //takeEvery(MY_STORE_SETSELF, handleStoreSelf),
        ]);
    };

    function* _hasNicknameDataChangedOrNew(fName, lName) {
        if(!fName || !lName) {
            return false;
        }
        const {firstName, lastName, nickName} = yield select(state => state.mySelf);
        return firstName !== fName || lastName !== lName || !nickName;
    }


    function* _hasUsernameChanged(uName) {
        const {username} = yield select(state => state.mySelf);
        console.log("hasUsernameChanged", uName, username, username !== uName);
        return username !== uName;
    }

    function* _mergeEtcWithMyself(_etc) {
        const {etc} = yield select(state => state.mySelf);
        return {
            ...etc,
            ..._etc
        };
    }
}
