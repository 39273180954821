import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { CookiesProvider } from 'react-cookie';

import {App} from './AppBuildModeWrapper'

import generateClassName from './hackingJss'
import JssProvider from 'react-jss/lib/JssProvider';

import * as serviceWorker from './serviceWorker';
//import FastClick from 'react-fastclick-alt';

//cookie provider was added for 1.1 fast fixes,
// but we should stick to Persistable setup (seems to have better data versioning)
// ... fixme during refactoring ...



ReactDOM.render(
    <JssProvider generateClassName={generateClassName}>
        <CookiesProvider>
            {/*<FastClick>*/}
            <App/>
            {/*</FastClick>*/}
        </CookiesProvider>
    </JssProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
