import { all, put, takeEvery, takeLatest,  select, call } from 'redux-saga/effects';
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import {urls} from "../../Configs";
import axios from "axios";
import { showNotification } from 'react-admin';

import {FETCH_ALL_LIVE_ADSB_EXCHANGE_RQ, STORE_ALL_LIVE_ADSB_EXCHANGE} from "../constants/LiveAdsbExchangeConstants";
import {
    MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ,
    MAP_LIVE_ABSB_EXCHANGE_ENABLED,
    MAP_UI_LIVE_ABSB_EXCHANGE_SHOW
} from "../constants/MapUxConfigConstants";
import {InspectTypes} from "../../modules2lib/common/";
import {STORE_INSPECT_DATA} from "../constants/InspectConstants";
import {TIMER_TICK_RQ} from "../constants/TickConstants";
import {FETCH_ALL_RTDM_CREOTECH_TICKS_RQ} from "../constants/RtdmCreotechTicksConstants";


const getMyAppExtras = (state) => state.myAppExtras;
const getLiveAdsbExchange = (state) => state.liveAdsbExchange;
const getMapUxConfig = (state) => state.mapUxConfig;

//experimental/reference only!!!


function* handleFetch({ type, payload }) {
    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiLiveAbsbExchangeEnabled) {
    //     console.error('lprHelipads fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);

    //console.log('payload', payload);
    //console.log('fetch lprHelipads start');

    yield put({ type: FETCH_START });

    try {

        const prevLiveAdsbExchange =  yield select(getLiveAdsbExchange);

        const liveAdsbUrl = urls.liveAdsbUrl;
        let fixedUrl = (prevLiveAdsbExchange.lastDv) ? `${liveAdsbUrl}&lastDv=${prevLiveAdsbExchange.lastDv}` : liveAdsbUrl;

        const opts = {
            method:'get',
        };

        const promise = axios(fixedUrl, opts);

        const response = yield call(() => promise);

        //fixme show preferences?
        yield put({type: MAP_UI_LIVE_ABSB_EXCHANGE_SHOW, payload:true});
        console.log('fetch liveAdsbExchange response', response);
        yield put({ type: STORE_ALL_LIVE_ADSB_EXCHANGE, payload:{rawData:response.data.acList, lastDv : response.data.lastDv} });

        yield put({type: MAP_LIVE_ABSB_EXCHANGE_ENABLED, payload:true});


        yield put({ type: FETCH_END });

    } catch (error) {
        console.error('liveAdsbExchange fetch error', error);
        yield put(showNotification('liveAdsbExchange fetch error', 'warning'));
        yield put({ type: FETCH_END });
    }

}


//fixme show preferences?
function* handleEnableToggle({payload}) {
    //console.error('handle MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_LIVE_ADSB_EXCHANGE_RQ});
    } else {
        //just disabling
        yield put({type: STORE_ALL_LIVE_ADSB_EXCHANGE, payload:{lastDv:null}});

        yield put({type: MAP_LIVE_ABSB_EXCHANGE_ENABLED, payload:false});
        yield put({type: MAP_UI_LIVE_ABSB_EXCHANGE_SHOW, payload:false});
    }
}

function* handleTick({payload}) {
    //console.error('handle TIMER_TICK_RQ for exchange tick, payload:', payload);

    //every 2 ticks
    if (payload.ticks % 2 === 0) {
        const mapUxConfig = yield select(getMapUxConfig);
        //let's not fetch in background.. only when visible
        if (mapUxConfig.uiLiveAbsbExchangeShow) {
            yield put({type: FETCH_ALL_LIVE_ADSB_EXCHANGE_RQ});
        }
    }
}

export default function* () {

    yield all([
        takeEvery(MAP_LIVE_ABSB_EXCHANGE_ENABLE_RQ, handleEnableToggle),

        takeLatest(FETCH_ALL_LIVE_ADSB_EXCHANGE_RQ, handleFetch),

        takeEvery(TIMER_TICK_RQ, handleTick),

    ]);
}
