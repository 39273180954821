import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';
import { urls} from "../../Configs";
import axios from "axios";
import { showNotification } from 'react-admin';

import {FETCH_ALL_RTDM_EXCHANGE_TICKS_RQ, STORE_ALL_RTDM_EXCHANGE_TICKS} from "../constants/RtdmExchangeTicksContants";
import {
    MAP_UI_RTDM_EXCHANGE_TICKS_SHOW,
    MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ,
    MAP_RTDM_EXCHANGE_TICKS_ENABLED,
    MAP_UI_HEIGHT_FILTER,
    PANDORA_TRAFFIC_HEIGHT_FILTER,
} from "../constants/MapUxConfigConstants";

import {TIMER_TICK_RQ} from "../constants/TickConstants";

const getMyAppExtras = (state) => state.myAppExtras;
const getMapUxConfig = (state) => state.mapUxConfig;
const getInspectData = (state) => state.inspectData;

const getExchangeAircrafts = (state) => state.rtdmExchangeAircrafts;
const getExchangeTicks = (state) => state.rtdmExchangeTicks;

//should be per app? pandora is default now
const isPandora = true;

function* handleFetch({ type, payload }) {

    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiRtdmSourcesEnabled) {
    //     console.error('lprHelipads fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);

    //console.log('payload', payload);
    //console.log('fetch rtdmExchangeTicks start');

    yield put({ type: FETCH_START });

    try {

        const rtdmExchangeTicksUrl = urls.rtdmExchangeTicksUrl;

        const opts = {
            method:'get',
        };

        const promise = axios(rtdmExchangeTicksUrl, opts);

        const response = yield call(() => promise);

        //fixme show preferences

        const mapUxConfig = yield select(getMapUxConfig);

        if (isPandora) {
            yield put({type: MAP_UI_RTDM_EXCHANGE_TICKS_SHOW, payload:mapUxConfig.pandoraCreotechTrafficEnabled});
        } else {
            yield put({type: MAP_UI_RTDM_EXCHANGE_TICKS_SHOW, payload:true});
        }

        //console.log('fetch rtdmExchangeTicks response', response);
        //adding aircrafts from store....
        const exchangeAircrafts = yield select(getExchangeAircrafts);

        const inspectData = yield select(getInspectData);

        const {bottomCutoff, topCutoff} = (isPandora)? mapUxConfig.trafficHeightFilter:mapUxConfig.heightFilter;

        const nextPayload = {
            rawData:response.data,
            aircraftsData:exchangeAircrafts.rawData,
            currentInspectData:inspectData,
            meta:{
                bottomCutoff,
                topCutoff
            }
        };

        yield put({ type: STORE_ALL_RTDM_EXCHANGE_TICKS, payload:nextPayload});


        yield put({ type: FETCH_END });

    } catch (error) {
        console.error('rtdmExchangeTicks fetch error', error);
        yield put(showNotification('rtdmExchangeTicks fetch error', 'warning'));
        yield put({ type: FETCH_END });
    }

}


//fixme show preferences?
function* handleEnableToggle({payload}) {
    console.warn('handle FETCH_ALL_RTDM_EXCHANGE_TICKS_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_RTDM_EXCHANGE_TICKS_RQ});
    } else {
        //just disabling

        yield put({type: MAP_RTDM_EXCHANGE_TICKS_ENABLED, payload:false});
        yield put({type: MAP_UI_RTDM_EXCHANGE_TICKS_SHOW, payload:false});

    }
}

function* handleTick({payload}) {
    //console.error('handle TIMER_TICK_RQ for exchange tick, payload:', payload);

    //every 2 ticks
    if (payload.ticks % 2 === 0) {
        const mapUxConfig = yield select(getMapUxConfig);
        //let's not fetch in background.. only when visible
        if (mapUxConfig.uiRtdmExchangeTicksShow) {
            yield put({type: FETCH_ALL_RTDM_EXCHANGE_TICKS_RQ});
        }
    }
}

function* handleDisplayPrefsChange({ type, payload }) {

    console.log('on handle display prefs change', type, payload);

    const rtdmExchangeTicks = yield select(getExchangeTicks);

    const mapUxConfig = yield select(getMapUxConfig);

    const exchangeAircrafts = yield select(getExchangeAircrafts);

    const inspectData = yield select(getInspectData);

    const {bottomCutoff, topCutoff} = (isPandora)? mapUxConfig.trafficHeightFilter:mapUxConfig.heightFilter;

    const nextPayload = {
        rawData:rtdmExchangeTicks.rawData,
        aircraftsData:exchangeAircrafts.rawData,
        currentInspectData:inspectData,
        meta:{
            bottomCutoff,
            topCutoff
        }
    };

    yield put({ type: STORE_ALL_RTDM_EXCHANGE_TICKS, payload:nextPayload});
}

export default function* () {
    //disabling exchange (no longer works)

    const fx = [
        //takeEvery(MAP_RTDM_EXCHANGE_TICKS_ENABLE_RQ, handleEnableToggle),
        //takeEvery(FETCH_ALL_RTDM_EXCHANGE_TICKS_RQ, handleFetch),
        //takeEvery(TIMER_TICK_RQ, handleTick),
    ];


    if (isPandora) {
        //fx.push(takeEvery(PANDORA_TRAFFIC_HEIGHT_FILTER, handleDisplayPrefsChange))
    } else {
        //fx.push(takeEvery(MAP_UI_HEIGHT_FILTER, handleDisplayPrefsChange))
    }

    yield all(fx);
}
