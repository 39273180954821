import {put, takeEvery} from 'redux-saga/effects';
import {CHANGE_LOCALE_SUCCESS} from "react-admin";
import {PERSIST_LOCALE} from "../constants/PersistLocaleContants";
import {setMomentLocale} from "../../dr_ra2/utils/momentHelpers";

function* persistLocale({ type, payload }) {
    setMomentLocale(payload.locale);
    yield put({type: PERSIST_LOCALE, payload: {locale: payload.locale}});
}


export default function* () {

    yield takeEvery(CHANGE_LOCALE_SUCCESS, persistLocale);
}
