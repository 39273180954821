import * as React from "react";
import PropTypes from "prop-types";
import DataProvider from "../../../MyDataProvider";
import connect from "react-redux/es/connect/connect";
import {loadingEnd, loadingStart} from "../../../logic/ui/MyUiActions";
import {bindActionCreators, compose} from "redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import {API_VERBS} from "../../../MyApiVerbs";


class ZoneDataProviderBase extends React.Component {

    state = {
        zonedata: undefined,
        isFetching: false,
    };

    static defaultProps = {
        children: ()=>{},
    };

    componentDidMount() {
        if(this.props.zonename) {
            this._fetchZoneData(this.props.zonename);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!shallowEqual(this.props.zonename, prevProps.zonename)) {
            this._fetchZoneData(this.props.zonename);
        }
    }

    _fetchZoneData = (zonename) => {
        if(this.state.isFetching) return;

        console.log('zone data provider fetch');

        this.props.loadingStart();
        this.setState({
            isFetching: true,
        });

        const dataProvider = DataProvider.getDataProvider();
        dataProvider(API_VERBS.GET_LIST, 'zonesAll', {filter: {name: zonename}})
            .then(
                (res) => {
                    this.setState({
                        zonedata: res.data[0],
                        isFetching: false,
                    });
                    this.props.loadingEnd();
                }
            )
            .catch((e)=>{
                this.setState({
                    isFetching: false,
                });
                this.props.loadingEnd();
                console.error('Cannot fetch zone data from zone name in ZoneDataProvider', e);
            });
    };


    render() {
        const {children} = this.props;
        const {zonedata} = this.state;

        return children({zonedata});
    }


}

ZoneDataProviderBase.propTypes = {
    children: PropTypes.func.isRequired,
    zonename: PropTypes.string.isRequired
};

const enhance = compose(
    onlyUpdateForKeys(['zonename']),
    connect(
        null,
        dispatch => ({
            loadingStart: bindActionCreators(loadingStart, dispatch),
            loadingEnd: bindActionCreators(loadingEnd, dispatch),
        })
    ),
);

export const ZoneDataProvider = enhance(ZoneDataProviderBase);
