import {
    CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS,
    CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS, CONFIGURATION_REMOVE_KEY,
    CONFIGURATION_SET_KEY, CONFIGURATION_SET_KEY_OBJECT
} from "./ConfigurationConstants";

const initialState = {
    [CONFIGURATION_KEY_NOTIFICATIONS_INFOBOX_SOUNDS]: true,
    [CONFIGURATION_KEY_NOTIFICATIONS_ALERTBOX_SOUNDS]: true,
};

export default (state = initialState, {type, key, value}) => {
    switch (type) {
        case CONFIGURATION_SET_KEY:
            return {
                ...state,
                [key]: value
            };
        case CONFIGURATION_SET_KEY_OBJECT:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    ...value
                }
            };
        case CONFIGURATION_REMOVE_KEY:
            let newState = {};
            for(const k in state) {
                if(k === key) continue;
                newState = {
                    ...newState,
                    [k]: state[k]
                }
            }
            return {
                ...newState,
            };
        default:
            return state;
    }
}