import { all, put, takeEvery,  select, call } from 'redux-saga/effects';
import {FETCH_ALL_POIS_V2_RQ, STORE_ALL_POIS_V2, SAVE_POI_DATA_V2_RQ} from '../constants/PoisV2Constants';
import DataProvider from "../../dr_ra2/MyDataProvider";

import { showNotification } from 'react-admin';
import { deserializePoisV2 } from '../oldLogic/PoisListV2';
import { refreshView } from 'react-admin';
import { serializePrimitive} from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";
import { push } from 'react-router-redux';

import {
    FETCH_START,
    FETCH_END
} from 'react-admin';

import {
    MAP_UI_POIS_V2_SHOW,
    MAP_POIS_V2_ENABLE_RQ,
    MAP_POIS_V2_ENABLED
} from "../constants/MapUxConfigConstants";

import {LOADING_END, LOADING_START} from "../../dr_ra2/logic/ui/MyUiActions";
import {PRIMITIVES} from "../../gl_map/glSetups/deckDraw/DrawEditorEnums";
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

export const getMySelf = (state) => state.mySelf;

export const getEditablePrimitives = (state) => state.drawEditorData.editablePrimitives;

//copy/paste of zone saga. DRY me later!

//todo check if FETCH START/END should be added
const getMapUxConfig = (state) => state.mapUxConfig;
const getMyAppExtras = (state) => state.myAppExtras;
const getPoisV2 = (state) => state.poisV2;


function* handleFullSave({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'myroot.message.saving',
    });

    //yield put(showNotification('missionsV2 save here', 'warning'));
    try {

        const editablePrimitives = yield select(getEditablePrimitives);

        console.warn('handleFullSave SAVE_POI_DATA_V2_RQ');
        console.log('poiV2 save here', type, payload);

        const savable = [];

        for (let i = 0; i < editablePrimitives.length; i++) {
            const primitive = editablePrimitives[i];
            if (primitive.primitiveType === PRIMITIVES.MEASURE) continue;
            savable.push(primitive);
        }

        if (savable.length !== 1) {
            yield earlyFailSafe('resources.pois.notifications.oneAreaRequiredErr');
            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));
            return;
        }

        const singleEditPrimitive = savable[0];


        const dataProvider = DataProvider.getDataProvider();

        const poiRecord = Object.assign({}, payload);

        if (!poiRecord.etc) {
            poiRecord.etc = {};
        }

        const {primitive, wkb, min, max } = serializePrimitive(singleEditPrimitive);


        poiRecord.etc.primitive = primitive;
        poiRecord.geometry = wkb;
        //would need fixing slider to feets..
        //so right now uses record fields
        //poiRecord.min = min;
        //poiRecord.max = max;

        if (poiRecord.min >= poiRecord.max ) {
            yield earlyFailSafe('resources.pois.notifications.wrongMinMaxErr');
            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));
            return;
        }

        console.log('poiV2 save here editable prim', poiRecord);

        let poiId = poiRecord.uid;

        console.warn('===============poiId', poiId);
        let poiSaved, isCreate;
        isCreate = (!poiId);

        if (isCreate) {
            poiSaved = yield dataProvider(API_VERBS.CREATE, 'pois', {data: poiRecord});
            console.warn('=================new poi saved', poiSaved);
            //throw "new mission saved"
            poiId = poiSaved.data.uid;

        } else {
            poiSaved = yield dataProvider(API_VERBS.UPDATE, 'pois', {id: poiId, data: poiRecord});
            console.warn('=================old poi updated', poiSaved);
        }


        yield put({ type: FETCH_END });

        if (isCreate) {
            yield put(push(`/pois/${poiId}/edit`));
            yield put({type: LOADING_END});
        }
        else {
            yield put(refreshView());
            yield put({type: LOADING_END});
        }

    } catch (error) {
        console.error('handleSave SAVE_POI_DATA_V2_RQ error', error);
        yield put(showNotification('resources.pois.notifications.saveFailedErr', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});

    }
}


function* handleFetch({ type, payload = {}}) {

    const mapUxConfig = yield select(getMapUxConfig);

    // if (!mapUxConfig.uiPoisV2Show) {
    //     console.error('rtMap poisV2 fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);

    const poisV2 = yield select(getPoisV2);

    if (poisV2.rawData.length > 0) {
        console.warn('====poisv2 already loaded, skipping fetch request, some meta maybe later');
        return;
    }

    console.log('payload', payload);
    console.log('appExtras', appExtras);

    try {

        const dataProvider = DataProvider.getDataProvider();

        console.log('data provider', dataProvider);

        const poisAll = yield dataProvider(API_VERBS.GET_LIST, 'poisAll', {});
        console.log('handleFetch FETCH_ALL_POIS_V2_RQ response', poisAll);
        const poisProcessed = deserializePoisV2(poisAll.data);
        console.log('handleFetch deserializePoisV2 response', poisProcessed);

        //fixme other similar calls to support forceShow..
        if (payload.meta && payload.meta.forceShow)
            yield put({type: MAP_UI_POIS_V2_SHOW, payload:true});

        yield put({ type: STORE_ALL_POIS_V2, payload:{rawData:poisProcessed} });

        yield put({type: MAP_POIS_V2_ENABLED, payload:true});

        //aargh.
        //PubSub.publish(PubSubSignals.ON_POIS_RULES_CHANGE, getPoiRules());
        //yield put({ type: FETCH_END });

    } catch (error) {
        console.error('handleFetch FETCH_ALL_POIS_V2_RQ error', error);
        yield put(showNotification('poisV2 fetch error', 'warning'));
        //yield put({ type: FETCH_END });
    }
}

//fixme show preferences?
function* handleEnableToggle({payload}) {
    console.warn('handle MAP_POIS_V2_ENABLE_RQ, payload:', payload);

    if (payload) {
        yield put({type: FETCH_ALL_POIS_V2_RQ, payload});
    } else {
        //just disabling
        yield put({type: MAP_POIS_V2_ENABLED, payload:false});
        yield put({type: MAP_UI_POIS_V2_SHOW, payload:false});
    }

}

//just force refersh with new timestamp..
//fixes dirty check in map draw
function* handleShownToggle({ type, payload }) {

    //console.log('handleTick in alerts (cleaning)', payload);
    yield put({type: STORE_ALL_POIS_V2, payload: {}});

}

export default function* () {

    yield all([
        takeEvery(MAP_POIS_V2_ENABLE_RQ, handleEnableToggle),

        takeEvery(FETCH_ALL_POIS_V2_RQ, handleFetch),

        takeEvery(MAP_UI_POIS_V2_SHOW, handleShownToggle),

        takeEvery(SAVE_POI_DATA_V2_RQ, handleFullSave),
    ]);
}

//----------helpers

function* earlyFailSafe(msg) {
    yield put(showNotification(msg, 'warning'));
    yield put({ type: FETCH_END });
    yield put({type: LOADING_END});

    //yield put(refreshView()); //no refresh on failsafe!
}
