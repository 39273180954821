import { all, put, select, takeEvery, takeLatest, call } from "redux-saga/effects";

import {STARTUP, SET_INIT_PHASE, SET_APP_STATE_STORE, FULL_APP_RELOAD} from "../constants/AppStateConstants";
import {FETCH_PHASES, INIT_PHASES} from "../constants/AppStateEnums";

import {showNotification, hideNotification} from 'react-admin';

import {handleFetch as fetchZoneTypes} from './ZoneTypesV2Sagas'

import {requestLoopbackVersion} from '../oldLogic/BackendVersions'
import MySocketIoV2Client from "../oldLogic/MySocketIoV2Client";
import {handleFetch as fetchAllCheckins} from "./CheckinsV2Sagas";
import {handleFetch as fetchAllMissions} from "./MissionsV2RtMapSagas";
import {handleFetch as fetchAllZones} from "./ZonesV2Sagas";
import {handleFetch as fetchAllAlerts} from "./AlertsV2Sagas";
import {TIMER_TICK_RQ} from "../constants/TickConstants";
import {get} from "lodash";
import MyStoreProvider from "../../dr_ra2/MyStoreProvider";
import {encrypt,decrypt} from "../../rt_map_ra2/SimpleCrypt"
import {fullAppReload} from "../actions/AppStateActions"

//import firebase from 'react-native-firebase';


const getThreeLights = (state) => state.threeLights;
const getAppExtras = (state) => state.myAppExtras;
const getMySelf = (state) => state.mySelf;
const getMyAuth = (state) => state.myAuth;

// async function getFirebaseIid() {
//     return await firebase.iid().get();
// }
//
// async function getFirebaseToken() {
//     return await firebase.iid().getToken();
// }

// process STARTUP actions
function* startup({ payload }) {

    const mySelf = yield select(getMySelf);
    const myAuth = yield select(getMyAuth);
    const appExtras = yield select(getAppExtras);

    console.log("startup here", payload, myAuth, appExtras);

    //console.log("startup here", firebase);


    //console.log("startup iid.nativeModuleExists", String(firebase.iid.nativeModuleExists));
    let firebaseIid  //= yield call(getFirebaseIid);

    let firebaseToken// = yield call(getFirebaseToken);

    console.log('firebaseToken', firebaseToken);


    const deviceInfo = {
        firebaseIid,
    }

    console.log('deviceInfo', deviceInfo);
    //hmm. call?
    requestLoopbackVersion();
    //have to be loaded before all.
    //other stuff can or can be not loaded depending on context
    //btw to review with deck maps setup..
    yield put({
        type: SET_APP_STATE_STORE,
        payload: { fetchPhase: FETCH_PHASES.ZONE_TYPES}
    });
    yield* fetchZoneTypes({});

    if(appExtras.app.name === 'RT_MAP') {

        yield put({
            type: SET_APP_STATE_STORE,
            payload: { fetchPhase: FETCH_PHASES.ZONES}
        });
        yield* fetchAllZones({payload:{meta:{pandoraInit:true}}});

        yield put({
            type: SET_APP_STATE_STORE,
            payload: { fetchPhase: FETCH_PHASES.ALERTS}
        });
        yield* fetchAllAlerts({});

        yield put({
            type: SET_APP_STATE_STORE,
            payload: { fetchPhase: FETCH_PHASES.MISSIONS}
        });
        yield* fetchAllMissions({});

        yield put({
            type: SET_APP_STATE_STORE,
            payload: { fetchPhase: FETCH_PHASES.CHECKINS}
        });
        yield* fetchAllCheckins({});
    }

    yield put({
        type: SET_APP_STATE_STORE,
        payload: { fetchPhase: FETCH_PHASES.NONE}
    });

    //console.log('firebaseIid', firebaseIid);

    yield put({
        type: SET_INIT_PHASE,
        payload: { initPhase: INIT_PHASES.STARTING, deviceInfo }
    });


    if (myAuth.token) {
        console.warn('======initing socket v2!!!', myAuth);

        MySocketIoV2Client.init({auth:myAuth, myAppExtras:appExtras});

        console.log(appExtras);
    }

    console.log('==== init phase end');
    yield put({
        type: SET_INIT_PHASE,
        payload: { initPhase: INIT_PHASES.READY }
    });

    //hmm, init locator
    // yield put({
    //     type: STORE_LOCATOR_REPORT,
    //     payload: {
    //         probeMode,
    //         locatorFeature,
    //     }
    // });
}


//moved rt-map reload btn
function* handleFullAppReload({payload}) {
    const appExtras = yield select(getAppExtras);

    if(appExtras.app.name === 'RT_MAP') {

        const mySelf = yield select(getMySelf);

        const pandoraAuth = get(mySelf, 'pandoraAuth', {});

        //TODO few issues here.
        //refresh button HAVE to do full reload for now, since we would need to change ui AND probably documentation
        // to do it PROPER way, ie:
        // 1. fast reload functionality here (saga force refresh, in rt-map context TODO),
        // 2. full reload somewhere else (browser is easy -> shift reload, pandora console should be checked, iirc should work)
        // 3. non-existing logout button cleaning full app state, (saga logout, in rt-map context TODO),
        // ad 3). furthermore. we should discuss what happens on logout pandora-console.
        // so ... refresh button with full reload here.

        //window.location.href = `/#/?user=${pandoraAuth.user}&pass=${pandoraAuth.pass}`;

        //... aaand, nope. We don;t want to show password on reload in browser
        //window.location.href = (pandoraAuth.fromQueryString) ? `/#/?user=${pandoraAuth.user}&pass=${pandoraAuth.pass}` : '/#/';

        const cipher = encrypt({user:pandoraAuth.user, pass:pandoraAuth.pass});

        console.warn('cipher', cipher);

        console.warn('decicpher', decrypt(decodeURIComponent(encodeURIComponent(cipher))));

        window.location.href = `/#/?c=${encodeURIComponent(cipher)}`;
        console.warn('fullreaload here, ', payload);

        if (payload && payload.forceReload) {
            //hmm deprecated param.
            window.location.reload(true);
        } else {
            window.location.reload();
        }

    } else {
        console.error('FULL APP RELOAD NOT SUPPORTED')
    }
}

const fullReloadTimeout = 60*60*3; //60*60*X_hrs

function* handleTick({payload}) {
    const appExtras = yield select(getAppExtras);

    if (appExtras.app.name === 'RT_MAP') {

        if (payload.ticks % fullReloadTimeout === 0) {
            //console.warn('10 sec in app state saga');
            yield put(showNotification('App will reload...', 'info',
                {
                    autoHideDuration: 15000,
                    action: {
                        title: 'CANCEL',
                        onClick: () => {
                            //argh
                            //console.warn('Click', this);
                            MyStoreProvider.getStore().dispatch(hideNotification());
                        }
                    },
                    onClose: (event, reason) => {
                        console.warn('onClose', event, reason);
                        if (reason === 'timeout') {
                            //argh
                            MyStoreProvider.getStore().dispatch(fullAppReload());
                        }
                    }
                }
            ));
        } else {
            yield null;
        }
    }
}


export default function*() {

    yield all([
        takeLatest(STARTUP, startup),
        takeEvery(FULL_APP_RELOAD, handleFullAppReload),
        takeEvery(TIMER_TICK_RQ, handleTick),
    ]);

}
