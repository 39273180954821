import React from 'react';
import PostIcon from '@material-ui/icons/Book';
import AccountBalance from '@material-ui/icons/AccountBalance';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Widgets from '@material-ui/icons/Widgets';
import ReportProblem from '@material-ui/icons/ReportProblem';
import FindInPage from '@material-ui/icons/FindInPage';
import Security from '@material-ui/icons/Security';
import Games from '@material-ui/icons/Games';
import Send from '@material-ui/icons/Send';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';

import { Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import { PostList, PostEdit, PostCreate, PostShow } from './components/resources/posts';
import { UserList } from './components/resources/users';
import ErrorBoundary from './components/ErrorBoundary'

import { DroneModelsList, DroneModelsCreate, DroneModelsEdit, DroneModelsShow } from './components/resources/DroneModels';
import { DronesList, DronesCreate, DronesEdit, DronesShow } from './components/resources/Drones';
import {CertificatesList, CertificatesCreate, CertificatesEdit, CertificatesShow} from "./components/resources/Certificates";
import {CertificateTypesList, CertificateTypesShow, CertificateTypesEdit} from "./components/resources/CertificateTypes";
import {ContainersList, ContainersCreate, ContainersEdit} from "./components/resources/Containers";
import {FaasList, FaasShow, FaasEdit} from './components/resources/Faas'
import {GovsCreate, GovsEdit, GovsList, GovsShow} from './components/resources/Govs';
import {InsurancesList, InsurancesCreate, InsurancesEdit} from "./components/resources/Insurances";
import {InsuranceTypesList} from "./components/resources/InsuranceTypes";
import {EventsList, EventsShow} from './components/resources/Events'
import {MissionsCreate, MissionsEdit, MissionsList, MissionsShow} from "./components/resources/Missions";
import {OperatorsEdit, OperatorsList, OperatorsShow} from './components/resources/Operators';
import {OrgOperatorsCreate, OrgOperatorsEdit, OrgOperatorsList} from './components/resources/OrgOperators';
import {OrgsCreate, OrgsEdit, OrgsList} from './components/resources/Orgs';
import {UnitGovsCreate, UnitGovsEdit, UnitGovsList, UnitGovsShow} from './components/resources/UnitGovs';
import {UnitsCreate, UnitsEdit, UnitsList, UnitsShow} from './components/resources/Units';
import {ZonesEdit, ZonesCreate, ZonesList, ZonesShow} from './components/resources/Zones';
import {PoisEdit, PoisCreate, PoisList, PoisShow} from './components/resources/Pois';

import {ZoneLegsEdit, ZoneLegsCreate, ZoneLegsList, ZoneLegsShow} from './components/resources/ZoneLegs';


import { adminSaga } from 'react-admin';
import { combineReducers } from 'redux';

//import restClientRouter from 'aor-rest-client-router';

import jsonRestClient from 'aor-json-rest-client';

import Dashboard from './components/DashboardOriExt';

import Admin from './components/MyAdmin'
//import {Admin} from 'react-admin'
import MyNewLoginPage from './components/auth/MyNewLoginPage';
import MyNewLogoutButton from './components/auth/MyNewLogoutButton';

import i18nProvider from "./i18n/Myi18nProvider";
import authProvider from './MyAuthProvider';
import CustomGovRoutes from './logic/CustomGovRoutes';
import customReducers from './logic/CustomReducers';

import MyRestClient from './MyRestClient';
import MyDataProvider from './MyDataProvider';

import menu, {MENU_GROUPS} from './components/MyMenu';

import MyExtraAuthSaga from '../LogicV2/auth/MyExtraAuthSaga';

import {canUpdate, canRead, canCreate, canDelete, RESOURCES, isSuperAdmin} from './logic/MyRestConfig';
import {superAdminTweaks, govUrls} from './logic/MyRestGovUrls';
import Configs, {defaultLocale, FEATURES_V1_2_ENUMS} from '../Configs';

import data from './data.json';

import {
    MissionsV2Sagas,
    TickSagas,
    ZonesV2Sagas,
    ZoneTypesV2Sagas,
    AppStateSagas,
    PoisV2Sagas,
    UnitsV2Sagas,
    MapUxConfigSagas,
    LiveAdsbExchangeSagas,

    RtdmCreotechTicksSagas,
    RtdmExchangeTicksSagas,
    RtdmExchangeAircraftsSagas,
    RtdmSoapLprUnitsTicksSagas,
    LocaleSagas,
    RehydrateSagas,
    NotamSagas,
    AdminSagas,
    AcmRulesSagas,
} from '../LogicV1Redux/sagas'


import {AppTitle} from "../LogicV1Redux/constants/AppTitleEnums";
import ApplicationsSagas from "../LogicV1Redux/sagas/ApplicationsSagas";
import {ZoneTypesCreate, ZoneTypesEdit, ZoneTypesList, ZoneTypesShow} from "./components/resources/ZoneTypes";
import {NotamsCreate, NotamsList, NotamsShow} from "./components/resources/Notams";
import {AcmRulesCreate, AcmRulesEdit, AcmRulesList} from "./components/resources/AcmRules";

const noAccessPage = '/login'; //?? from code

const myUrls = {
    apiBase: Configs.urls.apiBaseV2,
    noAccessPage,
    superAdminTweaks,
    defaultREST:govUrls,
};
//dang. have to be const, not object created in lamba on each state update.. at least now (static config)
export const AppConfig = {
    appMode:Configs.Apps.DR2_GOV.type,
    app: Configs.Apps.DR2_GOV,
    builtOn:Configs.builtOn,
    devBuild:Configs.devBuild,
};
//console.log(myUrls);

const dataProvider = MyDataProvider.init({
    rules: [
        ['certTypes','jsonSrv'],
        //['certs','jsonSrv'],
        ['insuranceTypes','jsonSrv'],
        ['insurances','jsonSrv'],
        ['posts','jsonServerProvider'],
        ['users','jsonServerProvider'],

        ['*','loopBackSrv']
    ],
    services: {
        jsonServerProvider: jsonServerProvider('http://jsonplaceholder.typicode.com'),
        loopBackSrv: MyRestClient(myUrls),
        jsonSrv: jsonRestClient(data)
    }
});

const testPermissions = (permissions) => {
    console.log('testPermissions',permissions);
    return null;
};

const customSagas = [
    adminSaga(dataProvider, authProvider, i18nProvider),
    MyExtraAuthSaga(dataProvider, authProvider),
    MissionsV2Sagas,
    ZonesV2Sagas,
    ZoneTypesV2Sagas,
    AppStateSagas,
    PoisV2Sagas,
    UnitsV2Sagas,
    MapUxConfigSagas,
    TickSagas,
    LiveAdsbExchangeSagas,

    RtdmCreotechTicksSagas,
    RtdmExchangeTicksSagas,
    RtdmExchangeAircraftsSagas,
    RtdmSoapLprUnitsTicksSagas,
    ApplicationsSagas,
    LocaleSagas,
    RehydrateSagas,
    NotamSagas,
    AdminSagas,
    AcmRulesSagas
];

const ACM_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ACM);
const ZONE_DELETE_ENABLED = Configs.enabledFeatures.includes(FEATURES_V1_2_ENUMS.ZONE_DELETE);

export const App = () => (

    <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={MyNewLoginPage}
        logoutButton={MyNewLogoutButton}
        customRoutes={CustomGovRoutes}
        customSagas={customSagas}
        customReducers={customReducers}
        i18nProvider={i18nProvider}
        locale={defaultLocale}
        title = {AppTitle.CORE}
        menu = {menu}
        configs = {AppConfig}
    >

        {permissions => [
            testPermissions(permissions),
            <Resource name="zonesAll" //used by map!
            />,
            <Resource name="unitsAll"
                //temporarily for debug only

                      //list={ UnitsList}
                //damn.. zonesAll looks solved (for op - except map!), units all requires show for now
                      show={ UnitsShow }
            />,


            <Resource name="govs"
                      options={{label: 'resources.govs.menu'}}
                      list={canRead('govs', permissions) ? GovsList : null}
                      show={canRead('govs', permissions) && !canUpdate('govs', permissions) ? GovsShow : null}

                      edit={canUpdate('govs', permissions) ? GovsEdit : null}
                      create={canCreate('govs', permissions) ? GovsCreate : null}
                      icon={SupervisorAccount}
            />,

            <Resource name="units"
                      options={{label: 'resources.units.menu'}}
                      list={isSuperAdmin(permissions.roles) ? canRead('units', permissions) ? UnitsList : null : null}
                      // list={ UnitsList}
                      show={canRead('units', permissions) && !canUpdate('units', permissions) ? UnitsShow : null}

                      edit={canUpdate('units', permissions) ? UnitsEdit : null}
                      create={canCreate('units', permissions) ? UnitsCreate : null}
                      icon={AccountBalance}
            />,

            <Resource name="unitGovs"
                      options={{label: 'resources.unitGovs.menu'}}
                      list={canRead('unitGovs', permissions) ? UnitGovsList : null}
                      show={canRead('unitGovs', permissions) && !canUpdate('unitGovs', permissions) ? UnitGovsShow : null}

                      edit={canUpdate('unitGovs', permissions) ? UnitGovsEdit : null}
                      create={canCreate('unitGovs', permissions) ? UnitGovsCreate : null}
                      icon={AssignmentInd}
            />,

            <Resource name="zones"
                      options={{label: 'resources.zones.menu'}}
                      list={canRead('zones', permissions) ? ZonesList : null}
                      // show={canRead('zones', permissions) && !canUpdate('zones', permissions) ? ZonesShow : null}
                      show={canRead('zones', permissions) ? ZonesShow : null}

                      edit={canUpdate('zones', permissions) ? ZonesEdit : null}
                      create={canCreate('zones', permissions) ? ZonesCreate : null}

                      icon={Widgets}
            />,

            <Resource name="pois"
                      options={{label: 'resources.pois.menu'}}
                      list={canRead('pois', permissions) ? PoisList : null}
                      show={canRead('pois', permissions) ? PoisShow : null}

                      edit={canUpdate('pois', permissions) ? PoisEdit : null}
                      create={canCreate('pois', permissions) ? PoisCreate : null}

                      icon={Widgets}
            />,

            <Resource name="zoneLegs"
                //       options={{label: 'resources.zoneLegs.menu'}}
                //       list={canRead('zoneLegs', permissions) ? ZoneLegsList : null}
                // // show={canRead('zones', permissions) && !canUpdate('zones', permissions) ? ZonesShow : null}
                //       show={canRead('zoneLegs', permissions) ? ZoneLegsShow : null}
                //       edit={canUpdate('zoneLegs', permissions) ? ZoneLegsEdit : null}
                //       icon={PostIcon}
            />,

            <Resource name="operators"
                      options={{label: 'resources.operators.menu', dataProvider: dataProvider}}
                      list={canRead('operators', permissions) ? OperatorsList : null}
                      show={canRead('operators', permissions) && !canUpdate('operators', permissions) ? OperatorsShow : null}
                      edit={canUpdate('operators', permissions) ? OperatorsEdit : null}
                      icon={PersonOutline}
            />,
            <Resource name="missions"
                      options={{label: 'resources.missions.menu'}}
                      list={canRead('missions', permissions) ? MissionsList : null}
                      //show={canRead('missions', permissions) && !canUpdate('missions', permissions) ? MissionsShow : null}
                      show={canRead('missions', permissions) ? MissionsShow : null}

                      edit={canUpdate('missions', permissions) ? MissionsEdit : null}
                      icon={FlightTakeoff}
            />,
            <Resource name="drones"
                      options={{label: 'resources.drones.menu'}}
                      list={canRead('drones', permissions) ? DronesList : null}
                      show={canRead('drones', permissions) && !canUpdate('drones', permissions) ? DronesShow : null}

                      edit={canUpdate('drones', permissions) ? DronesEdit : null}
                      icon={Send}
            />,
            <Resource name="insurances"
                /* options={{ label: 'resources.insurances.menu' }}
                list={InsurancesList}
                edit={InsurancesEdit}
                create={InsurancesCreate}
                remove={Delete}
                icon={PostIcon} */
            />,

            <Resource name="insuranceTypes"
                /* list={InsuranceTypesList} */
            />,

            <Resource name="certTypes"
                      options={{label: 'resources.certTypes.menu'}}
                      list={canRead('certTypes', permissions) ? CertificateTypesList : null}
                      show={canRead('certTypes', permissions) ? CertificateTypesShow : null}
            />,
            <Resource name="droneModels"
                      options={{label: 'resources.droneModels.menu'}}
                      list={canRead('droneModels', permissions) ? DroneModelsList : null}
                      show={canRead('droneModels', permissions) ? DroneModelsShow : null}
                      edit={canUpdate('droneModels', permissions) ? DroneModelsEdit : null}
                      create={canCreate('droneModels', permissions) ? DroneModelsCreate : null}
                      icon={Games}

            />,
            <Resource name="zoneTypes"
                      options={{label: 'resources.zoneTypes.menu'}}
                      list={canRead('zoneTypes', permissions) ? ZoneTypesList : null}
                      show={canRead('zoneTypes', permissions) && !canUpdate('zoneTypes', permissions) ? ZoneTypesShow : null}
                      edit={canUpdate('zoneTypes', permissions) ? ZoneTypesEdit : null}
                      create={canCreate('zoneTypes', permissions) ? ZoneTypesCreate : null}
                      icon={Widgets}
            />,
            <Resource name="notams"
                      options={{label: 'resources.notams.menu'}}
                      list={canRead('notams', permissions) ? NotamsList : null}
                      show={canRead('notams', permissions) ? NotamsShow : null}
                      create={canCreate('notams', permissions) ? NotamsCreate : null}
                      icon={Games}

            />,

            <Resource name={RESOURCES.ACM_RULES}
                      options={{hideInMenu: true}}
                      list={ACM_ENABLED && canRead(RESOURCES.ACM_RULES, permissions) ? AcmRulesList : null}
                      // show={canRead('notams', permissions) ? NotamsShow : null}
                      edit={ACM_ENABLED && canUpdate(RESOURCES.ACM_RULES, permissions) ? AcmRulesEdit : null}
                      create={ACM_ENABLED && canCreate(RESOURCES.ACM_RULES, permissions) ? AcmRulesCreate : null}
                      // icon={Games}
            />,
            <Resource name="notamActivations"/>,

            ZONE_DELETE_ENABLED && <Resource name={RESOURCES.ZONE_ARCHIVE}/>,

            <Resource name={RESOURCES.EVENT_GOVS}
                      options={{
                          label: 'resources.eventGovs.menu',
                          menuGroup: MENU_GROUPS.logs
                      }}
                      list={canRead(RESOURCES.EVENT_GOVS, permissions) ? EventsList : null}
                      show={canRead(RESOURCES.EVENT_GOVS, permissions) ? EventsShow : null}
                      icon={FindInPage}


            />,
            <Resource name={RESOURCES.EVENT_OPERATORS}
                      options={{
                          label: 'resources.eventOperators.menu',
                          menuGroup: MENU_GROUPS.logs
                      }}
                      list={canRead(RESOURCES.EVENT_OPERATORS, permissions) ? EventsList : null}
                      show={canRead(RESOURCES.EVENT_OPERATORS, permissions) ? EventsShow : null}
                      icon={FindInPage}
            />,
            <Resource name="containers"
                /*
                list={ContainersList}
                edit={ContainersEdit}
                create={ContainersCreate}
                */
            />,

            <Resource name="orgs"
                /*
                options={{ label: 'resources.orgs.menu' }}
                list={OrgsList}
                edit={OrgsEdit}
                icon={PostIcon}
                */
            />,

            <Resource name="orgOperators"
                /* options={{ label: 'resources.orgOperators.menu' }}
                list={OrgOperatorsList}
                edit={OrgOperatorsEdit}
                icon={PostIcon} */
            />,
            <Resource name="faas"
                      // disabled 24.04.2019 for certification
                      // options={{label: 'resources.faas.menu'}}
                      // list={canRead('faas', permissions) ? FaasList : null}
                      // show={canRead('faas', permissions) ? FaasShow : null}
                      // icon={ReportProblem}

            />,
            <Resource
                name="posts"
                // icon={PostIcon}
                // list={PostList}
                // edit={PostEdit}
                // create={PostCreate}
                // show={PostShow}
            />,
            <Resource name="users"
                      // icon={UserIcon}
                      // list={UserList}
            />

        ]}


    </Admin>

);
