import myTheme from './theme/MyThemeReducer'
import myAuth from '../../LogicV2/auth/MyAuthReducer';
import mySelf from '../../LogicV2/auth/MySelfReducer';
import alertsV2 from '../../LogicV1Redux/reducers/AlertsV2Reducer';

import checkinsV2 from '../../LogicV1Redux/reducers/CheckinsV2Reducer';

import zonesV2 from '../../LogicV1Redux/reducers/ZonesV2Reducer'; //see code -> not exactly duplicate of zones resource..
import zoneTypesV2 from '../../LogicV1Redux/reducers/ZoneTypesV2Reducer';

import poisV2 from '../../LogicV1Redux/reducers/PoisV2Reducer';
import unitsV2 from '../../LogicV1Redux/reducers/UnitsV2Reducer'; //fast/dirty to review

import tick from '../../LogicV1Redux/reducers/TickReducer';
import inspectData from '../../LogicV1Redux/reducers/InspectDataReducer'
import drawEditorData from '../../LogicV1Redux/reducers/DrawEditorReducer'
import mySettings from "./settings/MySettingsReducer";
import myAppExtras from "../../LogicV1Redux/reducers/MyAppExtrasReducer"
import myUi from "./ui/MyUiReducer";
import missionsV2 from '../../LogicV1Redux/reducers/MissionsV2Reducer';
import mapUxConfig from '../../LogicV1Redux/reducers/MapUxConfigReducer';
import infobox from './box/InfoboxReducer';
import alertbox from './box/AlertboxReducer';
import infoSeen from './box/InfoSeenReducer';
import configuration from './settings/ConfigurationReducer';
import socket from '../../LogicV1Redux/reducers/SocketReducer';

import rtdmCreotechTicks from '../../LogicV1Redux/reducers/RtdmCreotechTicksReducer';

import rtdmSoapLprUnitsTicks from '../../LogicV1Redux/reducers/RtdmSoapLprUnitsTicksReducer';

import customLayer from '../../LogicV1Redux/reducers/CustomLayerReducer';
import persistedLocale from '../../LogicV1Redux/reducers/PersistedLocaleReducer';

//can be reenabled later. so keeping commented out
//import liveAdsbExchange from '../../LogicV1Redux/reducers/LiveAdsbExchangeReducer';
//import rtdmExchangeTicks from '../../LogicV1Redux/reducers/RtdmExchangeTicksReducer';
//import rtdmExchangeAircrafts from '../../LogicV1Redux/reducers/RtdmExchangeAircraftsReducer';

export default {
    myAuth,
    mySelf,
    myTheme,
    mySettings,
    alertsV2,
    checkinsV2,

    zonesV2,
    zoneTypesV2,
    poisV2,
    unitsV2,
    missionsV2,
    infobox,
    alertbox,
    infoSeen,
    configuration,
    socket,

    inspectData,
    drawEditorData,
    myUi,
    tick,
    myAppExtras,
    mapUxConfig,
    rtdmCreotechTicks,
    rtdmSoapLprUnitsTicks,
    customLayer,
    persistedLocale,

    //liveAdsbExchange,
    //rtdmExchangeTicks,
    //rtdmExchangeAircrafts,
}
