import {INFO_SHOW_MSG} from "./InfoboxConstants";

const initialState = {
    queue: [],
    popVersion: 0,
};

export default (state = initialState, {type, situationID, locationValid, data}) => {
    switch (type) {

        case INFO_SHOW_MSG:

            const infoMsgInit = {
                situationID,
                locationValid,
                data
            };

            const queue = state.queue;
            queue.push(infoMsgInit);
            const popVersion = state.popVersion + 1;

            return {
                ...state,
                queue,
                popVersion
            };

        default:
            return state;
    }
}

