import { all, put, takeEvery,  select, call , throttle} from 'redux-saga/effects';
import {
    FETCH_ALL_ZONES_V2_RQ,
    STORE_ALL_ZONES_V2,
    SAVE_ZONE_DATA_V2_RQ,
    DELETE_ZONE
} from '../constants/ZonesV2Constants';

import DataProvider from "../../dr_ra2/MyDataProvider";
import axios from "axios";
import moment from "moment";

import {currentCountry, myUrls as urls, useMocks} from "../../MyConfig";

import { showNotification } from 'react-admin';
import { deserializeZonesV2 } from '../oldLogic/ZonesListV2';
import { refreshView } from 'react-admin';
import { serializePrimitive } from "../../gl_map/glSetups/deckPrimitives/DeckPrimitiveSerialization";
import { push } from 'react-router-redux';
import {get, sortBy} from "lodash";
import {turfWithin} from '../../gl_map/glSetups/oldCommon/commonTurf';

import {polygon as turfPoly} from "@turf/helpers";


import {connectDynamicData} from '../../modules2lib/examples/myEsmWrapper'
import {
    FETCH_START,
    FETCH_END
} from 'react-admin';

import {
    MAP_UI_ZONES_V2_SHOW,
    MAP_ZONES_V2_ENABLE_RQ,
    MAP_ZONES_V2_ENABLED,
    MAP_UI_ENTITIES_VISIBILITY,
    MAP_UI_HEIGHT_FILTER
} from "../constants/MapUxConfigConstants";

import {LOADING_END, LOADING_START} from "../../dr_ra2/logic/ui/MyUiActions";
import {requestWebcatActivation} from "../../LogicV1Redux/oldLogic/webcatActivationParser";
import {requestAixmTimeslices} from "../../LogicV1Redux/oldLogic/aixmTimeslicesParser";
import {requestNotams} from "../../LogicV1Redux/oldLogic/notamActivationParser";

import {PRIMITIVES} from "../../gl_map/glSetups/deckDraw/DrawEditorEnums";
import {Logger, LOGGERS} from "../../DebugConfig";
import {TIMER_TICK_RQ} from "../constants/TickConstants";
import {RESOURCES} from "../../dr_ra2/logic/MyRestConfig";
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";
import {MY_STORE_SETSELF} from "../../LogicV2/auth/MyAuthConstants";

const l = Logger.get(LOGGERS.ZONES_SAGA);


const zoneMocks = []
//experimenting now...
//technically we could try to use RA/CRUD_GET_LIST...
//but i am bit afraid now to get too confident about RA2 flows yet...

//btw add passing dataProvider in init? like authSaga? not sure...

const getMapUxConfig = (state) => state.mapUxConfig;
const getMyAppExtras = (state) => state.myAppExtras;
const getMySelf = (state) => state.mySelf;
const getEditablePrimitives = (state) => state.drawEditorData.editablePrimitives;

const getZonesV2 = (state) => state.zonesV2;
const getZoneTypesV2 = (state) => state.zoneTypesV2;

//todo check if FETCH START/END should be added

function* handleFullSave({ type, payload }) {

    yield put({ type: FETCH_START });
    yield put({
        type: LOADING_START,
        primaryMessage: 'myroot.message.saving',
    });

    //yield put(showNotification('missionsV2 save here', 'warning'));
    try {

        l.warn('handleFullSave SAVE_ZONE_DATA_V2_RQ');
        l.log('zoneV2 save here', type, payload);

        const dataProvider = DataProvider.getDataProvider();

        const zoneRecord = Object.assign({}, payload);

        const editablePrimitives = yield select(getEditablePrimitives);

        const savable = [];

        for (let i = 0; i < editablePrimitives.length; i++) {
            const primitive = editablePrimitives[i];

            if (primitive.primitiveType === PRIMITIVES.MEASURE) continue;

            savable.push(primitive);
        }

        if (savable.length !== 1) {
            yield earlyFailSafe('resources.zones.notifications.oneAreaRequiredErr');
            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));
            return;
        }

        const singleEditPrimitive = savable[0];

        const mySelf = yield select(getMySelf);
        const ownerUnit = (mySelf.units.find(el => el.uid === zoneRecord.unitid));

        //not every unit have borders now
        //superadmin don't have to own unit, add extra check here. <- fixme
        if (ownerUnit && ownerUnit.geojson ) {


            if (!(process.env.REACT_APP_DISABLE_ZONE_JURISDICTION_EARLY_CHECK)) {

                //damn.. precache?
                //const turfBorderPoly = turfPoly(ownerUnit.__primitive.polygon);
                const singleEditPrimitivePoly = turfPoly(singleEditPrimitive.polygon);

                l.log('zone within', ownerUnit.geojson, singleEditPrimitivePoly);

                const within = turfWithin(singleEditPrimitivePoly, ownerUnit.geojson);

                if (!within) {
                    yield earlyFailSafe('resources.zones.notifications.zoneNotInJurisdictionErr');
                    return;
                }
            }
        }


        if (!zoneRecord.etc) {
            zoneRecord.etc = {};
        }

        const {primitive, wkb, min, max } = serializePrimitive(singleEditPrimitive);


        zoneRecord.etc.primitive = primitive;
        zoneRecord.geometry = wkb;
        //would need fixing slider to feets..
        //so right now uses record fields
        //zoneRecord.min = min;
        //zoneRecord.max = max;

        if (zoneRecord.min >= zoneRecord.max ) {
            yield earlyFailSafe('resources.zones.notifications.wrongMinMaxErr');
            //wtf. not working..
            //yield put(setAllEditablePrimitives(editablePrimitives));
            return;
        }

        l.log('zoneV2 save here editable prim', zoneRecord);

        let zoneId = zoneRecord.uid;

        l.warn('zoneId', zoneId);
        let zoneSaved, isCreate;
        isCreate = (!zoneId);

        if (isCreate) {
            zoneSaved = yield dataProvider(API_VERBS.CREATE, 'zones', {data: zoneRecord});
            l.warn('new zone saved', zoneSaved);
            //throw "new mission saved"
            zoneId = zoneSaved.data.uid;

        } else {
            zoneSaved = yield dataProvider(API_VERBS.UPDATE, 'zones', {id: zoneId, data: zoneRecord});
            l.warn('old zone updated', zoneSaved);
        }


        yield put({ type: FETCH_END });

        if (isCreate) {
            yield put(push(`/zones/${zoneId}/edit`));
            yield put({type: LOADING_END});
        }
        else {
            yield put(refreshView());
            yield put({type: LOADING_END});
        }

    } catch (error) {
        l.error('handleSave SAVE_ZONE_DATA_V2_RQ error', error);
        yield put(showNotification('resources.zones.notifications.saveFailedErr', 'warning'));
        yield put({ type: FETCH_END });
        yield put(refreshView());
        yield put({type: LOADING_END});

    }
}

export function* handleFetch({ type, payload = {}}) {

    console.log('zonesV2, handleFetch payload', payload);
    const mapUxConfig = yield select(getMapUxConfig);
    // if (!mapUxConfig.uiZonesV2Show) {
    //     console.error('rtMap zonesV2 fetch skipped -> disabled with config');
    //     return yield null;
    // }

    const appExtras = yield select(getMyAppExtras);

    try {
        console.log('payload', payload);
        console.log('appExtras', appExtras);

        const opts = {
            method:'get',
        };

        let aupZuluStartHour = currentCountry.aupZuluStartHour;

        let aupZuluStart = moment.utc(aupZuluStartHour, 'hh:mm').clone();

        const zonesStopFilterFrom = aupZuluStart.toISOString();
        const zonesStartFilterUntil = aupZuluStart.clone().add('1','d').toISOString(); //parametrize me

        console.log("=== aupZuluStart", zonesStopFilterFrom);
        console.log("=== aupZuluEnd", zonesStartFilterUntil);

        const filter = {
            fields: {
                archived: true, //should be used in filter
                acts: true,
                contact: true,
                country: true,
                description: true,
                etc: true,
                geojson: true, //used in _fisJurisdictionHack!!! (and probably some other places)
                max: true,
                min: true,
                modified: true,
                name: true,
                othername: true,
                restriction: true,
                source: true,
                start: true,
                stop: true,
                type: true,
                uid: true,
                unitid: true,
                geometry: true //old deserialize!
            },
            order: 'type DESC',

            where: {
                and: [
                    {active: true},
                    {
                        or: [
                            {"stop": null},
                            {and: [
                                    {"start": {"lt": zonesStartFilterUntil}},
                                    {"stop": {"gt": zonesStopFilterFrom}},
                                ]
                            },
                            {'acts.H24': true}, //fixme better acts support
                        ]
                    },
                    // {
                    //     or: [
                    //         {type: 'R'},
                    //         {type: 'D'},
                    //         //{type: 'AREA'},
                    //
                    //     ]
                    // }
                ]
            }
        };

        console.log('===setting limit for debug');

        const finalUrl = encodeURI(`${urls.getZonesV2Url}?filter=${JSON.stringify(filter)}`);

        let response;

        const useMock = useMocks.zonesV2;

        if (!useMock) {
            //console.log('fetcing getZonesV2Url', urls.getZonesV2Url);
            const promise = axios(finalUrl, opts);

            response = yield call(() => promise);

        } else {
            response = {
                data : zoneMocks
            }
        }


        let zoneTypesVis = get(mapUxConfig, 'visibilityPrefs.zoneTypes', {});

        //not used during fetch?
        //const heightFilter =  get(mapUxConfig, 'heightFilter', {});

        zoneTypesVis = updateZoneTypesList(response.data, zoneTypesVis);
        yield put({ type: MAP_UI_ENTITIES_VISIBILITY, payload:{zoneTypes:zoneTypesVis}});

        console.log('handleFetch FETCH_ALL_ZONES_V2_RQ response');

        const zoneTypesV2 = yield select(getZoneTypesV2);

        const zonesPreProcessed = deserializeZonesV2(response.data, zoneTypesV2);
        //let zonesProcessed = zonesAll.data;
        console.log('handleFetch deserializeZonesV2 zonesPreProcessed', Object.assign([], zonesPreProcessed));

        if (payload.meta && payload.meta.pandoraInit) {
            const mySelf = yield select(getMySelf);
            console.log('on pandoraInit, ', zonesPreProcessed, mySelf);
            const alertable = get(mySelf, 'pandora.alertable', []);
            console.log('alertable', alertable);
            //fixme should be tree, OR better db support but for now we won;t have such cases
            const masterList = [];
            const childrenList = [];
            //not super efficient (typezone merge?), but used once on init.
            for (let i=0; i<zonesPreProcessed.length; i++) {
                const name = get(zonesPreProcessed[i], 'inspectData.name', false);
                const masterZone = get(zonesPreProcessed[i], 'inspectData.zoneRecord.etc.master', false);
                for (let j=0; j<alertable.length; j++) {
                    const alertableMaster = alertable[j].name;
                    const withChildren = alertable[j].withChildren;
                    if (alertableMaster === name) {
                        masterList.push({name});
                    } else if (withChildren && alertableMaster === masterZone) {
                        childrenList.push({name});
                    }
                }
            }
            //masters before children. temporary solution
            const atcOwnedZones = masterList.concat(sortBy(childrenList,['name']));

            yield put({
                type: MY_STORE_SETSELF,
                payload: { atcOwnedZones}
            });

        }

        let zonesProcessed;

        let dynamicDataConnected = false;

        if (!useMock && payload.meta && payload.meta.withAupsAips) {

            //using old aups quick and fast, just for comparison
            try {
                console.log('====aupsaips call here');

                //fixme? notification on error in requests, to fix?
                let promises = [
                    requestWebcatActivation(),
                    requestAixmTimeslices(),
                    requestNotams()
                ];

                const allResolves = yield call(() => Promise.all(promises));

                zonesProcessed = connectDynamicData({
                    zones:zonesPreProcessed,
                    webcatActivations: allResolves[0],
                    aixmTimeslices: allResolves[1],
                    notams: allResolves[2],
                    zoneTypes:zoneTypesV2,
                });

                dynamicDataConnected = true;
            } catch (e) {
                //snackbar for now.
                console.log('zone.fetchErrorToast', e);
                l.error('handleFetch FETCH_ALL_ZONES_V2_RQ error', e);
                yield put(showNotification('zonesV2 fetch error', 'warning'));

            }
        }

        if (!dynamicDataConnected) {
            zonesProcessed = connectDynamicData({
                zones:zonesPreProcessed,
                zoneTypes: zoneTypesV2,
                webcatActivations:[],
                aixmTimeslices:[],
                notams:[],
                skipActivationCalc:true
            })
        }

        console.log('after connectDynamicData', zonesProcessed);


        //fixme other similar calls to support forceShow..
        if (payload.meta && payload.meta.forceShow) {
            yield put({type: MAP_UI_ZONES_V2_SHOW, payload: true});
        }

        yield put({

            type: STORE_ALL_ZONES_V2, payload: {
                rawData: zonesProcessed,
                meta: {
                    zoneTypesVis: zoneTypesVis,
                    bottomCutoff: mapUxConfig.heightFilter.bottomCutoff,
                    topCutoff: mapUxConfig.heightFilter.topCutoff
                }
            }
        });


        // zoneTypesVis = updateZoneTypesList(response.data, zoneTypesVis);
        //
        // yield put({ type: MAP_UI_ENTITIES_VISIBILITY, payload:{zoneTypes:zoneTypesVis, zoneTypesVis:zoneTypesVis}});

        //fixme other similar calls to support forceShow..
        // if (payload.meta && payload.meta.forceShow) {
        //     yield put({type: MAP_UI_ZONES_V2_SHOW, payload: true});
        // }

        // yield put({type: MAP_ZONES_V2_ENABLED, payload:true});

    } catch (error) {
        //snackbar for now.
        console.log('zone.fetchErrorToast', error);
        l.error('handleFetch FETCH_ALL_ZONES_V2_RQ error', error);
        yield put(showNotification('zonesV2 fetch error', 'warning'));
        //yield put({ type: FETCH_END });
    }
}


//fixme show preferences?
function* handleEnableToggle({payload}) {
    l.warn('handle MAP_ZONES_V2_ENABLE_RQ, payload:', payload);

    if (payload) {
        yield put({type: MAP_ZONES_V2_ENABLED, payload:true});
        yield put({type: MAP_UI_ZONES_V2_SHOW, payload:true});
        yield put({type: FETCH_ALL_ZONES_V2_RQ, payload});

    } else {
        yield put({type: MAP_ZONES_V2_ENABLED, payload:false});
        yield put({type: MAP_UI_ZONES_V2_SHOW, payload:false});
        yield put({ type: STORE_ALL_ZONES_V2, payload:{
                rawData:[],
            }
        });
    }
}

//just force refersh with new timestamp..
//fixes dirty check in map draw
function* handleShownToggle({ type, payload }) {

    //l.log('handleTick in alerts (cleaning)', payload);
    yield put({type: STORE_ALL_ZONES_V2, payload: {}});

}


//force recalc deck filters
function* handleDisplayPrefsChange({ type, payload }) {

    l.log('on handle display prefs change', type, payload);

    const zonesV2 = yield select(getZonesV2);

    const mapUxConfig = yield select(getMapUxConfig);

    yield put({ type: STORE_ALL_ZONES_V2, payload:{
            rawData:zonesV2.rawData,
            meta: {
                zoneTypesVis: mapUxConfig.visibilityPrefs.zoneTypes,
                bottomCutoff: mapUxConfig.heightFilter.bottomCutoff,
                topCutoff: mapUxConfig.heightFilter.topCutoff
            }
        }
    });
}

const zoneTick = 10*60; //10 min
function* handleTick({ type, payload }) {

    const appExtras = yield select(getMyAppExtras);
    if (payload.ticks % zoneTick === 0) {

        if (appExtras.app.name === 'RT_MAP') {

            //TODO add some algorithm to support SLOW dynamic ctr/fis holes hacks,
            // with filtered onZoneActivitiesChange
            const mapUxConfig = yield select(getMapUxConfig);
            if (mapUxConfig.uiMissionsV2Enabled) {
                //console.error('onZoneTick');
                yield put({type: FETCH_ALL_ZONES_V2_RQ, payload:{meta:{withAupsAips:true}}});
            }
        }
    }
}

function* handleZoneDelete({ type, payload }) {
    console.log("handleZoneDelete", payload);
    yield put({type: FETCH_START});

    try {
        const dataProvider = DataProvider.getDataProvider();
        const {uid, redirect} = payload;

        yield dataProvider(API_VERBS.CREATE, RESOURCES.ZONE_ARCHIVE, {id: uid});

        yield put({type: FETCH_END});
        yield put(showNotification('ra.notification.deleted', 'info'));
        //Not needed
        //yield put(push(redirect));
        yield put(refreshView());

    } catch (e) {
        yield put(showNotification(
            `resources.${RESOURCES.ZONES}.notifications.deleteFailedErr`,
            'warning',
            {messageArgs: {status: e.message}}
        ));
        console.warn('Error archiving', e);
        yield put({type: FETCH_END});
    }
}


export default function* () {

    yield all([
        takeEvery(MAP_ZONES_V2_ENABLE_RQ, handleEnableToggle),

        takeEvery(TIMER_TICK_RQ, handleTick),

        takeEvery(FETCH_ALL_ZONES_V2_RQ, handleFetch),

        takeEvery(MAP_UI_ZONES_V2_SHOW, handleShownToggle),

        takeEvery(SAVE_ZONE_DATA_V2_RQ, handleFullSave),

        takeEvery(MAP_UI_ENTITIES_VISIBILITY, handleDisplayPrefsChange),

        takeEvery(DELETE_ZONE, handleZoneDelete),

        throttle(700, MAP_UI_HEIGHT_FILTER, handleDisplayPrefsChange)

    ]);
}

//----------helpers

function* earlyFailSafe(msg) {
    yield put(showNotification(msg, 'warning'));
    yield put({ type: FETCH_END });
    yield put({type: LOADING_END});

    //yield put(refreshView()); //no refresh on failsafe!
}


function updateZoneTypesList(dbArr, zoneTypesVis) {
    //hmm, cleanup nonexisting?
    //just adding new types now, shown by default.
    for (let i = 0; i < dbArr.length; i++) {
        const currZoneType = dbArr[i].type;
        if (zoneTypesVis[currZoneType] === undefined) {
            zoneTypesVis[currZoneType] = true;
        }
    }

    //and sort by name.
    const ordered = {};
    Object.keys(zoneTypesVis).sort().forEach(function(key) {
        ordered[key] = zoneTypesVis[key];
    });

    return ordered;
}


